import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIconn from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// imports here
import CloseIcon from '../../../images/CloseCrossIcon.svg'
import { FunctionCommas } from "../../../utils/commasFun";
import Arrow from "../../../images/Arrowright.svg";
import CongratsPopUp from "../../Modals/CongratulationPopupModal";
import MultitapModal from "../../Modals/MultitapModal";
import notEnoughPointCoin from "../../../images/notEnoughPointCoin.svg";
import TonPreConfirmPopup from "../../Modals/TonPreConfirmPopup";
import SuccessFailPopUp from "../../SuccessFailPopUp";
import TicketLimitExceedModal from "../../Modals/TicketLimitExceedModal";
import Lottie from "react-lottie";
import ConfettiVertical from '../../../ConfettiJson/confetti vertical.json'

const TokenPopup = ({ setOpenPopup2, }) => {
    const navigate = useNavigate();

    // states here 
    const [notEnoughPoint, setNotEnoughPoint] = useState(false);
    const [congratsSuccessPopup, SetCongratsSuccessPopup] = useState(false);
    const [openPreTonConfirmModal, setOpenPreTonConfirmModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [popupMessage, setPopUpMessage] = useState("");
    const [subtext, setSubtext] = useState("");
    const [subtext1, setSubtext1] = useState("");
    const [showConfetti, setShowConfetti] = useState(false);
    const [showLotties, setShowLotties] = useState(false); // Control whether to show the animation
    const [resultPopUp, setResultPopUp] = useState(false);
    const [ticket, setTicket] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [ticketLimitExceed, SetTicketLimitExceed] = useState(false);
    const [modalLoadder, setModalLoadder] = useState(false);
    const [trafficLight, setTafficLight] = useState(false);
    const [popUpMessage, setPopupMessage] = useState("");
    const userTonTickets = useSelector((state) => state?.user?.userTonTickets);
    const currentPoint = useSelector((state) => state?.point?.currentPoint);
    const [initialUserPoint, setInitialUserPoint] = useState(currentPoint);


    const defaultOptions = {
        loop: false, // Set to true if you want the animation to repeat
        autoplay: true,
        animationData: ConfettiVertical, // Your Lottie animation data
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };


    useEffect(() => {
        if (success && congratsSuccessPopup) {
            setShowLotties(true);
            const timer = setTimeout(() => {
                setShowLotties(false);
            }, 7000); // Confetti lasts for 3000 milliseconds (3 seconds)

            return () => clearTimeout(timer); // Cleanup timer on component unmount
        }
    }, [success, congratsSuccessPopup]);


    useEffect(() => {
        if (!congratsSuccessPopup) {
            // Reset Lottie animation when the popup closes
            setShowLotties(false);
        }
    }, [congratsSuccessPopup]);


    const handleBoxClick = (ticket) => {
        if (ticket?.ticketCost > currentPoint) {
            setNotEnoughPoint(true);
            return;
        }
        setTicket(ticket);
        setOpenPreTonConfirmModal(true);
    };

    const handleCloseTONModal = () => {
        setOpenPreTonConfirmModal(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <Box
            sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                background: "#1B1529FA",
                height: "492px",
                zIndex: 1000000,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                animation: `${slideUp} 200ms ease-out forwards`,
                borderRadius: "25px",
                boxSizing: "border-box",
                padding: "20px",

            }}
        >
            <IconButton
                onClick={() => setOpenPopup2(false)}
                sx={{
                    position: "absolute",
                    top: "12px",
                    right: "12px",
                    color: "#fff",
                }}
            >
                <CloseIconn width={16} height={16} />
            </IconButton>





            {/* all popups here  */}
            {openModal && (
                <MultitapModal
                    setTafficLight={setTafficLight}
                    setPopUpMessage={setPopUpMessage}
                    setOpenModal={setOpenModal}
                    setNotEnoughPoint={setNotEnoughPoint}
                    setModalLoadder={setModalLoadder}
                    loadder={modalLoadder}
                    setSuccess={setSuccess}
                    setResultPopUp={setResultPopUp}
                    onClose={handleCloseModal}
                    content={modalContent}
                />
            )}
            {openPreTonConfirmModal && (
                <TonPreConfirmPopup
                    // setTafficLight={setTafficLight}
                    setPopUpMessage={setPopUpMessage}
                    setOpenPreTonConfirmModal={setOpenPreTonConfirmModal}
                    // setNotEnoughPoint={setNotEnoughPoint}
                    setModalLoadder={setModalLoadder}
                    loadder={modalLoadder}
                    setSuccess={setSuccess}
                    SetCongratsSuccessPopup={SetCongratsSuccessPopup}
                    onClose={handleCloseTONModal}
                    // content={modalContent}
                    setSubtext={setSubtext}
                    ticket={ticket}
                    SetTicketLimitExceed={SetTicketLimitExceed}
                />
            )}
            <div>

                {showLotties && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 9999,
                            pointerEvents: 'none',
                        }}
                    >
                        <Lottie
                            options={defaultOptions}
                            height={window.innerHeight}
                            width={window.innerWidth}
                            isStopped={!showLotties} // Stop the animation when showConfetti is false
                            speed={1.5} // Adjust this for speed, 1.5 means 1.5x speed
                        />
                    </div>
                )}

                {resultPopUp && (
                    <SuccessFailPopUp
                        navigate={navigate}
                        customMessage={popupMessage}
                        setResultPopUp={setResultPopUp}
                        success={success}
                    />
                )}

                {success && congratsSuccessPopup && (
                    <div style={{ position: 'relative' }}>
                        <CongratsPopUp
                            customMessage={popupMessage}
                            subtext={subtext}
                            setSubtext1={setSubtext1}
                            setSubtext={setSubtext}
                            SetCongratsSuccessPopup={SetCongratsSuccessPopup}
                            setShowConfetti={setShowConfetti}
                        />
                    </div>
                )}
            </div>


            {ticketLimitExceed && (
                <TicketLimitExceedModal
                    SetTicketLimitExceed={SetTicketLimitExceed}
                    customMessage={popUpMessage}
                    setSubtext={setSubtext}
                    subtext={subtext}

                />
            )}



            {/* tokes box */}
            
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        height:'393px',
                        flexDirection: "column",
                        overflowY: 'auto',
                    }}
                >
                    <Typography
                        sx={{
                            color: "#FFFFFF",
                            fontWeight: 700,
                            fontSize: "18px",
                            fontFamily: "Inter",
                            lineHeight: "23.4px",
                        }}
                    >
                        TON Prize Pool Points
                    </Typography>
                    {userTonTickets.map((ticket) => (
                        <Box
                            key={ticket._id}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: "black",
                                border: "1px solid #313130",
                                paddingX: "14px",
                                // paddingY: "5px",
                                borderRadius: "10px",
                                marginTop: "15px",
                                bgcolor: "#15131D",
                            }}
                            onClick={() => handleBoxClick(ticket)}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "7px",
                                    height: "80px",
                                }}
                            >
                                <Box>
                                    <img
                                        src={ticket.ticketLogoUrl}
                                        width={60}
                                        height={60}
                                        alt="Ticket"
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        component="p"
                                        variant="body1"
                                        sx={{
                                            color: "#FFFFFF",
                                            fontFamily: "Inter",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {FunctionCommas(ticket?.ticketCost)} Points
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            // gap: "2px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: "#FFFFFF",
                                                fontWeight: 400,
                                                fontSize: {
                                                    xs: "12px", // Font size 10px for extra small screens (below 400px)
                                                    sm: "15px", // Font size 12px for small screens and above
                                                },
                                                fontFamily: "Inter",
                                                marginTop: "7px",
                                            }}
                                        >
                                            {ticket.ticketDescription}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <img src={Arrow} alt="Arrow" width={32} height={32} />
                        </Box>
                    ))}
                    {congratsSuccessPopup && (
                        <CongratsPopUp
                            SetCongratsSuccessPopup={SetCongratsSuccessPopup}
                            success={success}
                            customMessage={popUpMessage}
                            subtext={subtext}
                            subtext1={subtext1}
                            setShowConfetti={setShowConfetti}
                        />
                    )}
                    {success && resultPopUp && (
                        <SuccessFailPopUp
                            setResultPopUp={setResultPopUp}
                            success={success}
                            customMessage={popupMessage}
                        />
                    )}
                </Box>

                {/* not enough poitns */}

                {notEnoughPoint && (
                    <Box
                        sx={{
                            position: "absolute",
                            height: "100vh",
                            width: "100vw",
                            backgroundColor: "#1b152993",
                            top: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                            animation: `${slideUp} 200ms ease-out forwards`,
                        }}
                    >
                        <Box
                            sx={{
                                position: "relative", // Add this for positioning the cross button
                                width: "350px",
                                height: "160px",
                                background: "linear-gradient(to top right, #7660FF, #C7BDFF)",
                                borderRadius: "16px",
                                display: "flex",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    width: "32px",
                                    height: "32px",
                                    color: "#FFFFFF",
                                }}
                                onClick={() => setNotEnoughPoint(false)}
                            >
                                <img src={CloseIcon} width={32} height={32} />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    height: "100%",
                                    flexDirection: "column",
                                    paddingLeft: "15px",
                                    paddingTop: "25px",
                                    gap: "10px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 800,
                                        fontSize: "30px",
                                        lineHeight: "36px",
                                        color: "#fff",
                                    }}
                                >
                                    Not Enough Points
                                </Typography>
                                <button
                                    style={{
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        background: "#674EFF",
                                        borderRadius: "8px",
                                        outline: "none",
                                        border: "none",
                                        color: "#fff",
                                        width: "125px",
                                        height: "34px",
                                    }}
                                    onClick={() => setOpenPopup2(false)}
                                >
                                    Keep Tapping
                                </button>
                            </Box>
                            <img
                                src={notEnoughPointCoin}
                                alt="not_enough_point"
                                style={{ width: "auto", height: "100%" }}
                            />
                        </Box>
                    </Box>
                )}
            
        </Box>
    );
};

export default TokenPopup;