import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Unsccuess from "../images/Unsuccess.svg";
import Sccuess from '../images/Icon-success.svg'
import {setBoosterPopVisible} from "../store/slices/userSlice";
import { useDispatch } from "react-redux";
import Sucesses from "../images/Sucesses.png"
import UnscuessIcon from "../images/UnscuessIcon.svg"
const SuccessFailPopUp = ({setResultPopUp,success,navigate,  customMessage ,  subtext1 }) => {

  const dispatch = useDispatch();
dispatch(setBoosterPopVisible(true));

  const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
  const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
  const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
const handelClick = () =>{
  if(success);
  if(!success){
    // dispatch(setBoosterPopVisible(true));
    setResultPopUp(false) ;
  }

}




  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#802020",
        height: "434px",
        zIndex: 10000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "25px",
        animation: `${slideUp} 200ms ease-out forwards`,
        borderRadius: "25px",
        boxSizing:"border-box"
      }}
    >
      <>
        <IconButton
          onClick={() => {
            // dispatch(setBoosterPopVisible(true));
            setResultPopUp(false);
          }}
          sx={{
            position: "absolute",
            top: "12px",
            right: "12px",
            color: "#fff",
            zIndex: 2005
          }}
        >
          <CloseIcon width={16} height={16} />
        </IconButton>
       {!success &&  <img src={ UnscuessIcon  } alt="icon" width={85} height={85} />}
       {success && <img src={ Sucesses  } alt="icon" width={115} height={94.88}  /> }
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            
            // height:"101px"
          }}
        >
          <Typography
            component="p"
            sx={{
              fontFamily: "Inter",
              fontWeight: 800,
              fontSize: "32px",
              lineHeight: "38.4px",
              color: "#fff",
            }}
          >
          {success ? "Success" :" Failure"}
          </Typography>
          {customMessage && (<Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16.94px",
              color: "#fff",
              marginTop:"12px",
              textAlign: 'center',
              width:"393px",

              // padding: '10px'
            }}
          >
            {customMessage}
          </Typography>
          )}
          {subtext1 && (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "16.94px",
              marginTop:"12px",
              color: "#FFFFFF",
              padding: "0 10px",
              textAlign: "center",
              width:"393px",
              marginTop:"12px" 
            }}
          >
           {subtext1}
          </Typography>
        )}
        </Box>

        <button
          style={{
            width: "350px",
            background: "#AA4545",
            height:"55px",
            outline: "None",
            border: "None",
            borderRadius: "12px",
            marginTop:"35px",
            marginBottom: "31px",
            border: '1px solid transparent', // Transparent border to allow background-clip
            backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
            backgroundOrigin: 'border-box', // Background starts from the border
            backgroundClip: 'padding-box, border-box', 
          }}
          onClick={()=>setResultPopUp(false)}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 873,
              fontSize: "18px",
              lineHeight: "23.4px",
              color: "#fff",
              textAlign:"center"
            }}
          >
          {success ? "Done":"Try again"}
          </Typography>
        </button>
      </>
    </Box>
  );
};

export default SuccessFailPopUp;
