// import React from "react";
// import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import Unsuccess from "../../images/CongratsSuccessModal.svg";
// import Success from '../../images/CongratsSuccessModal.svg';
// import { useNavigate } from 'react-router-dom';

// const CongratsPopUp = ({ SetCongratsSuccessPopup, success,  customMessage, subtext , unsuccessSubtext }) => {
//   const navigate = useNavigate();

//   const FadeContainer = styled(Box)`
  
//     animation: fadeIn 0.5s ease-in-out;
//     @keyframes fadeIn {
//       from {
//         opacity: 0;
//       }
//       to {
//         opacity: 1;
//       }
//     }
//   `;

//   const slideUp = keyframes`
//     0% {
//       transform: translateY(100%);
//       opacity: 0;
//     }
//     100% {
//       transform: translateY(0);
//       opacity: 1;
//     }
//   `;

//   const slideDown = keyframes`
//     0% {
//       transform: translateY(0);
//       opacity: 1;
//     }
//     100% {
//       transform: translateY(100%);
//       opacity: 0;
//     }
//   `;

// //   const handleClick = () => {
// //     if (success) {
// //         window.location.href= "https://join.klinkfinance.com/17iCBR3RkLb" // Redirect to the desired route if success
// //     } else {
// //       setResultPopUp(false); // Close the popup if failure
// //     }
// //   };

// // const handleClick = () => {
// //     window.location.href = "https://join.klinkfinance.com/17iCBR3RkLb";
// //   };

// //   const success = true; 

//   return (
//     <Box
//       sx={{
//         position: "absolute",
//         bottom: 0,
//         left: 0,
//         right: 0,
//         background: "#1B1529FA",
//         height: "393px",
//         zIndex: 1000000,
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         gap: "25px",
//         animation: `${slideUp} 200ms ease-out forwards`,
//         borderRadius: "25px",
//         boxSizing: "border-box",
//         padding: "20px",
//       }}
//     >
//       <IconButton
//         onClick={() => SetCongratsSuccessPopup(false)}
//         sx={{
//           position: "absolute",
//           top: "12px",
//           right: "12px",
//           color: "#fff",
//         }}
//       >
//         <CloseIcon width={16} height={16} />
//       </IconButton>
//       <img
//         src={success ? Success : Unsuccess}
//         alt={success ? "Success" : "Failure"}
//         width={160}
//         height={160}
//       />
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           textAlign: "center",
//         }}
//       >
//         <Typography
//           component="p"
//           sx={{
//             fontFamily: "Inter",
//             fontWeight: 800,
//             fontSize: "32px",
//             lineHeight: "38.4px",
//             color: "#fff",
//             letterSpacing: "-1px",
//           }}
//         >
//           {success ? "Congratulations!" : "Connect Klink Wallet"}
//         </Typography>
//         <Typography
//           sx={{
//             fontFamily: "Inter",
//             fontWeight: 400,
//             fontSize: "14px",
//             lineHeight: "16.94px",
//             color: "#fff",
//             marginTop: "12px",
//             padding: "0 20px",
//           }}
//         >
//           {customMessage}
//         </Typography>

//         <Typography
//           sx={{
//             fontFamily: "Inter",
//             fontWeight: 400,
//             fontSize: "12px",
//             lineHeight: "15px",
//             color: "#fff",
//             marginTop: "8px",
//             padding: "0 20px",
//             textAlign: "center",
//           }}
//         >
//           {success ? subtext : subtext }
//         </Typography>
      
//       </Box>
//       <button
//         style={{
//           width: "350px",
//           background: "#674EFF",
//           height: "55px",
//           outline: "none",
//           border: "none",
//           borderRadius: "12px",
//           marginTop: "20px",
//           cursor: "pointer",
//         }}
//         onClick={() => window.open("https://join.klinkfinance.com/17iCBR3RkLb") }
//       >
//         <Typography
//           sx={{
//             fontFamily: "Inter",
//             fontWeight: 873,
//             fontSize: "18px",
//             lineHeight: "23.4px",
//             color: "#fff",
//             textAlign: "center",
//           }}
         
//         >
//           {success ? "Go to Klink Wallet" : "Connect Klink account"}
//         </Typography>
//       </button>
//     </Box>
//   );
// };

// export default CongratsPopUp;

import React from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Unsuccess from "../../images/CongratsSuccessModal.svg";
import Success from '../../images/CongratsSuccessModal.svg';
import KlinkToken from '../../images/Klink-Coin.svg'; // Add your KlinkToken image path here
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { setBoosterPopVisible } from "../../store/slices/userSlice";
import { useDispatch } from "react-redux";

const CongratsPopUp = ({ SetCongratsSuccessPopup, success, customMessage, subtext, unsuccessSubtext, setShowConfetti }) => {
  
  const dispatch = useDispatch();
  dispatch(setBoosterPopVisible(true));

  const navigate = useNavigate();
  
  const handleClose = () => {
    dispatch(setBoosterPopVisible(true));
    SetCongratsSuccessPopup(false);
    setShowConfetti(false); // Reset confetti state
  };
  
  const handleClick = () => {
    const url = process.env.REACT_APP_BRANCH_URL;
  
    if (window.Telegram.WebApp) {
      // Open the link using Telegram WebApp's openLink method
      window.Telegram.WebApp.openLink(url);
    } else {
      // Fallback to the default behavior if Telegram WebApp is not available
      if (isIOS) {
        window.location.href = url;
      } else if (isAndroid) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  };

  const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;

  const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

  const slideDown = keyframes`
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  `;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#1B1529FA",
        height: "393px",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // gap: "25px",
        animation: `${slideUp} 200ms ease-out forwards`,
        borderRadius: "25px",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      <IconButton
       onClick={handleClose}
        sx={{
          position: "absolute",
          top: "12px",
          right: "12px",
          color: "#fff",
          zIndex: 2005
        }}
      >
        <CloseIcon width={16} height={16} />
      </IconButton>
      <img
        src={success ? Success : Unsuccess}
        alt={success ? "Success" : "Failure"}
        width={160}
        height={160}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
         {!success && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '6px',
              // marginTop: "5px",
              marginBottom: "15px"
            }}
          >
            <img src={KlinkToken} alt="coin.svg" width={26} height={26} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: 800,
                  lineHeight: '28.8px',
                  letterSpacing: '-0.01em',
                  textAlign: 'center',
                  color: '#fff',
                }}
              >
                50,000
              </Typography>
            </div>
          </Box>
        )}
        <Typography
          component="p"
          sx={{
            fontFamily: "Inter",
            fontWeight: 800,
            fontSize: "32px",
            lineHeight: "38.4px",
            color: "#fff",
            letterSpacing: "-1px",
          }}
        >
          {success ? "Congratulations!" : "Connect TON Wallet"}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#fff",
            marginTop: "12px",
            padding: "0 20px",
          }}
        >
          {customMessage}
        </Typography>

        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#fff",
            // marginTop: "8px",
            padding: "0 20px",
            textAlign: "center",
          }}
        >
          {success ? subtext : subtext}
        </Typography>

       
      </Box>
      <button
        style={{
          width: "350px",
          background: "#674EFF",
          height: "55px",
          outline: "none",
          border: "none",
          borderRadius: "12px",
          marginTop: "10px",
          cursor: "pointer",
          marginBottom: "21px"
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 873,
            fontSize: "18px",
            lineHeight: "23.4px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {success ? "Go to TON wallet" : "Connect TON wallet"}
        </Typography>
      </button>
    </Box>
  );
};

export default CongratsPopUp;
