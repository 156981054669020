import { Box } from '@mui/material';
import React from 'react';
import YoutubeIcon from '../images/YoutubeVideoStatusIcon.svg';

const PlaceholderytuserStatus = () => {
  const openLink = () => {
    const youtubeUrl = 'https://www.youtube.com/watch?v=khrIjasO1nk';

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(youtubeUrl, { try_instant_view: false });
    } else {
      window.open(youtubeUrl, '_blank');
    }
  };

  return (
    <Box
      sx={{
        width: '36px',
        height: '36px',
        position: 'absolute',
        top: 16,
        right: '11px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backdropFilter: 'blur(50px)',
        padding: '2px',
        zIndex: 2,
        cursor: 'pointer', // Add pointer cursor to indicate clickability
      }}
      onClick={openLink} // Add onClick event to handle the link opening
    >
      <img
        src={YoutubeIcon}
        alt="user_icon"
        width={30}
        height={22}
        style={{ position: 'fixed', zIndex: 100, objectFit: 'cover' }}
      />
    </Box>
  );
};

export default PlaceholderytuserStatus;
