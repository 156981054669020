import {
    Box,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    keyframes,
    styled
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import ThreeSectionLodder from '../components/ThreeSectionLodder'
import { FunctionCommas } from '../utils/commasFun'
import tasksmallIMG from '../images/TaskReamingIMG.svg'
import ConectUserStatusSmall from '../images/ConectUserStatusSmall.svg'
import KlinkCoinPurple from '../images/KlinkCoinPurple.svg'
import TotalLifePoints from "../images/TotalLifePoints.svg"
import Smile from "../images/Smile.svg"
import FAQ from '../components/FAQ/Faq'
import CountUp from "react-countup";
import minerCoinSmall from '../images/minerCoinSmall.svg'
import KlinkSocialProfile_TikTok from '../images/KlinkSocialProfile_TikTok.svg'
import Dailyreward from '../components/DailyReward/Dailyreward'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserMiningInfo, handleStartMining } from '../Apis'
import infocircleIcon from '../images/infocircleIcon.svg'
import { setUserMiningInfo } from '../store/slices/userSlice'
import MiningRewardPopup from '../components/Modals/MiningRewardClaimPopup'
import star_background from "../images/Star_background.svg"
const Airdrop = () => {


    const currentPoint = useSelector((state) => state?.point?.currentPoint);

    const [initialRender, setInitialRender] = useState(true)
    const [loading, setLoading] = useState(false)


    const [isMining, setIsMining] = useState(false);
    const [openMinignRewadPopup, setpenMinignRewadPopup] = useState(false);
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const userData = useSelector((state) => state?.user.userData);
    const accessToken = useSelector((state) => state?.user?.accessToken);
    // const userMultiplier = useSelector((state) => state?.user?.userData?.miningMultiplier)
    const userminininfo = useSelector((state) => state?.user?.userminininfo)
    const completedTaskCount = useSelector((state) => state?.user?.userminininfo?.completedTaskCount)
    const totalTaskCount = useSelector((state) => state?.user?.userminininfo?.totalTaskCount)
    const KlinkAirdropPoints = useSelector((state) => state?.user?.userminininfo?.AirdropPoints)
    const isMiningActive = useSelector((state) => state?.user?.userminininfo?.isMiningActive)
    const hourlyRewards = useSelector((state) => state?.user?.userminininfo?.hourlyRewards)
    const lastMiningEndTime = useSelector((state) => state?.user?.userminininfo?.lastMiningEndTime)
    // const userMultiplier = useSelector((state) => state?.user?.miningMultiplier)

    const userMultiplier = useSelector((state) => state?.user?.userData?.miningMultiplier)
        // console.log("isMiningActive", isMiningActive);
    const remainingTasks = totalTaskCount - completedTaskCount;
    // console.log("Airdrop userminininfo", userminininfo);

    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState('')
    const [timerEnded, setTimerEnded] = useState(false);
    const [derivedMultiplier, setDerivedMultiplier] = useState(null);




    const handleConnectClick = () => {
        navigate('/register'); // Navigate to the /register page when clicked
    };

    // const calculateTimeLeft = (endTime) => {
    //     const now = new Date();
    //     const endTimeDate = new Date(endTime);
    //     const timeDiff = endTimeDate - now;

    //     if (timeDiff > 0) {
    //         const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    //         const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    //         const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    //         return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    //     }

    //     return isMiningActive; // Timer has ended
    // };

    // useEffect(() => {
    //     // Fetch the mining info when the component loads to get the correct state
    //     dispatch(getUserMiningInfo(userId, accessToken));

    //     if (lastMiningEndTime) {
    //         const interval = setInterval(() => {
    //             const formattedTime = calculateTimeLeft(lastMiningEndTime);
    //             setTimeLeft(formattedTime);

    //             if (formattedTime === "00:00:00" && !timerEnded) {
    //                 setIsMining(false); // Update local state
    //                 setTimerEnded(true); // Mark that the timer has ended
    //                 dispatch(setUserMiningInfo(userId, accessToken));

    //                 clearInterval(interval); // Clear the interval once the timer ends
    //             }
    //         }, 1000);

    //         return () => clearInterval(interval);
    //     }
    // }, [lastMiningEndTime, dispatch, userId, accessToken, timerEnded]);


    // const Miningstart = async () => {
    //     try {
    //         const response = await dispatch(handleStartMining(userId, accessToken));
    //         if (response) {
    //             setIsMining(true);
    //             setTimeLeft(calculateTimeLeft(lastMiningEndTime));
    //         }
    //     } catch (error) {
    //         alert("Failed to start mining.");
    //     }
    // };




// useEffect(() => {
//     // Fetch the mining info when the component loads to get the correct state
//     dispatch(getUserMiningInfo(userId, accessToken));

//     if (lastMiningEndTime) {
//         // Set initial time left immediately
//         const formattedTime = calculateTimeLeft(lastMiningEndTime);
//         setTimeLeft(formattedTime);

//         const interval = setInterval(() => {
//             const newTimeLeft = calculateTimeLeft(lastMiningEndTime);
//             setTimeLeft(newTimeLeft);

//             // Check if timer has ended
//             if (newTimeLeft === "00:00:00" && !timerEnded) {
//                 setIsMining(false); // Update local state
//                 setTimerEnded(true); // Mark that the timer has ended
//                 dispatch(setUserMiningInfo(userId, accessToken));
//                 clearInterval(interval); // Clear the interval once the timer ends
//             }
//         }, 1000);

//         return () => clearInterval(interval);
//     }
// }, [lastMiningEndTime, dispatch, userId, accessToken, timerEnded]);

// const Miningstart = async () => {
//     try {
//         const response = await dispatch(handleStartMining(userId, accessToken));
//         if (response) {
//             setIsMining(true);
//             // Set the correct time left immediately when mining starts
//             const formattedTime = calculateTimeLeft(lastMiningEndTime);
//             setTimeLeft(formattedTime);
//         }
//     } catch (error) {
//         alert("Failed to start mining.");
//     }
// };

// const calculateTimeLeft = (endTime) => {
//     const now = new Date();
//     const endTimeDate = new Date(endTime);
//     const timeDiff = endTimeDate - now;

//     if (timeDiff > 0) {
//         const hours = Math.floor(timeDiff / (1000 * 60 * 60));
//         const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
//         return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
//     }

//     return isMiningActive; // If time is up, return this instead of checking isMiningActive
// };


// useEffect(() => {
//     // Fetch the mining info when the component loads to get the correct state
//     dispatch(getUserMiningInfo(userId, accessToken));
//     setDerivedMultiplier(userMultiplier); 

//     if (lastMiningEndTime) {
//         // Set initial time left immediately
//         const formattedTime = calculateTimeLeft(lastMiningEndTime);
//         setTimeLeft(formattedTime);

//         const interval = setInterval(() => {
//             const newTimeLeft = calculateTimeLeft(lastMiningEndTime);

//             // Check if timer has ended
//             if (newTimeLeft === "00:00:00") {
//                 setIsMining(false); // Update local state
//                 setTimerEnded(true); // Mark that the timer has ended
//                 dispatch(setUserMiningInfo(userId, accessToken));
//                 clearInterval(interval); // Clear the interval once the timer ends
//             } else {
//                 setTimeLeft(newTimeLeft); // Only set time left if the timer hasn't ended
//             }
//         }, 1000);

//         return () => clearInterval(interval);
//     }
// }, [lastMiningEndTime, dispatch, userId, accessToken, timerEnded]);

useEffect(() => {
    // Fetch the mining info when the component loads to get the correct state
    dispatch(getUserMiningInfo(userId, accessToken));

    setDerivedMultiplier(userMultiplier); 

    if (lastMiningEndTime) {
        // Set initial time left immediately
        const formattedTime = calculateTimeLeft(lastMiningEndTime);
        setTimeLeft(formattedTime);

        const interval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(lastMiningEndTime);

            // Check if timer has ended
            if (newTimeLeft === "00:00:00") {
                dispatch(setUserMiningInfo(userId, accessToken));
                setIsMining(false); // Update local state
                setTimerEnded(true); // Mark that the timer has ended
                clearInterval(interval); // Clear the interval once the timer ends
            } else {
                setTimeLeft(newTimeLeft); // Only set time left if the timer hasn't ended
            }
        }, 1000);

        return () => clearInterval(interval);
    }
    console.log("userMultiplier", userMultiplier);
    
}, [lastMiningEndTime, dispatch, userId, accessToken, timerEnded,userMultiplier]);
const Miningstart = async () => {
    try {
        const response = await dispatch(handleStartMining(userId, accessToken));
        if (response) {
            setIsMining(true);
            // Set the correct time left immediately when mining starts
            const formattedTime = calculateTimeLeft(lastMiningEndTime);
            setTimeLeft(formattedTime);
        }
    } catch (error) {
        alert("Failed to start mining.");
    }
};

const calculateTimeLeft = (endTime) => {
    const now = new Date();
    const endTimeDate = new Date(endTime);
    const timeDiff = endTimeDate - now;

    if (timeDiff > 0) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    return null; // Return null if the timer is up
};




    const FadeContainer = styled(Box)`
      opacity: ${initialRender ? 0 : 1};
      transition: opacity 1s ease-in-out;
    `

    const location = useLocation();
    const showBoost = location.state?.showBoost || false;

    const [value, setValue] = useState(showBoost ? 'DailyBoost' : 'Mining');

    const changeValue = (_, v) => {
        if (v !== null) setValue(v)
    }



    const fromBottom = keyframes`
    0% {
          transform: translateY(20%);
          opacity: 0;
      }
      100% {
          transform: translateY(0);
          opacity: 1;
      }
  `





    return (
        <>


            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100vw',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <ThreeSectionLodder />
                </Box>
            ) : (
                <Box style={{
                    height: "84vh",
                    overflow: "hidden",
                    overflowY: "auto",
                    background: "transparent"
                }}>
                    <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%" }} alt="Star Background" />
                    <Box
                        // m={1}
                        sx={{
                            display: 'flex',
                            position: 'relative',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '16px',
                            marginTop: '29px',
                            // paddingLeft: '20px'
                            width: "100%"
                        }}
                    >
                        <ToggleButtonGroup
                            color='primary'
                            exclusive
                            value={value}
                            onChange={changeValue}
                            style={{ width: 'calc(100% - 25px)', marginLeft: "45px", marginRight: "0px" }}
                            sx={{
                                '& .MuiToggleButton-root': {
                                    // width: '190.5px',
                                    width: "50%",
                                    height: '31px !important',
                                    background: '#15141D',
                                    borderRadius: '8px',
                                    color: 'white !important',
                                    marginLeft: '-15px'
                                },
                                '& .Mui-selected': {
                                    backgroundColor: '#802020 !important',
                                    color: 'white !important',
                                    borderRadius: '8px',
                                    borderColor:'#1D1413',

                                    zIndex: 1,
                                    // width: '180.5px',
                                    width: "50%",
                                }

                            }}
                        >
                            <ToggleButton
                                value={'Mining'}
                                sx={{
                                    fontWeight: 700,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#FFFFFF',
                                    textTransform: 'capitalize',
                                }}

                            >
                                Mining
                            </ToggleButton>
                            <ToggleButton
                                value={'DailyBoost'}
                                sx={{
                                    fontWeight: 700,
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#FFFFFF',
                                    textTransform: 'capitalize',
                                }}
                            >
                                Daily Boost
                            </ToggleButton>
                        </ToggleButtonGroup>


                        {/* {value === 'Myactivity' && (
                <Popup
                  message={
                    !userData?.isUserVerified
                      ? 'Klink account is not connected'
                      : 'Klink account is connected'
                  }
                  status={userData?.isUserVerified ? 'SUCCESS' : 'REJECT'}
                />
              )} */}
                    </Box>

                    {value === 'Mining' && (
                        <>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginLeft: "22px", marginRight: "21px" }}>
                                <Box
                                    sx={{
                                        backgroundColor: "rgba(251, 196, 77, 0.1)", // Use rgba for transparent background
                                        // width: "350px",
                                        width: "100%",
                                        height: "34px",
                                        borderRadius: "8px",
                                        marginTop: "16px",
                                        border: "1px solid #000",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            height: "100%",
                                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', // to center the content vertically
                                            // gap: "10px"
                                        }}
                                    >
                                        {/* First Item - Image and Task Text */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "#fff",
                                                whiteSpace: "nowrap" // Prevent text wrapping
                                            }}
                                        >
                                            <img
                                                src={Smile}
                                                width={12}
                                                height={12}
                                                alt="Task icon"
                                                style={{ marginRight: '5px',marginTop:"4px" }}
                                            />
                                            <Typography
                                                sx={{
                                                    color: "#FFFFFF",
                                                    fontFamily: "Inter, sans-serif",
                                                    fontSize: "10px",
                                                    fontWeight: 700,
                                                    lineHeight: "normal", // Ensure line height doesn't push the text down
                                                }}
                                            >
                                                {completedTaskCount}/{totalTaskCount} Tasks Complete
                                            </Typography>
                                        </Box>



                                        <Box
                                            sx={{
                                                width: "1px",
                                                height: "18px", // Adjust the height as needed
                                                backgroundColor: "#3B2D45", // White vertical line
                                            }}
                                        />

                                        {/* Second Item - Multiplier */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "8px",  // Adjust the gap as needed
                                                color: "#FFFFFF"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "8px",
                                                    fontWeight: 700,
                                                    fontFamily: "Inter, sans-serif",
                                                    background: "#FBC44D",
                                                    borderRadius: "4px",
                                                    color: "#000000",
                                                    padding: "2px 4px"  // Optional padding for better spacing
                                                }}
                                            >
                                              {`X${userMultiplier ? userMultiplier.replace('x', '') : '1'}.00`}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "10px",
                                                    color: "#FFFFFF",
                                                    fontFamily: "Inter, sans-serif",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                Multiplier
                                            </Typography>
                                        </Box>


                                        {/* <Box
                                            sx={{
                                                width: "1px",
                                                height: "18px", // Adjust the height as needed
                                                backgroundColor: "#3B2D45", // White vertical line
                                            }}
                                        /> */}

                                        {/* Third Item - Connected */}
                                        <Box sx={{ display: "flex", alignItems: "center", color: "#fff" }}>
                                            {/* <img src={ConectUserStatusSmall}/> */}
                                            {/* <Box
                                                sx={{
                                                    width: '16px',
                                                    height: '16px',
                                                    position: 'relative',
                                                    backgroundColor: '#443961E5',
                                                    top: 0,
                                                    right: '2px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    borderRadius: '50px',
                                                    backdropFilter: 'blur(50px)',
                                                    padding: '2px',
                                                    zIndex: 10005
                                                }}

                                            >
                                                <img
                                                    src={ConectUserStatusSmall}
                                                    alt="user_icon"
                                                    width={16}
                                                    height={16}
                                                    style={{ position: 'fixed', zIndex: 100, objectFit: 'cover' }}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        width: '4.44px',
                                                        height: '4.44px',
                                                        background: `${userData?.isUserVerified ? '#00e600' : '#FB4667'}`,
                                                        top: 0,
                                                        borderRadius: '50px',
                                                        left: '2px',
                                                        zIndex: 10005
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    marginLeft: "2px",
                                                    color: "#FFFFFF",
                                                    fontFamily: "Inter, sans-serif",
                                                    fontSize: "10px",
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {userData?.isUserVerified ? "Connected" : "Disconnected"}
                                            </Typography> */}
                                        </Box>
                                    </Box>
                                </Box>


                                <Box sx={{ marginTop: "36px" }}>

                                    <Typography
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            lineHeight: '16.94px',
                                            textAlign: 'center',
                                            color: "#FFFFFF",
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Total $Sugar Boy Airdrop Points
                                    </Typography>

                                    {/* Flex container to align image and points in one line */}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center', // Centers content horizontally
                                        gap: '10px' // Adjust the space between image and text
                                    }}>
                                        {/* Image */}
                                        <img src={TotalLifePoints} alt="sugarBoy Coin" style={{ width: '38.28px', height: '38.28px' }} />

                                        {/* Points */}
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: "42px",
                                                color: "#FFFFFF",
                                                fontFamily: "Inter",
                                                lineHeight: "50.4px",
                                            }}
                                        >
                                            {FunctionCommas(KlinkAirdropPoints)}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* {!userData?.isUserVerified && (
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(251, 196, 77, 0.1)", // Use rgba for transparent background
                                            // width: "351px",
                                            width: "100%",
                                            height: "51.34px",
                                            borderRadius: "12px",
                                            marginTop: "16px",
                                            border: "1px solid #FBC44D80",
                                            display: "flex", // Flexbox to arrange items in a row
                                            alignItems: "center", // Vertically center the items
                                            justifyContent: "space-between", // Distribute space between items
                                            // padding: "0 12px", // Add padding to the left and right

                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={KlinkSocialProfile_TikTok} alt="connectKlink" style={{ marginRight: "8px", marginLeft: "8px" }} />
                                            <span
                                                style={{
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '18.2px',
                                                    textAlign: 'left',
                                                    color: "#ffffff",
                                                }}
                                            >
                                                Connect your Klink Account
                                            </span>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                padding: "10px",
                                                gap: "10px",
                                                borderRadius: "5px",
                                                width: "71px",
                                                height: "26px",
                                                backgroundColor: "#674EFF",
                                                marginRight: "8px",
                                                '&:hover': {
                                                    backgroundColor: "#5B42E7",  // Optional hover effect
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: 700,
                                                    lineHeight: '15.6px',
                                                    textAlign: 'left',
                                                    color: "#FFFFFF",

                                                }}
                                                onClick={handleConnectClick}
                                            >
                                                Connect
                                            </Typography>
                                        </Button>
                                    </Box>
                                )} */}
                                {isMiningActive && (
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(251, 196, 77, 0.1)", // Transparent background
                                            width: "146px",
                                            height: "30px",
                                            borderRadius: "8px",
                                            borderColor:"1px solid black",
                                            marginTop: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            padding: "0 12px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                flexWrap: "nowrap",
                                                gap: "6px",
                                                flex: 1,
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: "8px",
                                                        fontWeight: 700,
                                                        fontFamily: "Inter, sans-serif",
                                                        background: "#FBC44D",
                                                        borderRadius: "4px",
                                                        color: "#000000",
                                                        padding: "2px 4px",
                                                    }}
                                                >
                                                    X{userMultiplier.replace('x', '')}.00
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <img src={KlinkCoinPurple} alt="sugarBoy Coin" style={{ width: '12px', height: '12px', marginRight: '2.2px' }} />
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '10px',
                                                        fontWeight: 700,
                                                        lineHeight: '12.1px',
                                                        textAlign: 'left',
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    +{FunctionCommas(hourlyRewards)}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '6px',
                                                        fontWeight: 500,
                                                        lineHeight: '7.26px',
                                                        textAlign: 'left',
                                                        color: "#C7C8CA",
                                                    }}
                                                >
                                                    Income/Hour
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                                {remainingTasks > 0  && userData?.isUserVerified  && (
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(251, 196, 77, 0.1)", // Transparent background
                                            // width: "351px",
                                            width: 'calc(100% - 42px)',
                                            height: "51.33px",
                                            borderRadius: "12px",
                                            marginTop: "16px",
                                            border: "1px solid #FBC44D80",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            padding: "0 20px",
                                            marginLeft: "20px",
                                            marginRight: "21px",
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={infocircleIcon} alt="connectTON" style={{ marginRight: "8px" }} />
                                            <span
                                                style={{
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '16.94px',
                                                    textAlign: 'left',
                                                    color: "#ffffff",
                                                }}
                                            >
                                                {remainingTasks} tasks remain to start mining
                                            </span>
                                        </Box>
                                    </Box>
                                )}


                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                    marginX="auto"
                                    marginTop="50px"
                                    
                                >
                                    <Box
                                        width="300px"
                                        position="relative"
                                        
                                       
                                        sx={{
                                              
                                            borderRadius: "85.71px",
                                           '&:after': {
                                         content: '""',
                                         position: 'absolute',
                                         borderRadius: 'inherit', // Keep the same border-radius for inner border
                                        top: 6,  // Adjust these values to move the inner border further inside
                                        left: 6,
                                        right: 6,
                                        bottom: 6,
                                        border: '3px solid #15131D', // Inner inset border
                                        pointerEvents: 'none', // Make sure it doesn't affect button interaction
                                          },
                                             
                                            padding: "4px",
                                            opacity: (!userData?.isUserVerified || remainingTasks > 0 || isMiningActive) ? 0.5 : 1,
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: "100%",
                                                maxWidth: "300px",
                                                height: "42.86px",
                                               backgroundColor: "#FBC44D",
                                                color: userData?.isUserVerified ? "#000000" : "#AFAFAF",
                                                borderRadius: "85.71px",
                                               
                                                fontFamily: 'Inter',
                                                fontSize: '15.43px',
                                                fontWeight: 700,
                                                lineHeight: '20.06px',
                                                letterSpacing: '-0.01em',
                                                textAlign: 'center',
                                                textTransform: "capitalize",
                                                boxShadow: !userData?.isUserVerified
                                                    ? "0px 4px 15px rgba(0, 0, 0, 0.6)"
                                                    : "none",
                                                pointerEvents: userData?.isUserVerified && !isMiningActive ? "auto" : "none",
                                                opacity: isMiningActive ? 0.2 : (userData?.isUserVerified && remainingTasks <= 0 ? 1 : 100),
                                                "&:hover": {
                                                    backgroundColor: "#FBC44D",
                                                },
                                                "&:active": {
                                                    backgroundColor: "#FBC44D",
                                                },
                                                "&:focus": {
                                                    backgroundColor: "#FBC44D",
                                                },
                                                position: "relative" // to ensure positioning for the timer
                                            }}
                                            disabled={!userData?.isUserVerified || remainingTasks > 0 || isMiningActive}
                                            onClick={Miningstart}
                                        >
                                            {isMiningActive ? (
                                                <Box sx={{ visibility: "hidden" , inline: "1px solid #FBC44D",color:"#000000"}}>
                                                    <img
                                                        src={minerCoinSmall}
                                                        width={17.94}
                                                        height={18}
                                                        style={{ marginRight: "6px", }}
                                                        
                                                        alt="Mining Icon"
                                                    />
                                                    Start Mining
                                                </Box>
                                            ) : (
                                                <>
                                                    <img
                                                        src={minerCoinSmall}
                                                        width={17.94}
                                                        height={18}
                                                        style={{ marginRight: "6px" }}
                                                        alt="Mining Icon"
                                                    />
                                                    <Typography sx={{ font: 'Inter', color: '#000000', fontWeight: 700, tabSize: '15.43px', lineHeight: "20.06", textAlign: "center" }}>Start Mining</Typography>
                                                </>

                                            )}
                                        </Button>

                                        {/* Timer on top of the button centered */}
                                        {isMiningActive && (
                                            <Box
                                                position="absolute"
                                                top="30%"
                                                left="40%"
                                                transform="translate(-50%, -50%)"
                                                zIndex={10000}
                                            >
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        fontSize: '15.43px',
                                                        fontWeight: 700,
                                                        lineHeight: '20.06px',
                                                        letterSpacing: '-0.01em',
                                                        textAlign: 'center',
                                                        color: "#FBC44D",
                                                        outline: "1px solid #FBC44D",
                                                    }}
                                                >
                                                    {timeLeft}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>










                                {/* <FAQ /> */}


                            </Box>
                        </>
                    )}
                    {value === 'DailyBoost' && (
                        <>
                            <Box sx={{
                                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginLeft: "22px", marginRight: "21px",
                                width: 'calc(100% - 43px)'
                            }}>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    lineHeight: '23.4px',
                                    letterSpacing: '-0.01em',
                                    textAlign: 'center',
                                    width: "350px",
                                    color: "#FFFFFF",
                                    marginTop: "32px"
                                }}>
                                    Log In every day and claim your bonuses!
                                </Typography>
                                {/* 
                                <Box
                                    sx={{
                                        backgroundColor: "rgba(251, 196, 77, 0.1)", // Use rgba for transparent background
                                        width: "335px",
                                        height: "51.33px",
                                        borderRadius: "12px",
                                        marginTop: "16px",
                                        border: "1px solid #FBC44D80",
                                        display: "flex", // Flexbox to arrange items in a row
                                        alignItems: "center", // Vertically center the items
                                        justifyContent: "center", // Distribute space between items
                                        padding: "0 12px", // Add padding to the left and right
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Inter',
                                            fontSize: '15.43px',
                                            fontWeight: 700,
                                            textAlign: 'center',
                                            lineHeight: "20.06px",
                                            color: '#FBC44D',

                                        }}
                                    >
                                        {timeRemaining}
                                    </Typography>
                                </Box> */}

                                {/* {!userData?.isUserVerified && (
                                    <Box
                                        sx={{
                                            backgroundColor: "rgba(251, 196, 77, 0.1)",
                                            minWidth: "335px",
                                            height: "51.34px",
                                            borderRadius: "12px",
                                            marginTop: "16px",
                                            border: "1px solid #FBC44D80",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            padding: "0 12px",
                                        }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <img src={KlinkSocialProfile_TikTok} alt="connectKlink" style={{ marginRight: "8px" }} />
                                            <span
                                                style={{
                                                    fontFamily: 'Inter, sans-serif',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    lineHeight: '18.2px',
                                                    textAlign: 'left',
                                                    color: "#ffffff",
                                                }}
                                            >
                                                Connect your TON wallet
                                            </span>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                padding: "10px",
                                                gap: "10px",
                                                borderRadius: "5px",
                                                width: "71px",
                                                height: "26px",
                                                backgroundColor: "#FBC44D",
                                                '&:hover': {
                                                    backgroundColor: "#FBC44D",
                                                },
                                            }}
                                            onClick={() => navigate('/register')} // Redirect to /register on click
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter',
                                                    fontSize: '12px',
                                                    fontWeight: 700,
                                                    lineHeight: '15.6px',
                                                    textAlign: 'left',
                                                    color: "#000000",
                                                }}
                                            >
                                                Connect
                                            </Typography>
                                        </Button>
                                    </Box>
                                )} */}


                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "20px", }}>

                                    <Dailyreward />
                                </Box>
                            </Box>
                        </>
                    )}


                </Box>
            )}
        </>
    )
}

export default Airdrop