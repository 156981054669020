import { Box, Button, Grid, IconButton, keyframes, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import emailIcon from "../images/EmailIcon.svg"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ThreeSectionLodder from "../components/ThreeSectionLodder";
import accountConneccted from "../images/accountConnected.svg"
import { isIOS, isAndroid } from 'react-device-detect';
import EmailVerificationPopUp from '../components/Modals/EmailverficationPopup'
import EmailCubeIMG from '../images/EmailCubeIMG.svg'
import { emailVerification } from "../Apis";
import ConnectToWalletPopup from "../components/Modals/ConnectToWalletPopup";
import WalletImg from "../images/wallet_bigImg.svg";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const EmailVerification = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [boxMessage, setBoxMessage] = useState("Email Verification Failed");
    const [accounConnectedModal, setAccounConnectedModal] = useState(false);
    const userId = useSelector((state) => state.user.userId);
    const userData = useSelector((state) => state?.user?.userData);
    const accessToken = useSelector((state) => state?.user?.accessToken)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [emailVerificationPopup, setEmailVerificationPopup] = useState({ open: false, success: false, customMessage: '', subtext: '' });
    const [tronAddress, setTronAddress] = useState('');
    const [error, setError] = useState('');
    const [openConnectWalletPopup, setOpenConnectWalletPopup] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (isValidTronAddress(tronAddress)) {
    //         setError('');
    //         // Proceed with the form submission logic
    //         dispatch(emailVerification(userId, tronAddress, accessToken));
    //         setOpenConnectWalletPopup(true);

    //     } else {
    //         setError('Invalid TRON wallet address');
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isValidTronAddress(tronAddress)) {
            setError(''); // Clear previous errors

            try {
                // Dispatch email verification and await it to handle potential errors
                await dispatch(emailVerification(userId, tronAddress, accessToken));
                setOpenConnectWalletPopup(true); // Open popup if successful

            } catch (error) {
                // Display the error message if verification fails
                setError(error.message || 'Failed to verify address');
            }

        } else {
            setError('Invalid TRON wallet address');
            setOpenSnackbar(true);
        }
    };
    const onClose = () => {
        navigate('/earn');
        setOpenConnectWalletPopup(false);
    }

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };


    const linkRef = useRef();


    useEffect(() => {
        setTimeout(() => {
            setIsMessageBoxOpen(false);
        }, 3000)
    }, [boxMessage])


    const handleTronChange = (e) => {
        setTronAddress(e.target.value);
    };

    const isValidTronAddress = (address) => {
        // Regular expression to validate a TRON wallet address (starts with T, followed by 33 characters: letters and numbers)
        const tronRegex = /^T[a-zA-Z0-9]{33}$/;
        return tronRegex.test(address);
    };

    const fadeIn = keyframes`

  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
    return (
        <div
            style={{
                // background:"#fff",
                width: "100%",
                height: "82vh",
                overflowY: "scroll",
                overflowX: "hidden",
                boxSizing: "border-box",
                // paddingBottom: "10px",

            }}
        >
            {/* <UserRegisterStatus /> */}

            {/* Image and Text  */}
            <Box
                sx={{
                    display: 'flex', // Enables flexbox
                    flexDirection: 'column',
                    justifyContent: 'center', // Horizontally centers the image
                    alignItems: 'center', // Vertically centers the image
                }}
            >
                <Box
                    component="img"
                    sx={{
                        height: '160px', // Keep aspect ratio
                        width: '160px',
                        mt: '19px'
                    }}
                    alt="Connect Email Image"
                    src={WalletImg}
                />
                <Box
                    sx={{
                        mt: '11px'
                    }}
                >
                    <Typography
                        sx={{

                            color: 'white', // Custom text color
                            fontSize: '24px',
                            fontWeight: 800,
                            fontFamily: 'inter',
                            lineHeight: "28.8px"
                        }}
                    >
                        Specify your TRON Address
                    </Typography>
                </Box>
            </Box>


            <Box
                sx={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    mt: '17px',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "65px",

                }}
                className={`scroll-transition ${isKeyboardVisible ? 'scroll-up' : ''}`}

            >
                {/* register info box */}
                {/* <form
                    style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "start",
                        gap: "25px",
                        flexDirection: "column",
                    }}
                    onSubmit={handelSubmit}
                >
                    <Box
                        sx={{
                            background: "#31313080",
                            padding: "10px",
                            width: "100%",
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "center",
                            boxSizing: "border-box",

                        }}
                    >
                        <input type="email" ref={inputRef} required style={{
                            width: "90%",
                            fontFamily: "inter",
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "23.4px",
                            color: "#fff",
                            background: "transparent",
                            outline: "none",
                            border: "none",
                            backgroundColor: "transparent"

                        }}
                            placeholder="Enter your TON address here"
                            name="TON address"
                            onChange={handelEmailChange}
                        // onFocus={handleFocus}
                        // onBlur={handleBlur}
                        />
                    </Box>
                    <button

                        style={{
                            outline: "none",
                            border: "none",
                            padding: "16px",
                            width: "100%",
                            borderRadius: "12px",
                            background: "#AA4545",
                            fontFamily: "inter",
                            fontWeight: 700,
                            fontSize: "18px",
                            lineHeight: "23.4px",
                            color: "#fff",
                            cursor: "pointer"
                        }}
                        type="submit"
                    >

                        Done
                    </button>
                </form> */}
                <form
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        gap: '25px',
                        flexDirection: 'column',
                    }}
                    onSubmit={handleSubmit}
                >
                    <Box
                        sx={{
                            background: '#31313080',
                            padding: '10px',
                            width: '100%',
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            boxSizing: 'border-box',
                        }}
                    >
                        <input
                            type="text"
                            value={tronAddress}
                            onChange={handleTronChange}
                            required
                            style={{
                                width: '90%',
                                fontFamily: 'inter',
                                fontWeight: 400,
                                fontSize: '18px',
                                lineHeight: '23.4px',
                                color: '#fff',
                                background: 'transparent',
                                outline: 'none',
                                border: 'none',
                                backgroundColor: 'transparent',
                            }}
                            placeholder="Enter your TRON address here"
                            name="tronAddress"
                        />
                    </Box>
                    {/* {error && <span style={{ color: '#8B0000' }}>{error}</span>} */}
                    <button
                        style={{
                            border: '1px solid transparent', // Transparent border to allow background-clip
                            backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                            backgroundOrigin: 'border-box', // Background starts from the border
                            backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                            padding: '16px',
                            width: '100%',
                            borderRadius: '12px',
                            // background: '#AA4545',
                            fontFamily: 'inter',
                            fontWeight: 700,
                            fontSize: '18px',
                            lineHeight: '23.4px',
                            color: '#fff',
                            cursor: 'pointer',
                            // border:"1px solid white"
                        }}
                        type="submit"
                    >
                        Done
                    </button>

                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} sx={{marginBottom:"200px",borderRadius:"10px",width: "calc(90% - 0px)",marginLeft:"10px",marginRight:"10px"}}>
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </form>

                {openConnectWalletPopup && <ConnectToWalletPopup onClose={onClose} />}

                {/* {emailVerificationPopup.open && (
                    <EmailVerificationPopUp
                        SetEmailVerificationPopup={(open) => setEmailVerificationPopup(prev => ({ ...prev, open }))}
                        success={emailVerificationPopup.success}
                        customMessage={emailVerificationPopup.customMessage}
                        subtext={emailVerificationPopup.subtext}
                    />
                )} */}
            </Box>

            {/* {
                isOpen && <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: '#1B1529FA',
                        height: '493px',
                        zIndex: 1000000,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '25px',
                    }}
                >
                    <IconButton

                        sx={{
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            color: '#fff',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center"

                        }}
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box>

                        <ThreeSectionLodder />
                    </Box>

                </Box>
            } */}
            {/* 
            {
                isMessageBoxOpen &&

                <Box
                    sx={{
                        width: "300px",
                        height: "50px",
                        position: "absolute",
                        background: "#674EFF",
                        top: "10px",
                        left: "35px",
                        right: 0,
                        borderRadius: "12px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: " 1px solid #313130",
                        animation: `${fadeIn} 0.5s  forwards`
                    }}

                >
                    <Typography
                        sx={{
                            fontFamily: "inter",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "28.8px",
                            color: "#fff",
                            textAlign: "center",


                        }}
                    >
                        {boxMessage}
                    </Typography>
                </Box>
            } */}

            {/* 
            {
                accounConnectedModal && <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: "#1B1529FA",
                        height: "393px",
                        zIndex: 1000000,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                        animation: `${slideUp} 200ms ease-out forwards`,
                        borderRadius: "25px",
                        boxSizing: "border-box"
                    }}
                >
                    <>
                        <IconButton

                            sx={{
                                position: "absolute",
                                top: "12px",
                                right: "12px",
                                color: "#fff",
                            }}

                            onClick={() => setAccounConnectedModal(false)}
                        >
                            <CloseIcon width={16} height={16} />
                        </IconButton>
                        <img src={accountConneccted} alt="icon" width={160} height={160} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                height: "101px"
                            }}
                        >
                            <Typography
                                component="p"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 800,
                                    fontSize: "32px",
                                    lineHeight: "38.4px",
                                    color: "#fff",
                                    letterSpacing: "-1px"
                                }}
                            >
                                Account Connected
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "16.94px",
                                    color: "#fff",
                                    marginTop: "12px",
                                    textTransform: 'none !important'
                                }}
                            >
                                Click below to be redirected to your TON wallet app
                            </Typography>
                        </Box>

                        <Button
                            style={{
                                width: "350px",
                                background: "#AA4545",
                                height: "55px",
                                outline: "None",
                                border: "None",
                                borderRadius: "12px",
                                marginX: 'auto',
                                textTransform: 'none'
                            }}
                            onClick={() => navigate("/earn")}
                        // href="https://join.klinkfinance.com/17iCBR3RkLb"
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    textAlign: "center"
                                }}
                            >
                                Done
                            </Typography>
                        </Button>
                    </>

                </Box>
            } */}
        </div >
    );
};
export default EmailVerification;