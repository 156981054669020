import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import infoImg from "../../images/TappedOutMainIMG.svg";
import { useSelector } from "react-redux";


const InviteMaxLimitPopup = ({ setOpenInviteMaxLimitReached }) => {

    const dailyRefEndTime = useSelector((state) => state?.user?.userData?.dailyRefEndTime);
    console.log("dailyRefEndTime",dailyRefEndTime);
    const [remainingTime, setRemainingTime] = useState('');


 

    useEffect(() => {
        if (dailyRefEndTime) {
            const endTime = new Date(dailyRefEndTime); // Convert the end time string to Date object

            const calculateTimeLeft = () => {
                const now = new Date(); // Get the current time
                const difference = endTime - now; // Calculate time difference in milliseconds

                if (difference > 0) {
                    const hours = Math.floor(difference / (1000 * 60 * 60)).toString().padStart(2, '0');
                    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
                    const seconds = Math.floor((difference % (1000 * 60)) / 1000).toString().padStart(2, '0');

                    setRemainingTime(`${hours}:${minutes}:${seconds}`);
                } else {
                    // Time has expired
                    setRemainingTime('00:00:00');
                }
            };

            // Call calculateTimeLeft every second
            const timerId = setInterval(calculateTimeLeft, 1000);

            // Clean up the interval when the component unmounts
            return () => clearInterval(timerId);
        }
    }, [dailyRefEndTime]);


    console.log("dailyRefEndTime", dailyRefEndTime);

    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "#1B1529FA",
                    height: "465px",
                    zIndex: 1000000,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: `${slideUp} 200ms ease-out forwards`,
                    borderRadius: "25px",
                    boxSizing: "border-box",
                    padding: "20px",
                    width: "100%"
                }}
            >

                {/* close button box */}
                <Box>
                    <IconButton
                        onClick={() => setOpenInviteMaxLimitReached(false)}
                        sx={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            color: "#fff",
                        }}
                    >
                        <CloseIcon width={16} height={16}/>
                    </IconButton>
                </Box>

                {/* image box */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '10rem',
                        width: "10rem"
                    }}
                >
                    <img
                        src={infoImg}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'  // Ensures the image scales proportionally
                        }}
                        alt="Tap Point"
                    />
                </Box>


                {/* last box with text and button */}
                <Box
                    sx={{
                        width: "100%",
                        height: "14.56rem",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1.56rem"


                    }}
                >

                    <Box
                        sx={{
                            width: "5.06rem",
                            height: "1.75rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            boxSizing: "border-box",
                            paddingRight: "6px",
                            paddingTop: "4px",
                            paddingLeft: "6px",
                            paddingBottom: "4px",
                            borderRadius: "8px",
                            bgcolor: "#FBC44D1A"
                        }}
                    >
                        {/* dymnamically fetching timer */}
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 700,
                                fontSize: "1rem",
                                lineHeight: "20px",
                                color: "#FBC44D",
                                textAlign: "center",

                            }}
                        >
                            {remainingTime}
                        </Typography>
                    </Box>
                    {/* Box1 */}
                    <Box
                        sx={{
                            width: "100%",
                            height: "5.87rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                        }}
                    >

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 800,
                                fontSize: "32px",
                                lineHeight: "38.4px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "10px"
                            }}
                        >
                            Thanks for Sharing
                        </Typography>

                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "16.94px",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                }}
                            >
                        You have invited the maximum number of friends


                            </Typography>


                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "16.94px",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                }}
                            >
                            today. You can invite more tomorrow.


                            </Typography>

                        </Box>


                    </Box>

                    {/* box2 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "1.56rem"

                        }}
                    >
                        {/* done buttons box */}

                        <button
                            style={{
                                width: "350px",
                                background: "#674EFF",
                                height: "55px",
                                outline: "none",
                                border: "none",
                                borderRadius: "12px",
                                marginTop: "10px",
                                cursor: "pointer",
                                marginBottom: "21px"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    textAlign: "center",
                                }}
                                onClick={() => setOpenInviteMaxLimitReached(false)}
                            >
                                Done
                            </Typography>
                        </button>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default InviteMaxLimitPopup;
