import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  CardMedia,
  Typography,
  styled,
  useMediaQuery
} from '@mui/material'
import { useSwipeable } from 'react-swipeable'
import Onboard1 from '../../images/Onboard1.svg'
import Onboard2 from '../../images/Onboard2.svg'
import Onboard3 from '../../images/OnBoard3Airdrop.svg'
// import Onboard4 from '../../images/Onboard4.svg'
import Onboard4 from '../../images/OnBoard4-3x.png'
import ArrowRight from '../../images/Arrowright.svg'
import GotITArrow from '../../images/Arrow.svg'
import star_background from '../../images/Star_background.svg'

const onboardingSteps = [
  {
    image: Onboard1,
    heading1: 'Welcome to Sugar Boy',
    heading2: 'TG mini app',
    paragraph1: 'Your gateway to earning rewards daily',
    paragraph2: 'through fun and interactive actions.'
  },
  {
    image: Onboard2,
    heading1: 'Tap to earn points',
    heading2: '',
    paragraph1: 'Earn points by tapping and completing easy',
    paragraph2: 'task, Update daily.'
  },
  {
    image: Onboard3,
    heading1: 'Spend points on',
    heading2: 'chosen rewards',
    paragraph1: 'Spend points on boost, or convert them for',
    paragraph2: 'TON prize pool points.'
  },
  // {
  //   image: Onboard4,
  //   heading1: 'Payouts directly',
  //   heading2: 'to Klink wallet',
  //   paragraph1: 'Connect your Klink wallet to receive your',
  //   paragraph2: 'rewards directly to your account.'
  // }
]

const preloadImages = imageArray => {
  imageArray.forEach(step => {
    const img = new Image()
    img.src = step.image
  })
}

const Onboarding = ({ onFinish }) => {
  const [step, setStep] = useState(0)
  const [swipeDirection, setSwipeDirection] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    preloadImages(onboardingSteps)
    setLoading(false)
  }, [])

  const isSmallScreen = useMediaQuery('(max-width: 360px)')
  const isMediumScreen = useMediaQuery(
    '(min-width: 361px) and (max-width: 480px)'
  )
  const isLargeScreen = useMediaQuery('(min-width: 452px)')

  const getImageStyle = step => {
    const image = onboardingSteps[step].image
    if (image === Onboard2) {
      return {
        width: '100%',
        maxWidth: '280px',
        height: 'auto',
        objectFit: 'cover',
        marginBottom: '10vh',
        marginTop: '29px'
      }
    } else if (image === Onboard3) {
      return {
        width: '100%',
        maxWidth: '300px',
        height: 'auto',
        objectFit: 'cover',
        marginBottom: '5px',
        marginTop: '1.81rem'
      }
    } else if (image === Onboard4) {
      return {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        marginLeft: '-10%',
        marginBottom: '3vh'
      }
    } else {
      return {
        width: '100%',
        maxWidth: '299.5px',
        height: 'auto',
        objectFit: 'contain',
        // marginLeft: '10%',
        marginBottom: '5vh'
      }
    }
  }

  const nextStep = () => {
    setSwipeDirection('right')
    setTimeout(() => {
      setStep(prevStep => (prevStep + 1) % onboardingSteps.length)
      setSwipeDirection(null)
    }, 300)
  }

  const prevStep = () => {
    setSwipeDirection('left')
    setTimeout(() => {
      setStep(
        prevStep =>
          (prevStep - 1 + onboardingSteps.length) % onboardingSteps.length
      )
      setSwipeDirection(null)
    }, 300)
  }

  const goToStep = index => {
    setSwipeDirection(index > step ? 'right' : 'left')
    setTimeout(() => {
      setStep(index)
      setSwipeDirection(null)
    }, 300)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (step < onboardingSteps.length - 1) nextStep()
    },
    onSwipedRight: () => {
      if (step > 0) prevStep()
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  const StyledImage = styled(Box)`
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: rotate 10s linear infinite;
  `

  const renderDots = () => (
    <Box display='flex' justifyContent='center' mt={2}>
      {onboardingSteps.map((_, index) => (
        <Box
          key={index}
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: step === index ? '#FFFFFF' : '#A7A5A3',
            margin: '0 5px',
            cursor: 'pointer'
          }}
          onClick={() => goToStep(index)}
        />
      ))}
    </Box>
  )

  if (loading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Typography variant='h4' color='#FFFFFF'>
          Loading...
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <StyledImage component='img' src={star_background} alt='Styled Image' />
      <Box sx={{ width: '100%', maxWidth: '390px', margin: '0 auto' }} />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='space-between'
        height='100%'
        px={2}
        py={4}
        {...handlers}
      >
        <Box
          sx={{
            width: '100%',
            height: 'calc(100% - 100px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
            opacity: swipeDirection ? 0 : 1,
            transform: swipeDirection ? 'scale(0.95)' : 'scale(1)'
          }}
        >
          <CardMedia
            component='img'
            image={onboardingSteps[step].image}
            alt={onboardingSteps[step].heading1}
            sx={getImageStyle(step)}
            loading='lazy'
          />
          <Typography
            variant='h4'
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '28px', sm: '28px' },
              fontFamily: 'Inter',
              fontWeight: 800,
              textAlign: 'center',
              ...(step === 2 && { 
     marginTop: "5px"
              })
              // mb: 1
            }}
          >
            {onboardingSteps[step].heading1}
          </Typography>
          <Typography
            variant='h4'
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '28px', sm: '28px' },
              fontFamily: 'Inter',
              fontWeight: 800,
              textAlign: 'center',
              // mb: 2
              marginBottom: "5px",
            }}
          >
            {onboardingSteps[step].heading2}
          </Typography>
          <Typography
            variant='body1'
            sx={{
              color: '#f8f8f8',
              fontSize: { xs: '16px', sm: '16px' },
              fontFamily: 'Inter',
              fontWeight: 400,
              textAlign: 'center',

            }}
          >
            {onboardingSteps[step].paragraph1}
          </Typography>
          <Typography
            variant='body1'
            sx={{
              color: '#f8f8f8',
              fontSize: { xs: '16px', sm: '16px' },
              fontFamily: 'Inter',
              fontWeight: 400,
              textAlign: 'center',
            
            }}
          >
            {onboardingSteps[step].paragraph2}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: isLargeScreen ? '452px' : '332px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'fixed',
            bottom: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            paddingLeft: '29px',
            paddingRight: '29px',
            marginTop: "24px"
          }}
        >
          {renderDots()}
          {step < onboardingSteps.length - 1 ? (
            <Button
              variant='contained'
              onClick={nextStep}
              sx={{
                background: '#AA4545 !important',
                width: { xs: '60px', sm: '72px' },
                height: { xs: '60px', sm: '72px' },
                borderRadius: '50%',
                minWidth: 'auto'
              }}
            >
              <img src={ArrowRight} alt='Next' width={24} height={24} />
            </Button>
          ) : (
            <Button
              variant='contained'
              sx={{
                background: '#AA4545 !important',
                width: { xs: '140px', sm: '165px' },
                height: { xs: '60px', sm: '72px' },
                borderRadius: '72px',
                fontWeight: 873,

                fontFamily: 'Inter',
                fontSize: { xs: '14px', sm: '16px' }
              }}
              onClick={onFinish}
            >
              Got it{' '}
              <img
                src={GotITArrow}
                alt='Got It'
                style={{ marginLeft: '8px', width: '24px', height: '24px' }}
              />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Onboarding
