// import { Box, Button, Grid, IconButton, keyframes, TextField, Typography } from "@mui/material";
// import React, { useEffect, useRef, useState, useCallback } from "react";
// import klinkCoin from "../images/Klink-Coin.svg";
// import trophy from "../images/trophy.svg";
// import emailIcon from "../images/EmailIcon.svg"
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { emailVerification } from "../Apis";
// import CloseIcon from '@mui/icons-material/Close';
// import ThreeSectionLodder from "../components/ThreeSectionLodder";
// import DownloadKlinkStep1 from '../images/Step1.svg'
// import DownArrow from '../images/DownArrow.svg'
// import SignUpStep2 from '../images/Step2.svg'
// import ConnectEmail from '../images/Step3.svg'
// import accountConneccted from "../images/accountConnected.svg"
// import UserRegisterStatus from "../components/UserRegisterStatus"
// import { isIOS, isAndroid } from 'react-device-detect';
// import EmailVerificationPopUp from '../components/Modals/EmailverficationPopup'
// import connectWallet1 from "../images/connectWallet1.svg"
// import cupImg from "../images/cup.svg"
// import coinIcon from "../images/SugarboyCoin.svg"
// import connectWallet2 from "../images/ConnectWallet2.png"
// import ConnectToWalletPopup from "../components/Modals/ConnectToWalletPopup";
// import { setTonWalletAddress } from "../store/slices/userSlice"


// import { useTonConnectModal, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
// // import { TonConnectUIProvider, useTonConnect } from 'ton-connect';



// const Register = () => {
//   const [email, setEmail] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);
//   const [downloadModal, setDownloadModal] = useState(false);
//   const [boxMessage, setBoxMessage] = useState("Email Verification Failed");
//   const [accounConnectedModal, setAccounConnectedModal] = useState(false);
//   const userId = useSelector((state) => state.user.userId);
//   const userData = useSelector((state) => state?.user?.userData);
//   const accessToken = useSelector((state) => state?.user?.accessToken)
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const inputRef = useRef(null);
//   const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
//   const [emailVerificationPopup, setEmailVerificationPopup] = useState({ open: false, success: false, customMessage: '', subtext: '' });
//   const [isScrolled, setIsScrolled] = useState(false);
//   const formRef = useRef(null);

//   const [openConnectWalletPopup, setOpenConnectWalletPopup] = useState(false);

//   const [tonConnectUI] = useTonConnectUI(); // Get the TonConnect UI instance
//   const { open } = useTonConnectModal();
//   const { disconnect } = useTonConnectUI();
//   const address = useTonAddress(); // Get the connected wallet address
//   const [isConnected, setIsConnected] = useState(false);
//   const tonWalletAddress = useSelector((state) => state?.user?.tonWalletAddress);
//   const [walletAddress, setWalletAddress] = useState('');
//   const [totalValue, setTotalValue] = useState(0); // Assuming total value in the wallet is a number
//   const [hasShownPopup, setHasShownPopup] = useState(false);
//   const [inputAddress, setInputAddress] = useState('');
//   const [error, setError] = useState('');




//   // const handleConnectWallet = useCallback(() => {
//   //   // Check if wallet is already connected
//   //   if (address) {
//   //     alert("Wallet is already connected!");
//   //     return; // Prevent opening the connection modal
//   //   }

//   //   open(); // Open the TonConnect modal
//   // }, [open, address]);

//   // const ConnectConfirm = () => {
//   //   setOpenConnectWalletPopup(true);
//   //   dispatch(setTonWalletAddress(address));
//   //   // api logic of passing wallet addess of user and comletion of user registration

//   //   dispatch(emailVerification(userId, address, accessToken));
//   // }



//   const onClose = () => {
//     navigate('/earn');
//     setOpenConnectWalletPopup(false);
//   }


//   // useEffect(() => {
//   //   const timer = setTimeout(() => {
//   //     if (formRef.current) {
//   //       formRef.current.scrollIntoView({ behavior: "smooth" });
//   //     }
//   //   }, 500);

//   //   return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
//   // }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       // If the user scrolls, set isScrolled to false
//       if (isScrolled) {
//         setIsScrolled(false);
//       }
//     };

//     // Add scroll event listener
//     window.addEventListener('scroll', handleScroll);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [isScrolled]);
//   const handleFocus = () => {
//     setIsKeyboardVisible(true);
//     if (inputRef.current) {
//       inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   };

//   //   useEffect(() => {
//   //     const storedAddress = localStorage.getItem("walletAddress");
//   // },[]);




//   const handleWalletSubmit = () => {
//     console.log("this tonWalletAddress>>>", tonWalletAddress)
//     console.log("this walletAddress>>>", inputAddress)
//     if (tonWalletAddress === inputAddress) {
//       //  handelling api
//       dispatch(emailVerification(userId, tonWalletAddress, accessToken));
//       setOpenConnectWalletPopup(true);
//       setError(''); // Clear any previous error
//     } else {
//       setError('Wallet address does not match.');
//     }
//   };




//   const handleBlur = () => {
//     setIsKeyboardVisible(false);
//   };

//   const handleCloseModal = () => {
//     setDownloadModal(false);
//   };

//   const slideUp = keyframes`
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;
//   const handelSubmit = (e) => {
//     e.preventDefault();
//     navigate('/emailverfication');

//   }
//   const handelStartMission = () => {
//     if (userData?.isUserVerified) return;
//   }

//   const handelEmailChange = (e) => {
//     setEmail(e.target.value)
//   }
//   const linkRef = useRef();

//   const handleCopyClick = () => {
//     if (linkRef.current) {
//       linkRef.current.select();
//       document.execCommand('copy');
//       alert('Download link copied to clipboard!');
//     }
//   };
//   useEffect(() => {
//     setTimeout(() => {
//       setIsMessageBoxOpen(false);
//     }, 3000)
//   }, [boxMessage])

//   const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;
//   return (
//     <div
//       style={{
//         // background:"#fff",
//         width: "100%",
//         height: "82vh",
//         overflowY: "scroll",
//         overflowX: "hidden",
//         boxSizing: "border-box",
//         // paddingBottom: "10px",

//       }}


//     >
//       {/* <UserRegisterStatus /> */}
//       <Box
//         sx={{
//           paddingRight: "20px",
//           paddingLeft: "20px",
//           paddingTop: "40px",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           gap: "65px",
//           // overflowY: "scroll",

//         }}
//       // className={`scroll-transition ${isScrolled ? 'scroll-up' : ''}`}

//       >
//         {/* register info box */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignContent: "center",
//             gap: "25px",
//             flexDirection: "column"
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "start",
//               gap: "12px",
//               flexDirection: "column"
//             }}
//           >
//             <Typography
//               component={"h1"}
//               sx={{
//                 fontFamily: "inter",
//                 fontWeight: 800,
//                 fontSize: "24px",
//                 lineHeight: "28.8px",
//                 color: "#fff",
//                 textAlign: "center", // Center the entire text
//               }}
//             >
//               Connect your TRON wallet to
//               <br />
//               <span
//                 style={{
//                   display: "inline-block", // Ensures the span behaves like inline text
//                   width: "100%", // Takes up full width to center
//                   textAlign: "center", // Centers the text within the span
//                 }}
//               >
//                 this bot
//               </span>
//             </Typography>


//             {/* <Typography variant="h6" gutterBottom sx={{
//                 color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700,
//                 fontSize: "14px", lineHeight: "16.94px",
//               }}>
//                 How to Connect Klink TG Bot to Your Main Klink Account:
//               </Typography> */}
//             <div style={{ padding: '20px' }}>
//               <Grid container spacing={2} alignItems="center" justifyContent="center" >
//                 <Grid item xs={12} >
//                   <Grid container spacing={2} alignItems="center">
//                     <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
//                       <img src={connectWallet1} alt="Download Klink" style={{ display: 'block' }} />
//                     </Grid>
//                     <Grid item xs={10} sx={{ marginTop: "25px" }}>
//                       <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px", width: "232px", marginTop: "10px" }}>
//                         Play Sugar Boy:
//                       </Typography>
//                       <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px", width: "232px", marginTop: "10px" }}>
//                         from the telegram app
//                       </Typography>
//                     </Grid>
//                   </Grid>

//                   <Grid item xs={12} sx={{ marginBottom: "20px" }}>
//                     <Grid container spacing={2} alignItems="center">
//                       <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
//                         <img src={DownArrow} alt="Download Klink" style={{ display: 'block', paddingTop: "0px !important" }} />
//                       </Grid>
//                     </Grid>
//                   </Grid>

//                   <Grid item xs={12} sx={{ marginBottom: "20px" }}>
//                     <Grid container spacing={2} alignItems="center">
//                       <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
//                         <img src={DownArrow} alt="Download Klink" style={{ display: 'block', paddingTop: "0px !important" }} />
//                       </Grid>
//                     </Grid>
//                   </Grid>

//                   <Grid item xs={12} sx={{ marginBottom: "10px" }}>
//                     <Grid container spacing={2} alignItems="center">
//                       <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
//                         <img src={DownArrow} alt="Download Klink" style={{ display: 'block', paddingTop: "0px !important" }} />
//                       </Grid>
//                     </Grid>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <Grid container spacing={2} alignItems="flex-start">
//                       <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
//                         <img src={connectWallet2} alt="Download Klink" style={{ display: 'block', height: "48px", width: "48px" }} />
//                       </Grid>
//                       <Grid item xs={10} >
//                         <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px", width: "232px" }}>
//                           Connect TRON Wallet :
//                         </Typography>
//                         <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px", width: "232px", }}>
//                            Enter your TRON<br />account wallet to finish task.
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Grid>

//                 </Grid>
//               </Grid>
//             </div>
//           </Box>

//           <Box
//             sx={{
//               display: 'flex',
//               alignItems: "center",
//               padding: "10px",
//               background: "#802020",
//               border: '1px solid white', // Transparent border to allow background-clip
//               width: "100%",
//               boxSizing: "border-box",
//               borderRadius: "12px",
//               gap: "5px"

//             }}
//           >
//             <img src={cupImg} alt="Trophy_icon" width={40} height={40} />
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "5px"
//               }}
//             >
//               <Typography
//                 component={"p"}
//                 sx={{
//                   fontFamily: "Inter",
//                   fontWeight: 700,
//                   fontSize: "14px",
//                   lineHeight: "18.2px",
//                   color: "#FFFFFF",
//                 }}
//               >Sign up Reward</Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: "5px"
//                 }}
//               >
//                 <img src={coinIcon} alt="klink_coin" width={16} height={16} />
//                 <Typography
//                   sx={{
//                     fontFamily: "inter",
//                     fontWeight: 700,
//                     fontSize: "12px",
//                     lineHeight: "15.6px",
//                     color: "#fff",
//                   }}
//                 >50,000</Typography>
//               </Box>
//             </Box>
//           </Box>

//           <form
//           //  ref={formRef}
//             style={{
//               display: 'flex',
//               justifyContent: "center",
//               alignItems: "start",
//               gap: "25px",
//               flexDirection: "column",
//             }}
//             onSubmit={handelSubmit}
//           >
//             <Typography

//               component={"p"}
//               sx={{
//                 fontFamily: "inter",
//                 fontWeight: 800,
//                 fontSize: "24px",
//                 // lineHeight: "28.8px",
//                 color: "#fff",
//                 letterSpacing: "0px"
//               }}

//             >Enter your TRON Wallet address</Typography>
          
//             <button

//               style={{
//                 padding: "16px",
//                 width: "100%",
//                 borderRadius: "12px",
//                 background: "#AA4545",
//                 border: '1px solid transparent', // Transparent border to allow background-clip
//                 backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
//                 backgroundOrigin: 'border-box', // Background starts from the border
//                 backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
//                 fontFamily: "inter",
//                 fontWeight: 700,
//                 fontSize: "18px",
//                 lineHeight: "23.4px",
//                 color: "#fff",
//                 cursor: "pointer"
//               }}
//               type="submit"
//             >

//               {!loading ? "Submit" : "Loading"}
//             </button>
//           </form>


//         </Box>




//         {openConnectWalletPopup && <ConnectToWalletPopup onClose={onClose} />}




//         {/* {emailVerificationPopup.open && (
//           <EmailVerificationPopUp
//             SetEmailVerificationPopup={(open) => setEmailVerificationPopup(prev => ({ ...prev, open }))}
//             success={emailVerificationPopup.success}
//             customMessage={emailVerificationPopup.customMessage}
//             subtext={emailVerificationPopup.subtext}
//           />
//         )} */}
//       </Box>
//       {/* {
//         isOpen && <Box
//           sx={{
//             position: 'fixed',
//             bottom: 0,
//             left: 0,
//             right: 0,
//             background: '#1B1529FA',
//             height: '493px',
//             zIndex: 1000000,
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             gap: '25px',
//           }}
//         >
//           <IconButton

//             sx={{
//               position: 'absolute',
//               top: '16px',
//               right: '16px',
//               color: '#fff',
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//               alignItems: "center"

//             }}
//             onClick={() => setIsOpen(false)}
//           >
//             <CloseIcon />
//           </IconButton>

//           <Box>

//             <ThreeSectionLodder />
//           </Box>

//         </Box>
//       } */}
//       {/* 
//       {
//         isMessageBoxOpen &&

//         <Box
//           sx={{
//             width: "300px",
//             height: "50px",
//             position: "absolute",
//             background: "#674EFF",
//             top: "10px",
//             left: "35px",
//             right: 0,
//             borderRadius: "12px",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             border: " 1px solid #313130",
//             animation: `${fadeIn} 0.5s  forwards`
//           }}

//         >
//           <Typography
//             sx={{
//               fontFamily: "inter",
//               fontWeight: 500,
//               fontSize: "14px",
//               lineHeight: "28.8px",
//               color: "#fff",
//               textAlign: "center",


//             }}
//           >
//             {boxMessage}
//           </Typography>
//         </Box>
//       } */}


//       {/* {
//         accounConnectedModal && <Box
//           sx={{
//             position: "absolute",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             background: "#1B1529FA",
//             height: "393px",
//             zIndex: 1000000,
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: "15px",
//             animation: `${slideUp} 200ms ease-out forwards`,
//             borderRadius: "25px",
//             boxSizing: "border-box"
//           }}
//         >
//           <>
//             <IconButton

//               sx={{
//                 position: "absolute",
//                 top: "12px",
//                 right: "12px",
//                 color: "#fff",
//               }}

//               onClick={() => setAccounConnectedModal(false)}
//             >
//               <CloseIcon width={16} height={16} />
//             </IconButton>
//             <img src={accountConneccted} alt="icon" width={160} height={160} />
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 flexDirection: "column",

//                 height: "101px"
//               }}
//             >
//               <Typography
//                 component="p"
//                 sx={{
//                   fontFamily: "Inter",
//                   fontWeight: 800,
//                   fontSize: "32px",
//                   lineHeight: "38.4px",
//                   color: "#fff",
//                   letterSpacing: "-1px"
//                 }}
//               >
//                 Account Connected
//               </Typography>
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontWeight: 400,
//                   fontSize: "14px",
//                   lineHeight: "16.94px",
//                   color: "#fff",
//                   marginTop: "12px",
//                   textTransform: 'none !important'
//                 }}
//               >
//                 Click below to be redirected to your Klink app
//               </Typography>
//             </Box>

//             <Button
//               style={{
//                 width: "350px",
//                 background: "#674EFF",
//                 height: "55px",
//                 outline: "None",
//                 border: "None",
//                 borderRadius: "12px",
//                 marginX: 'auto',
//                 textTransform: 'none'
//               }}
//               onClick={() => navigate("/earn")}
//             // href="https://join.klinkfinance.com/17iCBR3RkLb"
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontWeight: 873,
//                   fontSize: "18px",
//                   lineHeight: "23.4px",
//                   color: "#fff",
//                   textAlign: "center"
//                 }}
//               >
//                 Done
//               </Typography>
//             </Button>
//           </>
//         </Box>
//       } */}
//     </div >
//   );
// };
// export default Register







import { Box, Button, Grid, IconButton, keyframes, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import klinkCoin from "../images/Klink-Coin.svg";
import trophy from "../images/trophy.svg";
import emailIcon from "../images/EmailIcon.svg"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailVerification } from "../Apis";
import CloseIcon from '@mui/icons-material/Close';
import ThreeSectionLodder from "../components/ThreeSectionLodder";
import DownloadKlinkStep1 from '../images/Step1.svg'
import DownArrow from '../images/DownArrow.svg'
import SignUpStep2 from '../images/Step2.svg'
import ConnectEmail from '../images/Step3.svg'
import accountConneccted from "../images/accountConnected.svg"
import UserRegisterStatus from "../components/UserRegisterStatus"
import { isIOS, isAndroid } from 'react-device-detect';
import EmailVerificationPopUp from '../components/Modals/EmailverficationPopup'
import connectWallet1 from "../images/connectWallet1.svg"
import cupImg from "../images/cup.svg"
import coinIcon from "../images/SugarboyCoin.svg"
import connectWallet2 from "../images/ConnectWallet2.png"
import ConnectToWalletPopup from "../components/Modals/ConnectToWalletPopup";
import { setTonWalletAddress } from "../store/slices/userSlice"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


import { useTonConnectModal, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
// import { TonConnectUIProvider, useTonConnect } from 'ton-connect';



const Register = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [boxMessage, setBoxMessage] = useState("Email Verification Failed");
  const [accounConnectedModal, setAccounConnectedModal] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const userData = useSelector((state) => state?.user?.userData);
  const accessToken = useSelector((state) => state?.user?.accessToken)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [emailVerificationPopup, setEmailVerificationPopup] = useState({ open: false, success: false, customMessage: '', subtext: '' });
  const [isScrolled, setIsScrolled] = useState(false);
  const formRef = useRef(null);

  const [openConnectWalletPopup, setOpenConnectWalletPopup] = useState(false);

  const [tonConnectUI] = useTonConnectUI(); // Get the TonConnect UI instance
  const { open } = useTonConnectModal();
  const { disconnect } = useTonConnectUI();
  const address = useTonAddress(); // Get the connected wallet address
  const [isConnected, setIsConnected] = useState(false);
  const tonWalletAddress = useSelector((state) => state?.user?.tonWalletAddress);
  const [walletAddress, setWalletAddress] = useState('');
  const [totalValue, setTotalValue] = useState(0); // Assuming total value in the wallet is a number
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const [inputAddress, setInputAddress] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
 




  // const handleConnectWallet = useCallback(() => {
  //   // Check if wallet is already connected
  //   if (address) {
  //     alert("Wallet is already connected!");
  //     return; // Prevent opening the connection modal
  //   }

  //   open(); // Open the TonConnect modal
  // }, [open, address]);

  // const ConnectConfirm = () => {
  //   setOpenConnectWalletPopup(true);
  //   dispatch(setTonWalletAddress(address));
  //   // api logic of passing wallet addess of user and comletion of user registration

  //   dispatch(emailVerification(userId, address, accessToken));
  // }



  const onClose = () => {
    navigate('/earn');
    setOpenConnectWalletPopup(false);
  }


  useEffect(() => {
    const timer = setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // If the user scrolls, set isScrolled to false
      if (isScrolled) {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);
  const handleFocus = () => {
    setIsKeyboardVisible(true);
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

//   useEffect(() => {
//     const storedAddress = localStorage.getItem("walletAddress");
// },[]);




  const handleWalletSubmit = () => {
    console.log("this tonWalletAddress>>>",tonWalletAddress)
      console.log("this walletAddress>>>",inputAddress)
    if (tonWalletAddress === inputAddress) {
      //  handelling api
      dispatch(emailVerification(userId, tonWalletAddress, accessToken));
      setOpenConnectWalletPopup(true);
      setError(''); // Clear any previous error
    } else {
      setError('Wallet address does not match.');
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
};




  const handleBlur = () => {
    setIsKeyboardVisible(false);
  };

  const handleCloseModal = () => {
    setDownloadModal(false);
  };

  const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
  const handelSubmit = (e) => {
    e.preventDefault();
    navigate('/emailverfication');

  }
  const handelStartMission = () => {
    if (userData?.isUserVerified) return;
  }

  const handelEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const linkRef = useRef();

  const handleCopyClick = () => {
    if (linkRef.current) {
      linkRef.current.select();
      document.execCommand('copy');
      alert('Download link copied to clipboard!');
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsMessageBoxOpen(false);
    }, 3000)
  }, [boxMessage])

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
  return (
    <div
      style={{
        // background:"#fff",
        width: "100%",
        height: "82vh",
        overflowY: "scroll",
        overflowX: "hidden",
        boxSizing: "border-box",
        // paddingBottom: "10px",

      }}


    >
      {/* <UserRegisterStatus /> */}
      <Box
        sx={{
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "65px",
          // overflowY: "scroll",

        }}
      // className={`scroll-transition ${isScrolled ? 'scroll-up' : ''}`}

      >
        {/* register info box */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            gap: "25px",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "12px",
              flexDirection: "column"
            }}
          >
            <Typography
              component={"h1"}
              sx={{
                fontFamily: "inter",
                fontWeight: 800,
                fontSize: "24px",
                lineHeight: "28.8px",
                color: "#fff",
                textAlign: "center", // Center the entire text
              }}
            >
              Connect your TON wallet to
              <br />
              <span
                style={{
                  display: "inline-block", // Ensures the span behaves like inline text
                  width: "100%", // Takes up full width to center
                  textAlign: "center", // Centers the text within the span
                }}
              >
                this bot
              </span>
            </Typography>


            {/* <Typography variant="h6" gutterBottom sx={{
                color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700,
                fontSize: "14px", lineHeight: "16.94px",
              }}>
                How to Connect Klink TG Bot to Your Main Klink Account:
              </Typography> */}
            <div style={{ padding: '20px' }}>
              <Grid container spacing={2} alignItems="center" justifyContent="center" >
                <Grid item xs={12} >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                      <img src={connectWallet1} alt="Download" style={{ display: 'block' }} />
                    </Grid>
                    <Grid item xs={10} sx={{ marginTop: "25px" }}>
                      <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px", width: "232px", marginTop: "10px" }}>
                        Play SugarBoy:
                      </Typography>
                      <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px", width: "232px", marginTop: "10px" }}>
                        from the telegram app
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={DownArrow} alt="Down Arrow" style={{ display: 'block', paddingTop: "0px !important" }} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={DownArrow} alt="Download Arrow" style={{ display: 'block', paddingTop: "0px !important" }} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={DownArrow} alt="Download Arrow" style={{ display: 'block', paddingTop: "0px !important" }} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={connectWallet2} alt="wallet connect" style={{ display: 'block', height: "48px", width: "48px" }} />
                      </Grid>
                      <Grid item xs={10} sx={{ marginTop: "10px" }}>
                        <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 700, fontSize: "14px", lineHeight: "16.94px", width: "232px" }}>
                          Connect Wallet :
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: "#FFFFFF", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "16.94px", width: "232px", marginTop: "10px" }}>
                          Come back <br />to this page and enter your <br />account wallet to finish task.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </div>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: "center",
              padding: "10px",
              background: "#802020",
              border: '1px solid white', // Transparent border to allow background-clip
              width: "100%",
              boxSizing: "border-box",
              borderRadius: "12px",
              gap: "5px"

            }}
          >
            <img src={cupImg} alt="Trophy_icon" width={40} height={40} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px"
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "18.2px",
                  color: "#FFFFFF",
                }}
              >Sign up Reward</Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                <img src={coinIcon} alt="_coin" width={16} height={16} />
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "15.6px",
                    color: "#fff",
                  }}
                >50,000</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
          
              border: '1px solid #FFFFFF',
              color: "#FFFFFF",
              borderRadius: '8px',
              padding: '16px',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "auto",
              width: "93%", // Adjust width based on your design
              gap: '16px', // Adds space between elements
            }}
          >
            {/* Textarea for wallet address */}
            <TextField
              label="Enter your connected wallet address"
              multiline
              variant="outlined"
              fullWidth
              value={inputAddress}
              onChange={(e) => setInputAddress(e.target.value)} // Update state on input change
              InputLabelProps={{
                style: { color: "#FFFFFF" } // Label color
              }}
              InputProps={{
                style: { color: "#FFFFFF" }, // Text color
              }}
              sx={{
                bgcolor: "#802020",
                marginBottom: "10px",
                borderRadius: "12px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#802020" ,// Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#802020" // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#802020" // Focused border color
                  }
                }
              }}
            />

            {/* Submit Button */}
            <Button
              variant="contained"
              sx={{
                bgcolor: "#FFFFFF",
                color: "#802020",
                fontWeight: 700,
                height: 'fit-content', // Adjust height of button to content
                ':hover': {
                  bgcolor: "#FFFFFFDD", // Slightly lighter on hover
                  borderRadius: '12px',
                }
              }}
              onClick={handleWalletSubmit} // Call the handle function on submit
            >
              Submit
            </Button>

            {/* Error Message */}
            {/* {error && (
              <Typography sx={{ color: 'red', marginTop: '10px' }}>
                {error}
              </Typography>
            )} */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} sx={{marginBottom:"200px",borderRadius:"10px",width: "calc(90% - 0px)",marginLeft:"10px",marginRight:"10px"}}>
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
            </Snackbar>
          </Box>




          {/* {address ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: "center",
                padding: "10px",
                background: "#802020",
                border: "1px solid #313130",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "12px",
                gap: "5px",
                justifyContent: "space-between"
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "18.2px",
                  color: "#FFFFFF",
                }}
              >
                Your wallet address: {`${address.slice(0, 3)}...${address.slice(-3)}`}
              </Typography>
              <button
                style={{
                  outline: "none",
                  border: "none",
                  padding: "10px",
                  borderRadius: "12px",
                  background: "#AA4545",
                  fontFamily: "inter",
                  color: "#fff",
                  cursor: "pointer"
                }}
                onClick={ConnectConfirm}
              >
                Connect
              </button>
            </Box>
          ) : (
            <button
              style={{
                border: '1px solid transparent', // Transparent border to allow background-clip
                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                backgroundOrigin: 'border-box', // Background starts from the border
                backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                padding: "10px",
                width: "100%",
                borderRadius: "12px",
                fontFamily: "inter",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "23.4px",
                color: "#fff",
                cursor: "pointer"
              }}
              onClick={handleConnectWallet}
            >
              Connect Wallet
            </button>
          )} */}


        </Box>




        {openConnectWalletPopup && <ConnectToWalletPopup onClose={onClose} />}




        {/* {emailVerificationPopup.open && (
          <EmailVerificationPopUp
            SetEmailVerificationPopup={(open) => setEmailVerificationPopup(prev => ({ ...prev, open }))}
            success={emailVerificationPopup.success}
            customMessage={emailVerificationPopup.customMessage}
            subtext={emailVerificationPopup.subtext}
          />
        )} */}
      </Box>
      {/* {
        isOpen && <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#1B1529FA',
            height: '493px',
            zIndex: 1000000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '25px',
          }}
        >
          <IconButton

            sx={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              color: '#fff',
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center"

            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box>

            <ThreeSectionLodder />
          </Box>

        </Box>
      } */}
      {/* 
      {
        isMessageBoxOpen &&

        <Box
          sx={{
            width: "300px",
            height: "50px",
            position: "absolute",
            background: "#674EFF",
            top: "10px",
            left: "35px",
            right: 0,
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: " 1px solid #313130",
            animation: `${fadeIn} 0.5s  forwards`
          }}

        >
          <Typography
            sx={{
              fontFamily: "inter",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "28.8px",
              color: "#fff",
              textAlign: "center",


            }}
          >
            {boxMessage}
          </Typography>
        </Box>
      } */}


      {/* {
        accounConnectedModal && <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#1B1529FA",
            height: "393px",
            zIndex: 1000000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            animation: `${slideUp} 200ms ease-out forwards`,
            borderRadius: "25px",
            boxSizing: "border-box"
          }}
        >
          <>
            <IconButton

              sx={{
                position: "absolute",
                top: "12px",
                right: "12px",
                color: "#fff",
              }}

              onClick={() => setAccounConnectedModal(false)}
            >
              <CloseIcon width={16} height={16} />
            </IconButton>
            <img src={accountConneccted} alt="icon" width={160} height={160} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

                height: "101px"
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 800,
                  fontSize: "32px",
                  lineHeight: "38.4px",
                  color: "#fff",
                  letterSpacing: "-1px"
                }}
              >
                Account Connected
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16.94px",
                  color: "#fff",
                  marginTop: "12px",
                  textTransform: 'none !important'
                }}
              >
                Click below to be redirected to your Klink app
              </Typography>
            </Box>

            <Button
              style={{
                width: "350px",
                background: "#674EFF",
                height: "55px",
                outline: "None",
                border: "None",
                borderRadius: "12px",
                marginX: 'auto',
                textTransform: 'none'
              }}
              onClick={() => navigate("/earn")}
            // href="https://join.klinkfinance.com/17iCBR3RkLb"
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 873,
                  fontSize: "18px",
                  lineHeight: "23.4px",
                  color: "#fff",
                  textAlign: "center"
                }}
              >
                Done
              </Typography>
            </Button>
          </>
        </Box>
      } */}
    </div >
  );
};
export default Register