import logo from "./logo.svg";
import CoinApp from "./pages/Tap";
import { BottomNavigation, Box, Button, CssBaseline, ThemeProvider, Typography, createTheme, keyframes } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import env from "react-dotenv";
import "./App.css";
import "./css/customStyle.css";
import './index.css'
import GoogleTrans from "./components/GoogleTranslator/Translator";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Mates from "./pages/Ref";
import BottomNavbar from './components/BottomNavbar/BottomNavbar'
import Earn from "./pages/Task";
import Boost from "./pages/Boost";
import Stats from "./pages/Stats";
import Modal from "./components/DailyReward"
import { useDispatch } from "react-redux";
import { SetBoostData, genrateAuthToken, getUserMiningInfo, getUserStats, setLevelsData, getUserTask, getUserTonTickets, init, onBoardUser, setData, RewardsData } from "./Apis";
import klinkQR from './images/qr.png'
import star_background from "./images/Star_background.svg"
import Levels from "./pages/Levels";
import { useSelector } from 'react-redux';
import Friends from "./pages/Ref";
import Onboarding from "./components/OnboardScreens/Onboard";
import ThreeSectionLodder from "./components/ThreeSectionLodder"
import Register from "./pages/Register";
import KlinkFLogo from './images/KlinkFLogo.svg'
import { setAccessToken, setRewardData, setUserDailyRewardReset } from "./store/slices/userSlice";
import { restoreEnergy, setCurrentEnergy } from "./store/slices/miningInfoSlice";
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import BlacklistedIMG from './images/BlacklistedPlacehoslderIMG.svg'
import FriendReferalIMG from './images/FriendReferalIMG.svg'
import EmailVerification from "./pages/EmailVerfication";
import PlaceholderytuserStatus from "./components/PlaceholderytuserStatus";
import UserLastActivePopup from "./components/Modals/UserLastActivePopup";
import Airdrop from "./pages/Airdrop";
import preloadimg from './images/preloadimg.png'
// import PreloaderScreen from "./components/PreloaderScreen/PreloaderScreen";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";

import WalletConnected from "./pages/WalletConnected"
import TotalLifePoints from "./images/TotalLifePoints.svg"
const theme = createTheme();
const telApp = window.Telegram.WebApp;
const isPhone = window.innerWidth < 600;

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /android|iphone|ipad|ipod|opera mini|iemobile|wpdesktop/i.test(userAgent.toLowerCase());
    setIsMobile(isMobile);
  }, []);

  return isMobile;
};

const useIsTelegramWebApp = () => {
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);


  useEffect(() => {
    const isTelegramWebApp = /TelegramDesktop|TelegramWeb/i.test(navigator.userAgent);
    setIsTelegramWebApp(isTelegramWebApp);
  }, []);

  return isTelegramWebApp;
};

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isFirstLaunch, setIsFirstLaunch] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const initailData = useSelector((state) => state.user.initailData);
  const userData = useSelector((state) => state?.user?.userData);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const userMiningInfo = useSelector((state) => state?.miningInfo?.miningInfo)
  const userCurrentEnergy = useSelector((state) => state?.miningInfo?.miningInfo.currentEnergy)
  const isblacklisted = useSelector((state) => state?.user?.userData?.isBlockListed)
  const userReferalCount = useSelector(state => state?.user?.userData?.referrals)
  // const initailData = useSelector((state) => state?.user?.initailData);
  const userminininfo = useSelector((state) => state?.user?.userminininfo)
  const dailyreward = useSelector((state) => state?.user?.dailyreward)
  const userDailyrewardResetTime = useSelector((state) => state?.user?.dailyRewardsResetAt)




  const streak = useSelector((state) => state?.user?.streak);

  const manifestUrl = process.env.TonConnectURL

  

  // const  boost = useSelector((state) =>  state?.user?.boosts)
  useEffect(() => {
    dispatch(init())

  }, [])

  const fromBottom = keyframes`
  0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
  const handleInviteClick = () => {
    const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}  🚀`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(
      "Join me on sugar boy's TG Bot and start earning crypto! Use the link to get a 5,000 points welcome bonus  \n\n"
    )}`;
    window.open(telegramShareUrl, "_blank");
  };


  const memoizedInviteClick = useCallback(() => {
    handleInviteClick();
  }, [handleInviteClick]);

  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const isMobile = useIsMobile();
  const isTelegramWebApp = useIsTelegramWebApp();
  const isTelegramMiniApp = true; // Replace this with your actual logic to detect Telegram Mini App

  const handleOnboardingFinish = () => {
    setIsOnboardingCompleted(true);
    dispatch(onBoardUser(userId, accessToken,initailData ));
  };


  const [startTime] = useState(performance.now());

  useEffect(() => {
    const renderTime = performance.now() - startTime;
    console.log(`this is App render time: ${renderTime.toFixed(2)}ms`);
  }, [startTime]);

  useEffect(() => {
    const interval = setInterval(async () => {

      if (userCurrentEnergy >= userData?.boosts?.energyLimit?.currentValue) {
        if (userData?.boosts?.energyLimit?.currentValue >= 1) {
          return
        }
        dispatch(setCurrentEnergy(userData?.boosts?.energyLimit?.currentValue));
        clearInterval(interval);
      } else {
        const energyLimit = userData?.boosts?.energyLimit?.currentValue;
        let amount = userData?.boosts?.rechargingSpeed?.currentValue;
        // dispatch(restoreEnergy({userData?.boosts?.rechargingSpeed?.currentValue, energyLimit}));
        dispatch(restoreEnergy({ amount, energyLimit }));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [userCurrentEnergy, userData?.boosts?.energyLimit?.currentValue]);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      // Set header color to bg_color from theme
      window.Telegram.WebApp.setHeaderColor('#802020');
    }
  }, []);

  useEffect(() => {
    if (telApp) {
      telApp.expand();
      // telApp.enableClosingConfirmation();
      telApp.enableClosingConfirmation();

      // Prevent the WebApp from closing on scroll
      telApp.onEvent('viewportChanged', () => {
        telApp.expand();
      });
    }
  }, []);

  useEffect(() => {
    // Clear the 'popupShown' flag on app load to ensure the popup shows on each new app open
    localStorage.removeItem('popupShown');
  }, []);

  //   useEffect(() => {
  //     localStorage.clear();
  //  }, []);

  useEffect(() => {
    dispatch(setData(userId,initailData, setLoading));
  }, [userId]);
  useEffect(() => {
    dispatch(SetBoostData(userId, accessToken));
    dispatch(getUserTask(userId, accessToken));
    dispatch(getUserTonTickets(userId, accessToken));
    dispatch(getUserStats(userId, setLoading, accessToken));
    dispatch(setLevelsData(userId, accessToken));
    dispatch(RewardsData(accessToken, userId))
    dispatch(getUserMiningInfo(userId, accessToken))
    

  }, [accessToken]);
  // Prevent right-click context menu
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    // Check if this is the first launch by checking sessionStorage
    if (!sessionStorage.getItem('visited')) {
      setIsFirstLaunch(true);
      sessionStorage.setItem('visited', 'true'); // Set this after the first launch
    } else {
      setIsFirstLaunch(false);
    }

    // Simulate loading time for initial launch or reload
    const timer = setTimeout(() => {
      setLoading(false); // Hide the preloader after the app is ready
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);



  // if (loading) {
  //   // First-time launch: display preload image
  //   if (isFirstLaunch) {
  //     return (
  //       <Box sx={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
  //   <PreloaderScreen/>
  //       </Box>
  //     );
  //   }
  // }


  return (
    <TonConnectUIProvider manifestUrl="https://sugarboy.blocsys.com/tonconnect-manifest.json">
    <WebAppProvider>
      <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
        {console.log("manifest url>>>>",manifestUrl)}

        {isblacklisted ? (
          <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <PlaceholderytuserStatus />
            <div style={{ height: '100vh', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', marginTop: "67px" }}>
              <Box sx={{ marginBottom: "17px" }}>
                <img src={BlacklistedIMG} width={232} height={191.01} alt="Error" />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "12px" }}>
                <Typography color={'white'} fontSize={'32px'} fontFamily={'Inter'} fontWeight={800} marginBottom={'-10px'}>
                  Suger Boy is at
                </Typography>
                <Typography color={'white'} fontSize={'32px'} fontFamily={'Inter'} fontWeight={800}>
                  full capacity!
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "35px" }}>
                <Typography color={'white'} fontSize={'14px'} fontFamily={'Inter'} fontWeight={400} textAlign={'center'} width={331}>
                  Please check back later, in the meantime invite 10 friends to get first access
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    border: '1px solid #FFFFFF',
                    opacity: '10%',
                    width: '80vw',
                    marginTop: '12px', // Adjust the margin as needed
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                ></Typography>
              </Box>
              <Box sx={{ marginTop: '24px', marginBottom: "28.81px" }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0px'
                  }}
                >
                  <Typography
                    color={'#FFFFFF'}
                    textAlign={'center'}
                    fontFamily={'Inter'}
                    fontSize={'12px'}
                    fontWeight={400}
                    lineHeight={'14.52px'}
                  >
                    Friends Invited
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '7px',
                    marginTop: '10px'
                  }}
                >
                  <img src={FriendReferalIMG} width={26} height={28} />
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '24px',
                      fontWeight: 800,
                      color: '#FFFFFF'
                    }}
                  >
                    {userReferalCount}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  // position: "fixed",
                  // bottom: "10%",

                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                  boxSizing: "border-box",
                  zIndex: 1000, // Ensure buttons are above other content
                  animation: `${fromBottom} 1s cubic-bezier(0.25, 0.8, 0.25, 1)`
                }}
              >
                <Button
                  sx={{
                    bgcolor: "#674EFF",
                    width: "350px",
                    height: "55px",
                    color: "#FFFFFF",
                    borderRadius: "12px",
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "23.4px",
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "#5A42E6", // Adjust hover color if needed
                    },
                  }}
                  onClick={memoizedInviteClick}
                >
                  Invite a friend
                </Button>

              </Box>
            </div>
          </div>
        ) : loading ? ( <
          Box sx={{ display: 'flex', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
           <ThreeSectionLodder/>
          </Box>
        ) : (
          <>
            {!isMobile && isTelegramMiniApp && !isTelegramWebApp ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: 'transparent', height: '100vh', zIndex: 'auto' }}>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <img src={KlinkFLogo} width={200} height={90} alt="sugar Boy Logo" />
                  <h1 style={{ color: 'white', fontFamily: 'Inter', fontWeight: 800, textAlign: 'center', marginTop: '30px' }}>
                    Leave the desktop. <span style={{ color: 'white', fontFamily: 'Inter', fontWeight: 800, display: 'inline-block' }}>Mobile gaming rocks!</span>
                  </h1>
                </div>
                <div style={{ width: '250px', height: '250px', borderRadius: '35px', background: '#FFFFFF', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '33px' }}>
                  <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%" }} alt="Star Background" />
                  <img src={klinkQR} width={250} height={250} style={{ borderRadius: '35px' }} alt="QR Code" />
                </div>
              </div>
            ) : (
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <UserLastActivePopup />
                  <Routes>
                    {!userData?.isVisited ? (
                      <Route path="/" element={<Onboarding onFinish={handleOnboardingFinish} />} />
                    ) : (
                      <>
                        <Route path="/" exact element={<CoinApp />} />
                        <Route path="/friends" element={<Friends />} />
                        <Route path="/earn" element={<Earn />} />
                        {/* <Route path="/boost" element={<Boost />} /> */}
                        <Route path="/airdrop" element={<Airdrop />} />
                        <Route path="/stats" element={<Stats />} />
                        <Route path="/daily-reward" element={<Modal />} />
                        <Route path="/user-level-details" element={<Levels />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/emailverfication" element={<EmailVerification />} />
                        <Route path="/WalletConnected" element={<WalletConnected />} />

                      </>
                    )}
                  </Routes>
                  {userData?.isVisited && <BottomNavbar />} {/* Render BottomNavbar only if onboarding is completed */}
                </BrowserRouter>
              </ThemeProvider>
            )}
          </>
        )}
        <Box
          sx={{
            position: 'absolute',
            width: '1000px',
            height: '250px',
            background: '#e59a25',
            borderRadius: '50%',
            transform: 'rotate(-25deg)',
            left: '-80px',
            top: '120px',
            right: "0px",
            filter: 'blur(98.6px)',
            zIndex: '-10',
            '@media (max-width: 450px)': {
              width: '480px',
            },
          }}
        />
      </div>
    </WebAppProvider>
    </TonConnectUIProvider>

  );
}

export default App;