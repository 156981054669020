import React, { useState } from 'react';
import { Box, Typography, IconButton, styled, keyframes, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { BuyUserMultitap, BuyUserEnergyLimit, BuyUserRechargeSpeed } from '../../Apis';
import { setUserData } from '../../store/slices/userSlice';
import ThreeSectionLodder from '../ThreeSectionLodder';
import { useNavigate } from 'react-router-dom';
import { FunctionCommas } from '../../utils/commasFun';
import { setNotEnoughPoint } from '../../store/slices/notEnoughPointSlice';
import { setBoosterPopVisible } from "../../store/slices/userSlice";

import connectWallet1 from "../../images/connectWallet1.svg"
import connectWallet2 from "../../images/ConnectWallet2.png"
import DownArrow from '../../images/rightArrow.png'
import balloons from '../../images/Balloons.svg'


function ConnectToWalletPopup({ onClose }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
    const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
    const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
    return (

        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                background: '#802020FA',
                height: '392px',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                animation: `${slideUp} 200ms ease-out forwards`,
                padding: "23px",
                borderTopRightRadius:"25px",
                borderTopLeftRadius:"25px"

            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    color: '#fff',

                }}
            >
                <CloseIcon />
            </IconButton>

            <Box
                sx={{
                    width: '215px',
                    marginLeft: '35px',
                    marginRight: '35px',
                    height: '142px',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: '50px',
                }}
            >
                    <img
                        src={balloons} // replace with your image source
                        alt="First Box Image"
                        style={{ width: '100%', height: '100%' }}
                    />
               
            </Box>

            {/* heading box */}

            <Box
                sx={{
                    width: 'calc(100% - 70px)',
                    marginLeft: '35px',
                    marginRight: '35px',
                    height: '64px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '50px',
                    color: "white"
                }}
            >
                {/* First Heading: Connect to Wallet */}
                <Typography
                    variant="h1"
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '28.5px',
                        fontWeight: '800',
                        lineHeight: '34.2px',
                        letterSpacing: '-0.02em',
                        textAlign: 'center',
                    }}
                >
                    Congratulations
                </Typography>

                {/* Second Heading: Your wallet address */}
                <Typography
                    variant="body1"
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '12.47px',
                        fontWeight: '400',
                        lineHeight: '15.09px',
                        textAlign: 'center',
                        marginTop: '10px', // Adds space between the two headings
                    }}
                >
                    Your TON Wallet successfully connected to Sugar Boy Bot
                </Typography>
            </Box>

            {/* Buttons box */}

            <Box
                sx={{
                    width: 'calc(100% - 30px)',
                    marginLeft: '15px',
                    marginRight: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                    color: 'white',
                }}
            >
                {/* Button */}
                <Box
                    component="button"
                    sx={{
                        width: '350px',
                        height: '55px',
                        display: 'flex', // Enables flexbox layout
                        alignItems: 'center', // Centers text vertically
                        justifyContent: 'center', // Centers text horizontally
                        borderRadius: '12px',
                        border: '1px solid transparent', // Transparent border to allow background-clip
                        backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                        backgroundOrigin: 'border-box', // Background starts from the border
                        backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                        color: 'white',
                        cursor: 'pointer',
                        fontFamily: 'Inter', // Sets font family
                        fontSize: '18px', // Sets font size
                        fontWeight: '873', // Sets font weight
                        lineHeight: '23.4px', // Sets line height
                        letterSpacing: '-0.01em', // Adjusts letter spacing
                        textAlign: 'center', // Ensures text is centered
                        fontVariationSettings: "'slnt' 0", // Sets font variation
                        marginTop: '20px', // Adds space between input and button
                    }}

                    onClick={() =>{console.log("clicked"); navigate('/earn')}}
                >
                    Go Back
                </Box>

            </Box>







            {/* <ThreeSectionLodder/> */}
        </Box>
    );
}

export default ConnectToWalletPopup;
