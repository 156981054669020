
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Checkbox,
  CircularProgress,
  Button,
  Badge
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  claimKlinkRegistrationPointInfo,
  getKlinkRegistrationInfo,
  getUserTask,
  userCompleteTask,
} from "../Apis";
import CheckIcon from "@mui/icons-material/Check";
import klinkCoin from "../images/KlinkCoin.svg";
import Arrowright from "../images/Arrowright.svg";
import KlinkIcon from "../images/KlinkIcon.svg";
import Coin_ from "../images/Klink-Coin.svg";
import YoutubeKF from "../images/YoutubeKF.svg";
import YoutubeIMG from "../images/YoutubeIMG.svg";
import ArrowRight from "../images/Arrowright.svg";
import { useNavigate } from "react-router-dom";
import bronze from "../images/BronzeSmall.svg";
import silver from "../images/SilverIcon.svg";
import gold from "../images/Gold.svg";
import Platinum from "../images/Platinum-smallIMG.svg";
import diamond from "../images/Diamand-smallImg.svg";
import UserRegisterStatus from "../components/UserRegisterStatus";
import TGCommunity from "../images/TG-Community.svg";
import TGChannel from "../images/TG-channel.svg";
import X from "../images/X.svg";
import Discord from "../images/Discord.svg";
import SuccessFailPopUp from "../components/SuccessFailPopUp";
import CountUp from "react-countup";
import { isIOS, isAndroid } from 'react-device-detect';
import Confetti from 'react-confetti'
import Lottie from "react-lottie";
// import ConfettiCentered from '../ConfettiJson/confetti centered.json'
//import ConfettiVertical from '../ConfettiJson/confetti vertical.json'
import { useSwipeable } from 'react-swipeable';
import TonIcon from "../images/TonIcon.svg"
import tonCoin from "../images/SugarboyCoin.svg"
import star_background from "../images/Star_background.svg"


const fromLeft = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    transform: translateX(2%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fromTop = keyframes`
  0% {
    transform: translateY(-15%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const boxFromRight = keyframes`
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
`;

const Earn = ({ open, handleClose, task }) => {
  const [completedTasks, setCompletedTasks] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user.userData);
  const userTask = useSelector((state) => state.user.userTask);
  const currentPoint = useSelector((state) => state?.point?.currentPoint);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const [claim, setClaim] = useState(false);
  const [taskKlinkId, setTaskKlinkId] = useState("");
  const [taskKlink, setKlinkTask] = useState({});
  const [claimLoading, setClaimLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [resultPopUp, setResultPopUp] = useState(false);
  const [initialUserPoint, setInitialUserPoint] = useState(currentPoint);
  const [taskIds, setTaskIds] = useState([]);
  const [subtext1, setSubtext1] = useState("");
  const [checkedTasks, setCheckedTasks] = useState({});
  const currentPoints = useSelector((state) => state.point.currentPoint);
  const [category, setCategory] = useState('socials');
  const [isActive1, setIsActive1] = useState(true) // first one always active
  const [isActive2, setIsActive2] = useState(false) // inactive first secondone
  const [isActive3, setIsActive3] = useState(false) // inactive third one

  const socialsRef = useRef(null); // it use for scrolling text of task
  const SocialtaskRef = useRef(null);
  const PartnerRef = useRef(null)
  const [showConfetti, setShowConfetti] = useState(false);
  const [showLottie, setShowLottie] = useState(false);
  // State to track loader visibility for URL handling
  const [urlLoader, setUrlLoader] = useState({});

  // State to track loader visibility for task completion
  const [taskLoader, setTaskLoader] = useState({});



  const categories = ['socials', 'Socialtask', 'Partner'];

  const handleSwipe = (direction) => {
    const currentIndex = categories.indexOf(category); // current category index
    let newIndex = currentIndex;

    if (direction === 'LEFT') {
      newIndex = currentIndex + 1 < categories.length ? currentIndex + 1 : currentIndex;
    } else if (direction === 'RIGHT') {
      newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : currentIndex;
    }

    if (newIndex !== currentIndex) {
      const newCategory = categories[newIndex];
      switch (newCategory) {
        case 'socials':
          handleCategoryClick('socials', socialsRef);
          socialsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          break;
        case 'Socialtask':
          handleCategoryClick('Socialtask', SocialtaskRef);
          SocialtaskRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          break;
        case 'Partner':
          handleCategoryClick('Partner', PartnerRef);
          PartnerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          break;
        default:
          break;
      }
    }
  };


  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('LEFT'),
    onSwipedRight: () => handleSwipe('RIGHT'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Optional: to support mouse swiping
  });



  const defaultOptions = {
    loop: false,  // Set to true if you want the animation to repeat
    autoplay: true,
    // // animationData: ConfettiCentered,
    // animationData: ConfettiVertical,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  };


  useEffect(() => {
    if (success && resultPopUp) {
      // Trigger Lottie animation on success
      setShowLottie(true);

      // Optionally stop the animation after it's played
      const timer = setTimeout(() => {
        setShowLottie(false); // Stops the animation after a delay
      }, 7000); // Adjust this delay according to the animation duration

      return () => clearTimeout(timer); // Cleanup timer on unmount or success change
    }
  }, [success, resultPopUp]);

  useEffect(() => {
    if (!resultPopUp) {
      // Reset Lottie animation when the popup closes
      setShowLottie(false);
    }
  }, [resultPopUp]);

  // useEffect(() => {
  //   if (success && resultPopUp) {
  //     setShowConfetti(true);
  //     const timer = setTimeout(() => {
  //       setShowConfetti(false);
  //     }, 5000); // Confetti lasts for 3000 milliseconds (3 seconds)

  //     return () => clearTimeout(timer); // Cleanup timer on component unmount
  //   }
  // }, [success, resultPopUp]);


  const handleClosePopup = () => {
    setResultPopUp(false);
    setShowConfetti(false); // Reset confetti when popup is closed
  };


  const isSmallScreen = useMediaQuery("(max-width: 430px)");

  useEffect(() => {
    dispatch(
      getKlinkRegistrationInfo(
        userId,
        setClaim,
        setTaskKlinkId,
        setKlinkTask,
        accessToken,
        setClaimLoading
      )
    );
  }, [userId, dispatch, accessToken]);

  const useLocalStorageArray = (key, initialValue = []) => {
    const [value, setValue] = useState(() => {
      const savedValue = localStorage.getItem(key);
      return savedValue ? JSON.parse(savedValue) : initialValue;
    });

    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [value, setValue];
  };


  //task id chnage for box issue 
  // const [isUrlOpenedSocials, setIsUrlOpenedSocials] = useState(() => {
  //   const savedSocials = localStorage.getItem('isUrlOpenedSocials');
  //   return savedSocials ? JSON.parse(savedSocials) : [];
  // });

  // const [isUrlOpenedSocialTasks, setIsUrlOpenedSocialTasks] = useLocalStorageArray('isUrlOpenedSocialTasks');
  // const [isUrlOpenedPartnerTasks, setIsUrlOpenedPartnerTasks] = useLocalStorageArray('isUrlOpenedPartnerTasks');


  // const [isUrlOpenedVideos, setIsUrlOpenedVideos] = useState(() => {
  //   const savedVideos = localStorage.getItem('isUrlOpenedVideos');
  //   return savedVideos ? JSON.parse(savedVideos) : [];
  // });

  // useEffect(() => {
  //   localStorage.setItem('isUrlOpenedSocials', JSON.stringify(isUrlOpenedSocials));
  // }, [isUrlOpenedSocials]);

  // useEffect(() => {
  //   localStorage.setItem('isUrlOpenedVideos', JSON.stringify(isUrlOpenedVideos));
  // }, [isUrlOpenedVideos]);

  const [isUrlOpenedSocials, setIsUrlOpenedSocials] = useLocalStorageArray('isUrlOpenedSocials');
  const [isUrlOpenedSocialTasks, setIsUrlOpenedSocialTasks] = useLocalStorageArray('isUrlOpenedSocialTasks');
  const [isUrlOpenedPartnerTasks, setIsUrlOpenedPartnerTasks] = useLocalStorageArray('isUrlOpenedPartnerTasks');
  const [isUrlOpenedVideos, setIsUrlOpenedVideos] = useLocalStorageArray('isUrlOpenedVideos');



  // const handleClaimButtonClick = (index, task, category) => {
  //   const isUrlOpened = category === "socials" ? isUrlOpenedSocials : isUrlOpenedVideos;
  //   const setIsUrlOpened = category === "socials" ? setIsUrlOpenedSocials : setIsUrlOpenedVideos;

  //   if (!isUrlOpened[index]) {
  //     if (task.socialUrl) {
  //       if (window.Telegram && window.Telegram.WebApp) {
  //         window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });

  //       } else {
  //         window.open(task.socialUrl, "_blank", "noopener,noreferrer");
  //       }
  //     }

  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = true;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   } else {
  //     setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
  //     completeTask(task._id, task.taskPoints);

  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = false;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   }
  // };


  const handleClaimButtonClick = (taskId, task, category) => {
    let isUrlOpened, setIsUrlOpened;

    switch (category) {
      case 'socials':
        isUrlOpened = isUrlOpenedSocials;
        setIsUrlOpened = setIsUrlOpenedSocials;
        break;
      case 'videos':
        isUrlOpened = isUrlOpenedVideos;
        setIsUrlOpened = setIsUrlOpenedVideos;
        break;
      case 'Socialtask':
        isUrlOpened = isUrlOpenedSocialTasks;
        setIsUrlOpened = setIsUrlOpenedSocialTasks;
        break;
      case 'Partner':
        isUrlOpened = isUrlOpenedPartnerTasks;
        setIsUrlOpened = setIsUrlOpenedPartnerTasks;
        break;
      default:
        return;
    }

    if (task?.isStoryTask && !isUrlOpened[taskId]) {
      console.log("Task details: ", task);

      if (window.Telegram && window.Telegram.WebApp) {
        const mediaUrl = task.socialUrl;
        const storyParams = {
          text: task.storyCaption || "I just posted story from @Sugar BOY and earned 10,000 points",
          // widget_link: task.storyWidgetLink ? {
          //   url: task.storyWidgetLink.url,
          //   name: task.storyWidgetLink.name || 'Visit Bot' // Optional name for the widget link
          // } : undefined
        };
        try {
          // Attempt to share story
          const result = window.Telegram.WebApp.shareToStory(mediaUrl, storyParams);
          setTimeout(() => {
            setShowLoader((prevState) => ({ ...prevState, [taskId]: false }));
            const updatedIsUrlOpened = { ...isUrlOpened, [taskId]: true };
            setIsUrlOpened(updatedIsUrlOpened);
          }, 5000);
          console.log("Result", result);
          if (result) {
            console.log('Story shared successfully');
          } else {
            console.log('shareToStory method is not supported');
          }
        } catch (error) {
          console.error('Exception while trying to share story:', error);
        }
      }
    } else {

      // if (!isUrlOpened[index]) {
      //   if (task.socialUrl) {
      //     if (task.isInApp) {
      //       window.location.href = task.socialUrl;
      //     } else if (window.Telegram && window.Telegram.WebApp) {
      //       window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });
      //     } else {
      //       window.open(task.socialUrl, "_blank", "noopener,noreferrer");
      //     }
      //   }
      //   setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
      //   const updatedIsUrlOpened = [...isUrlOpened];
      //   updatedIsUrlOpened[index] = true;
      //   setIsUrlOpened(updatedIsUrlOpened);

      // } else {
      //   setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
      //   completeTask(task._id, task.taskPoints);

      //   const updatedIsUrlOpened = [...isUrlOpened];
      //   updatedIsUrlOpened[index] = false;
      //   setIsUrlOpened(updatedIsUrlOpened);
      // }
      // if (!isUrlOpened[taskId]) {
      //   if (task.socialUrl) {

      //     if (task.isInApp) {
      //       window.location.href = task.socialUrl;
      //     } else if (window.Telegram && window.Telegram.WebApp) {
      //       window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });
      //     } else {
      //       window.open(task.socialUrl, "_blank", "noopener,noreferrer");
      //     }

      //   }

      //   // Show URL loading indicator
      //   setUrlLoader((prevState) => ({ ...prevState, [task._id]: true }));

      //   // Delay updating URL opened state and showing the claim button
      //   setTimeout(() => {
      //     const updatedIsUrlOpened = { ...isUrlOpened, [taskId]: true };
      //     setIsUrlOpened(updatedIsUrlOpened);

      //     // Hide URL loading indicator after 5 seconds
      //     setUrlLoader((prevState) => ({ ...prevState, [task._id]: true }));
      //   }, 5000); // 5000 milliseconds = 5 seconds

      // } else {
      //   // Show task completion loading indicator
      //   setTaskLoader((prevState) => ({ ...prevState, [task._id]: true }));
      //   completeTask(task._id, task.taskPoints);

      //   const updatedIsUrlOpened = { ...isUrlOpened, [taskId]: false };
      //   setIsUrlOpened(updatedIsUrlOpened);

      //   // Hide task completion loading indicator
      //   setTaskLoader((prevState) => ({ ...prevState, [task._id]: false }));
      // }

      if (!isUrlOpened[taskId]) {
        setShowLoader(true); // Show loader on Start button click
        setSelectedTaskId(taskId);

        if (task.socialUrl) {
          if (task.isInApp) {
            window.location.href = task.socialUrl;
          } else if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });
          } else {
            window.open(task.socialUrl, '_blank', 'noopener,noreferrer');
          }
        }

        // Show URL loading indicator
        setTimeout(() => {
          const updatedIsUrlOpened = { ...isUrlOpened, [taskId]: true };
          setIsUrlOpened(updatedIsUrlOpened);
          setShowLoader(false); // Hide loader when URL opens
          setIsUrlOpenedSocials((prevState) => ({ ...prevState, [task._id]: true }));
        }, 5000); // Simulate 5 seconds delay

      } else {
        setShowLoader(true); // Show loader on Claim button click
        setTimeout(() => {
          completeTask(task._id, task.taskPoints); // Simulate task completion
          const updatedIsUrlOpened = { ...isUrlOpened, [taskId]: false };
          setIsUrlOpened(updatedIsUrlOpened);
          setShowLoader(false); // Hide loader when task is completed
        }, 3000); // Simulate task completion time (3 seconds)
      }
    }
  };


  const handleRedirectClick = (taskId, task, category) => {
    // const isUrlOpened = category === "socials" ? isUrlOpenedSocials : isUrlOpenedVideos;
    let isUrlOpened;
    switch (category) {
      case 'socials':
        isUrlOpened = isUrlOpenedSocials;
        break;
      case 'videos':
        isUrlOpened = isUrlOpenedVideos;
        break;
      case 'Socialtask':
        isUrlOpened = isUrlOpenedSocialTasks;
        break;
      case 'Partner':
        isUrlOpened = isUrlOpenedPartnerTasks;
        break;
      default:
        return;
    }
    // if (!isUrlOpened[index] && !task.isInApp) {

    //   if (task.socialUrl && !task.isStoryTask) {
    //     if (window.Telegram && window.Telegram.WebApp) {
    //       window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });

    //     } else {
    //       window.open(task.socialUrl, "_blank", "noopener,noreferrer");
    //     }
    //   }
    // }
    if (!isUrlOpened[taskId] && !task.isStoryTask && task.socialUrl) {
      // Redirect based on whether the task is in-app
      if (task.isInApp) {
        window.location.href = task.socialUrl
      } else if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(task.socialUrl, { try_instant_view: false });
      } else {
        window.open(task.socialUrl, "_blank", "noopener,noreferrer");
      }
    }
  };


  const completeTask = async (id, taskPoints) => {

    setSelectedTaskId(id);
    dispatch(
      userCompleteTask(
        id,
        setCompletedTasks,
        userId,
        setShowLoader,
        accessToken,
        taskPoints,
        setResultPopUp,
        setSuccess,
        setSubtext1,
        currentPoints,
        userData.league
      )
    );
  };

  const handelRedirectToRegister = () => {
    try {
      navigate("/register");
    } catch (error) {
      console.error("Navigation error:", error);
    }
  };

  const handelRedirect = () => {
    navigate("/user-level-details");
  };

  const getPendingTasksCount = (category) => {
    return userTask?.filter((task) => task.category === category && !task.completed).length || 0;
  };


  const handleCategoryClick = (newCategory, ref) => {

    setCategory(newCategory);

    if (ref.current) {
      const container = ref.current.parentElement; // Assuming the ref element's parent is the scrollable container
      const containerWidth = container.clientWidth;
      const elementWidth = ref.current.clientWidth;
      const elementOffsetLeft = ref.current.offsetLeft;

      // Calculate the position to scroll to center the element
      const scrollToPosition = elementOffsetLeft - (containerWidth / 2) + (elementWidth / 2);

      // Smooth scroll to the target position
      container.scrollTo({
        left: scrollToPosition,
        behavior: 'smooth',
      });


    }
  };




  return (
    <>
      {/* <UserRegisterStatus /> */}
      <Box style={{
        height: "88vh",
        //  marginLeft: "18px",
        //  marginRight: "20px", 
        position: "absolute"
      }}>
        <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%" }} alt="Star Background" />
        <Box

          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            position:"fixed",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: "20px",

          }}
        >
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            flexDirection={"column"}
          // marginTop={"29px"}
          >
            <Box
              {...swipeHandlers}
              sx={{
                height: "80vh",
                position: "relative",
                overflowY: "auto",
                paddingTop: "40px",
                zIndex: 3000,
                '@media (min-width: 450px)': {
                  width: "100%",

                },
                // No width specified for screens smaller than 450px
              }}
            >

              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginLeft: "22px", marginRight: "21px", }}>


                <Box sx={{ width: "100%", marginBottom: "15px", }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      color: "#FFFFFF",
                      fontFamily: "Inter",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "23.4px",
                      paddingLeft: "18px",
                    }}
                  >
                    Add TON Wallet Address
                  </Typography>
                </Box>

                <Box
                  bgcolor="#AA4545"
                  color="white"
                  sx={{
                    textAlign: "left",
                    // width: isSmallScreen ? "350px" : "96%",
                    width: "100%",
                    // width: "350px",
                    height: "60px",
                    borderRadius: "12px",
                    // border: "1px solid red",
                    border: '1px solid transparent', // Transparent border to allow background-clip
                    backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                    backgroundOrigin: 'border-box', // Background starts from the border
                    backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                    // marginBottom: "39px",
                    pointerEvents:
                      userData?.isUserVerified && claim ? "none" : "auto",

                    // opacity: userData?.isUserVerified && claim ? 0.7 : 1,
                  }}
                  onClick={
                    !userData?.isUserVerified || !claim
                      ? handelRedirectToRegister
                      : undefined
                  }
                >
                  <img
                    src={TonIcon}
                    width={40}
                    height={40}
                    alt="Klink Icon"
                    style={{
                      marginRight: "10px",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      flexGrow: 1,
                      cursor:
                        userData?.isUserVerified && claim
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18.2px",
                        fontFamily: "Inter",
                      }}
                    >
                      Connect TON Wallet
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "5px",
                      }}
                    >
                      <img src={tonCoin} width={15.26} height={15.26} alt="Coin" />
                      <Typography
                        style={{
                          color: "#FFFFFF",
                          lineHeight: "15.6px",
                          fontWeight: 700,
                          fontFamily: "Inter",
                          fontSize: "12px",
                        }}
                      >
                        50,000
                      </Typography>
                    </Box>
                  </Box>


                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "auto",
                    }}
                  >
                    {userData?.isRegRewClaimed ? (
                      <Box
                        sx={{
                          backgroundColor: "#802020",
                          borderRadius: "50%",
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "12px",
                          position: "relative",
                        }}
                      >
                        <CheckIcon
                          sx={{
                            display: "flex",
                            width: "16px",
                            height: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        {userData?.isUserVerified ? (
                          <>
                            {!claimLoading ? (
                              <>
                                {claim ? (
                                  <CheckIcon
                                    sx={{
                                      alignItems: "center",
                                      background: "#802020",
                                      borderRadius: "100px",
                                      display: "flex",
                                      width: "16px",
                                      height: "10px",
                                      justifyContent: "center",
                                      color: "white",
                                    }}
                                    fontSize="16px"
                                  />
                                ) : (
                                  <button
                                    style={{
                                      color: "#FFFFFF",
                                      backgroundColor: "#AA4545",
                                      // marginLeft: '10px',
                                      border: '1px solid transparent', // Transparent border to allow background-clip
                                      backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                                      backgroundOrigin: 'border-box', // Background starts from the border
                                      backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only

                                      // padding: '8px',
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                      width: "50px",
                                      height: "26px",
                                      fontSize: "12px",
                                      fontWeight: 700,
                                      lineHeight: "15.6px",
                                      marginRight: "15px",
                                      fontFamily: "Inter",
                                    }}
                                    disabled={claim}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      dispatch(
                                        claimKlinkRegistrationPointInfo(
                                          userId,
                                          taskKlinkId,
                                          accessToken,
                                          setClaimLoading,
                                        )
                                      );
                                    }}
                                  >
                                    Claim
                                  </button>
                                )}
                              </>
                            ) : (
                              <Box
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  backgroundColor: "#802020",
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  marginRight: "15px"
                                }}
                              >
                                <CircularProgress
                                  size={12} // The size of the CircularProgress
                                  thickness={4}
                                  sx={{
                                    color: "white",
                                    position: "absolute", // Positioning it absolutely to center within the Box
                                  }}
                                />
                              </Box>
                            )}
                          </>
                        ) : (
                          <button
                            style={{
                              position: 'relative',
                              color: "#FFFFFF", // Text color
                              backgroundColor: "#AA4545", // Button background
                              cursor: "pointer",
                              borderRadius: "5px", // Rounded corners
                              width: "50px",
                              height: "26px",
                              fontSize: "12px",
                              fontWeight: 700,
                              lineHeight: "15.6px",
                              fontFamily: "Inter",
                              marginRight: "15px",
                              background: "#AA4545", // Button background
                              border: '1px solid transparent', // Transparent border to allow background-clip
                              backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                              backgroundOrigin: 'border-box', // Background starts from the border
                              backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                            }}
                          >
                            Start
                          </button>



                        )}
                      </>
                    )}
                  </Box>

                  {/* this is yoytube */}

                </Box>
                {userTask
                  ?.filter((task) => task.category === "videos")
                  .map((task, index) => (
                    <Box
                      key={task._id}
                      bgcolor="#AA4545"
                      color="white"
                      sx={{
                        textAlign: "left",
                        // width: isSmallScreen ? "350px" : "96%",
                        width: "100%",
                        height: "60px",
                        marginTop: "10px",
                        // marginRight: "16px",
                        // marginRight: "20px",
                        // marginLeft: "18px",
                        // padding: "10px",
                        borderRadius: "12px",
                        border: '1px solid transparent', // Transparent border to allow background-clip
                        backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                        backgroundOrigin: 'border-box', // Background starts from the border
                        backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                        display: "flex",
                        justifyContent: "space-between",
                        // marginRight: "20px",  
                        alignItems: "center",
                        animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                      }}
                      onClick={() =>
                        handleRedirectClick(task._id, task, "videos")
                      }
                    >
                      <img
                        src={task.taskLogoUrl}
                        width={40}
                        height={40}
                        alt={task.taskName}
                        style={{
                          marginRight: "10px",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          flexGrow: 1,
                        }}
                      >
                        <Typography
                          style={{
                            color: "#FFFFFF",
                            fontSize: isSmallScreen ? "14px" : "14px",
                            fontWeight: 700,
                            lineHeight: "17.5px",
                            fontFamily: "Inter",
                            letterSpacing: "0px",
                          }}
                        >
                          {task.taskName}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5.37px",
                            marginTop: "5px",
                          }}
                        >
                          <img src={Coin_} width={16} height={16} alt="Coin" />
                          <Typography
                            style={{
                              color: "#FFFFFF",
                              lineHeight: "17.5px",
                              fontWeight: 700,
                              fontFamily: "Inter",
                              fontSize: "12px",
                            }}
                          >
                            {task.taskPoints.toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() =>
                            handleClaimButtonClick(task._id, task, "videos")
                          }
                          disabled={task.completed}
                          sx={{
                            // color: '#FFFFFF',
                            backgroundColor: task.completed ? "transparent"
                              : selectedTaskId == task?._id && showLoader ? 'transparent'
                                : isUrlOpenedVideos[task._id] && !task.completed
                                  ? "#AA4545"
                                  : !task.completed && !isUrlOpenedVideos[task._id]
                                    ? "#AA4545"
                                    : "transparent",
                            minWidth: "0px !important",
                            padding: "0px 10px", // padding applied
                            gap: "0px", // gap applied
                            borderRadius: "5px 0px 0px 0px", // border-radius applied

                            "&:hover": {
                              backgroundColor: task.completed ? "transparent"
                                : selectedTaskId == task?._id && showLoader ? 'transparent'
                                  : isUrlOpenedVideos[task._id] && !task.completed
                                    ? "#AA4545"
                                    : !task.completed && !isUrlOpenedVideos[task._id]
                                      ? "#AA4545"
                                      : "transparent",
                            },

                            color: "#FFFFFF",
                            border: showLoader || task.completed ? "none" : '1px solid transparent',
                            backgroundImage: showLoader || task.completed ? '' : 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                            backgroundOrigin: showLoader || task.completed ? '' : 'border-box',
                            backgroundClip: showLoader || task.completed ? '' : 'padding-box, border-box',
                            cursor: "pointer",
                            borderRadius: "5px", // Ensure border radius is applied
                            width: "50px", // Width adjusted
                            height: "26px", // Height adjusted
                            fontSize: "12px",
                            fontWeight: 700,
                            lineHeight: "15.6px",
                            fontFamily: "Inter",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            textTransform: "none",
                            marginRight: selectedTaskId == task?._id && showLoader || task.completed ? "0px" : "15px",
                          }}
                        >
                          {selectedTaskId == task?._id && showLoader ? (
                            <>
                              <Box
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  border: "none",
                                  outline: "none",
                                  backgroundColor: "#802020",
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative", // To ensure proper alignment of CircularProgress
                                }}
                              >
                                <CircularProgress
                                  size={12} // The size of the CircularProgress
                                  thickness={4}
                                  sx={{
                                    color: "#FFFFFF",
                                    position: "absolute", // Positioning it absolutely to center within the Box
                                  }}
                                />
                              </Box>
                            </>
                          ) : (
                            <>
                              {task.completed ? (
                                <Box
                                  sx={{
                                    backgroundColor: "#802020",
                                    borderRadius: "50%",
                                    width: "24px",
                                    height: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CheckIcon
                                    sx={{
                                      display: "flex",
                                      width: "16px",
                                      height: "10px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white",
                                    }}
                                  />
                                </Box>
                              ) : isUrlOpenedVideos[task._id] ? (
                                "Claim"
                              ) : (
                                "Start"
                              )}
                            </>
                          )}
                        </Button>
                      </Box>
                    </Box>
                  ))}

              </Box>


              <Box {...swipeHandlers} sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center",}}>

                <Box
                  {...swipeHandlers}
                  sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    width: '100%',
                    maxHeight: '100vh',
                    marginTop: '39px',
                    gap: '15px',
                    marginBottom: '16px',
                    position: 'relative',
                    zIndex: 1,
                    alignItems: 'center',
                    marginRight: category === 'Partner' ? '50px' : '0px',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      // left: '50%',
                      bottom: 1,
                      left: 0,
                      width: '110%',
                      // border: '0.5px solid #ffffff',
                      height: '1px',
                      opacity: '10%',
                      backgroundColor: '#ffffff',
                      marginLeft: '32px',
                      zIndex: 0,
                    },
                  }}
                >
                  {/* Join Community Tab */}
                  <Box
                    ref={socialsRef}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      flexShrink: 0,
                      position: 'relative',
                      gap: '5px',
                      marginLeft: category === 'socials' ? '32px' : '15px',
                      transition: 'margin-left 0.3s ease',
                      paddingBottom: '11.5px',
                      marginTop: "auto",
                      zIndex: 2,

                      '&::after': {
                        content: category === 'socials' ? '""' : 'none', // Conditionally show line
                        position: 'absolute',
                        bottom: '0px',
                        left: 0,
                        width: '100%', // Make sure the line spans the full width
                        borderRadius: '3px',
                        height: '3px', // Increase the height of the line
                        color: '#802020',
                        backgroundColor: '#802020', // Purple color for the line
                      },

                    }}
                    onClick={() => {
                      handleCategoryClick('socials', socialsRef);
                      socialsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                    }}
                  >
                    <Badge
                      badgeContent={getPendingTasksCount('socials')}
                      showZero
                      color="error"
                      sx={{
                        marginRight: '5px',
                        '& .MuiBadge-badge': {
                          backgroundColor: '#802020 !important',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        color: category === 'socials' ? '#FFFFFF' : '#A7A7B0 !important',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '23.4px',
                        marginLeft: '5px',
                      }}
                    >
                      Join Community
                    </Typography>
                  </Box>

                  {/* Social Tasks Tab */}
                  <Box
                    ref={SocialtaskRef}
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      flexShrink: 0,
                      position: 'relative',
                      marginLeft: '15px',
                      paddingBottom: '11.5px',
                      zIndex: 2,
                      '&::after': {
                        content: category === 'Socialtask' ? '""' : 'none',
                        position: 'absolute',
                        bottom: '0px',
                        width: '114%',
                        right: '0px',
                        borderRadius: '3px',
                        height: '3px',
                        color: '#802020',
                        backgroundColor: '#802020',
                      },
                    }}
                    onClick={() => {
                      handleCategoryClick('Socialtask', SocialtaskRef);
                      SocialtaskRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                    }}
                  >
                    <Badge
                      badgeContent={getPendingTasksCount('Socialtask')}
                      showZero
                      color="error"
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#802020 !important',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: 'left',
                        color: category === 'Socialtask' ? '#FFFFFF' : '#A7A7B0 !important',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '23.4px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      Social Tasks
                    </Typography>
                  </Box>

                  {/* Partner Tasks Tab */}
                  <Box
                    ref={PartnerRef}
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      flexShrink: 0,
                      position: 'relative',
                      // marginRight: category === 'Partner' ? '20px' : '0px',
                      // marginRight: "0px",
                      paddingBottom: '11.5px',
                      zIndex: 2,
                      '&::after': {
                        content: category === 'Partner' ? '""' : 'none',

                        position: 'absolute',
                        bottom: '0px',
                        left: 0,
                        width: '100%',
                        borderRadius: '3px',
                        height: '3px',
                        color: '#802020',
                        backgroundColor: '#802020',
                      },
                    }}
                    onClick={() => {
                      handleCategoryClick('Partner', PartnerRef);
                      PartnerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'left',
                        color: category === 'Partner' ? '#FFFFFF' : '#A7A7B0 !important',
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '18px',
                        lineHeight: '23.4px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '15px',
                      }}
                    >
                      <Badge
                        badgeContent={getPendingTasksCount('Partner')}
                        showZero
                        color="error"
                        sx={{
                          marginRight: '5px',
                          '& .MuiBadge-badge': {
                            backgroundColor: '#802020 !important',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontWeight: 'bold',
                          },
                        }}
                      />
                      Partners Tasks
                    </Typography>
                  </Box>
                </Box>

                <Box {...swipeHandlers} sx={{ position: 'relative', marginLeft: "21px", width: "calc(100% - 43px)", marginRight: "22px", }}>

                  {category === 'socials' && (
                    <>
                      {userTask
                        ?.filter((task) => task.category === 'socials')
                        .map((task, index) => (
                          <Box
                            key={task._id}
                            bgcolor="#AA4545"
                            color="white"
                            sx={{
                              textAlign: 'left',
                              // width: isSmallScreen ? '350px' : '96%',
                              width: "100%",
                              height: '60px',
                              marginTop: '10px',
                              //  marginRight: '16px', 
                              // marginLeft: '18px',
                              borderRadius: '12px',
                              // border: '1px solid #313130',
                              border: '1px solid transparent', // Transparent border to allow background-clip
                              backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                              backgroundOrigin: 'border-box', // Background starts from the border
                              backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              position: 'relative', // Ensure the container is positioned
                              zIndex: 1, // Set a base z-index to ensure it stays on top
                              animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                            }}
                            onClick={() => handleRedirectClick(task._id, task, 'socials')}
                          >
                            <img
                              src={task.taskLogoUrl}
                              width={40}
                              height={40}
                              alt={task.taskName}
                              style={{
                                marginRight: '10px',
                                padding: '10px',
                                borderRadius: '5px',
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                flexGrow: 1,
                              }}
                            >
                              <Typography
                                style={{
                                  color: '#FFFFFF',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '17.5px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {task.taskName}

                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                  marginTop: '5px',
                                }}
                              >
                                <img src={Coin_} width={16} height={16} alt="Coin" />
                                <Typography
                                  style={{
                                    color: '#FFFFFF',
                                    lineHeight: '17.5px',
                                    fontWeight: 700,
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                  }}
                                >
                                  {task.taskPoints.toLocaleString()}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 2 }}>
                              <Button
                                onClick={() => handleClaimButtonClick(task._id, task, 'socials')}
                                disabled={task.completed}
                                sx={{
                                  backgroundColor:
                                    selectedTaskId === task._id && showLoader
                                      ? 'transparent'
                                      : isUrlOpenedSocials[task._id]
                                        ? '#613B39B2'
                                        : !task.completed && !isUrlOpenedSocials[task._id]
                                          ? '#613B39B2'
                                          : 'transparent',
                                  minWidth: '0px !important',
                                  padding: task.completed
                                    ? '0px !important'
                                    : isUrlOpenedSocials[task._id]
                                      ? '6px 8px !important'
                                      : '0px !important',
                                  '&:hover': {
                                    backgroundColor:
                                      selectedTaskId === task._id && showLoader
                                        ? 'transparent'
                                        : isUrlOpenedSocials[task._id]
                                          ? '#613B39B2'
                                          : !task.completed && !isUrlOpenedSocials[task._id]
                                            ? '#613B39B2'
                                            : 'transparent',
                                  },
                                  color: '#FFFFFF',
                                  // outline: 'none',
                                  // border: 'none',
                                  border: showLoader || task.completed || !isUrlOpenedSocials[task._id] ? "none" : '1px solid transparent',
                                  backgroundImage: showLoader || task.completed || !isUrlOpenedSocials[task._id]? '' : 'linear-gradient(#613B39B2, #613B39B2), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                  backgroundOrigin: showLoader || task.completed || !isUrlOpenedSocials[task._id] ? '' : 'border-box',
                                  backgroundClip: showLoader || task.completed || !isUrlOpenedSocials[task._id] ?  '' : 'padding-box, border-box',
                                  cursor: 'pointer',
                                  borderRadius: '5px',
                                  width: '50px !important',
                                  height: '26px',
                                  fontSize: '12px',
                                  fontWeight: 700,
                                  lineHeight: '15.6px',
                                  fontFamily: 'Inter',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textTransform: 'none',
                                  zIndex: 3, // Ensure this is higher than surrounding elements
                                  // position: 'relative', // Ensure it respects its parent's stacking context
                                  marginRight:
                                    selectedTaskId === task._id && showLoader || task.completed
                                      ? '0px'
                                      : '15px',
                                }}
                              >
                                {selectedTaskId === task._id && showLoader ? (
                                  <Box
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      backgroundColor: '#802020',
                                      borderRadius: '50%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'relative',
                                    }}
                                  >
                                    <CircularProgress
                                      size={12}
                                      thickness={4}
                                      sx={{
                                        color: '#FFFFFF',
                                        position: 'absolute',
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    {task.completed ? (
                                      <Box
                                        sx={{
                                          backgroundColor: '#802020',
                                          borderRadius: '50%',
                                          width: '24px',
                                          height: '24px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <CheckIcon
                                          sx={{
                                            display: 'flex',
                                            width: '12px',
                                            height: '12px',
                                            color: '#FFFFFF',
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          fontSize: '12px',
                                          fontWeight: 700,
                                          lineHeight: '15.6px',
                                          fontFamily: 'Inter',
                                        }}
                                      >
                                        {isUrlOpenedSocials[task._id] ? 'Claim' : 'Start'}
                                      </Typography>
                                    )}
                                  </>
                                )}
                              </Button>
                            </Box>
                          </Box>
                        ))}
                    </>
                  )}

                  {category === 'Socialtask' && (
                    userTask?.filter((task) => task.category === 'Socialtask').map((task, index) => (
                      <Box
                        key={task._id}
                        bgcolor="#AA4545"
                        color="white"
                        sx={{
                          textAlign: 'left',
                          width: "100%",
                          // width: isSmallScreen ? "350px" : "96%",
                          // marginRight: "16px",
                          // width: "350px",
                          height: '60px',
                          marginTop: '10px',
                          // margin: "10px",
                          // marginRight: "20px",
                          // marginLeft: "18px",
                          // marginLeft: "18px",
                          borderRadius: '12px',
                          // border: '1px solid #313130',
                          border: '1px solid transparent', // Transparent border to allow background-clip
                          backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                          backgroundOrigin: 'border-box', // Background starts from the border
                          backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                        }}
                        onClick={() => handleRedirectClick(task._id, task, 'Socialtask')}
                      >
                        <img
                          src={task.taskLogoUrl}
                          width={40}
                          height={40}
                          alt={task.taskName}
                          style={{
                            marginRight: '10px',
                            padding: '10px',
                            borderRadius: '5px',
                          }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
                          <Typography sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, lineHeight: '17.5px', fontFamily: 'Inter' }}>
                            {task.taskName}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '5px' }}>
                            <img src={Coin_} width={16} height={16} alt="Coin" />
                            <Typography sx={{ color: '#FFFFFF', lineHeight: '17.5px', fontWeight: 700, fontFamily: 'Inter', fontSize: '12px' }}>
                              {task.taskPoints.toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            onClick={() => handleClaimButtonClick(task._id, task, 'Socialtask')}
                            disabled={task.completed}
                            sx={{
                              backgroundColor: task.completed ? "transparent"
                                : selectedTaskId == task?._id && showLoader ? 'transparent'
                                  : isUrlOpenedSocialTasks[task._id] && !task.completed
                                    ? '#613B39B2'
                                    : !task.completed && !isUrlOpenedSocialTasks[task._id]
                                      ? '#613B39B2'
                                      : 'transparent',
                              minWidth: '0px !important',
                              padding: task.completed
                                ? '0px !important'
                                : isUrlOpenedSocialTasks[task._id]
                                  ? '6px 8px !important'
                                  : '0px !important',
                              '&:hover': {
                                backgroundColor: task.completed ? "transparent"
                                  : selectedTaskId == task?._id && showLoader ? 'transparent'
                                    : isUrlOpenedSocialTasks[task._id] && !task.completed
                                      ? '#613B39B2'
                                      : !task.completed && !isUrlOpenedSocialTasks[task._id]
                                        ? '#613B39B2'
                                        : 'transparent',
                              },
                              color: '#FFFFFF',
                              // outline: 'none',
                              // border: 'none',
                              border: showLoader || task.completed || !isUrlOpenedSocialTasks[task._id] ? "none" : '1px solid transparent',
                              backgroundImage: showLoader || task.completed || !isUrlOpenedSocialTasks[task._id]? '' : 'linear-gradient(#613B39B2, #613B39B2), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                              backgroundOrigin: showLoader || task.completed || !isUrlOpenedSocialTasks[task._id] ? '' : 'border-box',
                              backgroundClip: showLoader || task.completed || !isUrlOpenedSocialTasks[task._id] ?  '' : 'padding-box, border-box',
                              cursor: 'pointer',
                              borderRadius: '5px',
                              width: '50px !important',
                              height: '26px',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '15.6px',
                              fontFamily: 'Inter',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textTransform: 'none',
                              marginRight:
                                selectedTaskId == task?._id && showLoader || task.completed
                                  ? '0px'
                                  : '15px',
                            }}
                          >
                            {selectedTaskId == task?._id && showLoader ? (
                              <Box sx={{ width: '24px', height: '24px', backgroundColor: '#802020', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                <CircularProgress size={12} thickness={4} sx={{ color: '#FFFFFF', position: 'absolute' }} />
                              </Box>
                            ) : (
                              <>
                                {task.completed ? (
                                  <Box sx={{ backgroundColor: '#802020', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CheckIcon sx={{ width: '12px', height: '12px', color: '#FFFFFF' }} />
                                  </Box>
                                ) : (
                                  <Typography sx={{ fontSize: '12px', fontWeight: 700, lineHeight: '15.6px', fontFamily: 'Inter' }}>
                                    {isUrlOpenedSocialTasks[task._id] ? 'Claim' : 'Start'}
                                  </Typography>
                                )}
                              </>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    ))
                  )}


                  {category === 'Partner' && (
                    userTask?.filter((task) => task.category === 'Partner').map((task, index) => (
                      <Box
                        key={task._id}
                        bgcolor="#AA4545"
                        color="white"
                    

                        sx={{
                          textAlign: 'left',
                          width: "100%",
                          height: '60px',
                          marginTop: '10px',
                          borderRadius: '12px',
                          // border: '1px solid #313130',
                          border: '1px solid transparent', // Transparent border to allow background-clip
                          backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                          backgroundOrigin: 'border-box', // Background starts from the border
                          backgroundClip: 'padding-box, border-box', // Ensures the gradient shows on the border only
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                        }}
                        onClick={() => handleRedirectClick(task._id, task, 'Partner')}
                      >
                        <img
                          src={task.taskLogoUrl}
                          width={40}
                          height={40}
                          alt={task.taskName}
                          style={{
                            marginRight: '10px',
                            padding: '10px',
                            borderRadius: '5px',
                          }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
                          <Typography sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 700, lineHeight: '17.5px', fontFamily: 'Inter' }}>
                            {task.taskName}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '5px' }}>
                            <img src={Coin_} width={16} height={16} alt="Coin" />
                            <Typography sx={{ color: '#FFFFFF', lineHeight: '17.5px', fontWeight: 700, fontFamily: 'Inter', fontSize: '12px' }}>
                              {task.taskPoints.toLocaleString()}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            onClick={() => handleClaimButtonClick(task._id, task, 'Partner')}
                            disabled={task.completed}
                            sx={{
                              backgroundColor: task.completed ? "transparent"
                                : selectedTaskId == task?._id && showLoader ? 'transparent'
                                  : isUrlOpenedPartnerTasks[task._id] && !task.completed
                                    ? '#613B39B2'
                                    : !task.completed && !isUrlOpenedPartnerTasks[task._id]
                                      ? '#613B39B2'
                                      : 'transparent',

                              minWidth: '0px !important',
                              padding: task.completed
                                ? '0px !important'
                                : isUrlOpenedPartnerTasks[task._id] && !task.completed
                                  ? '6px 8px !important'
                                  : '0px !important',
                              '&:hover': {
                                backgroundColor: task.completed ? "transparent"
                                  : selectedTaskId == task?._id && showLoader ? 'transparent'
                                    : isUrlOpenedPartnerTasks[task._id] && !task.completed
                                      ? '#613B39B2'
                                      : !task.completed && !isUrlOpenedPartnerTasks[task._id]
                                        ? '#613B39B2'
                                        : 'transparent',
                              },
                              color: '#FFFFFF',
                              // outline: 'none',
                              // border: 'none',
                              border: showLoader || task.completed || !isUrlOpenedPartnerTasks[task._id] ? "none" : '1px solid transparent',
                              backgroundImage: showLoader || task.completed || !isUrlOpenedPartnerTasks[task._id]? '' : 'linear-gradient(#613B39B2, #613B39B2), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                              backgroundOrigin: showLoader || task.completed || !isUrlOpenedPartnerTasks[task._id] ? '' : 'border-box',
                              backgroundClip: showLoader || task.completed || !isUrlOpenedPartnerTasks[task._id] ?  '' : 'padding-box, border-box',
                              cursor: 'pointer',
                              borderRadius: '5px',
                              width: '50px !important',
                              height: '26px',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '15.6px',
                              fontFamily: 'Inter',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textTransform: 'none',
                              marginRight:
                                selectedTaskId == task?._id && showLoader || task.completed
                                  ? '0px'
                                  : '15px',
                            }}
                          >
                            {selectedTaskId == task?._id && showLoader ? (
                              <Box sx={{ width: '24px', height: '24px', backgroundColor: '#802020', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                <CircularProgress size={12} thickness={4} sx={{ color: '#FFFFFF', position: 'absolute' }} />
                              </Box>
                            ) : (
                              <>
                                {task.completed ? (
                                  <Box sx={{ backgroundColor: '#802020', borderRadius: '50%', width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CheckIcon sx={{ width: '12px', height: '12px', color: '#FFFFFF' }} />
                                  </Box>
                                ) : (
                                  <Typography sx={{ fontSize: '12px', fontWeight: 700, lineHeight: '15.6px', fontFamily: 'Inter' }}>
                                    {isUrlOpenedPartnerTasks[task._id] ? 'Claim' : 'Start'}
                                  </Typography>
                                )}
                              </>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* {resultPopUp && (
        <SuccessFailPopUp
          navigate={navigate}
          setResultPopUp={setResultPopUp}
          success={success}
          subtext1={subtext1}
        />
      )}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          style={{ zIndex: 9999 }} // Set a high zIndex to ensure it displays above other elements
        />
      )} */}
      {/* <div >
        {showConfetti && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2000, pointerEvents: "none"  }}>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
            />
          </div>
        )}
 
        {success && resultPopUp && (
          <SuccessFailPopUp
            navigate={navigate}
           
            setResultPopUp={handleClosePopup}
            success={success}
            subtext1={subtext1}
          />
        )}
      </div> */}
      <div>
        {/* Lottie Animation for success */}
        {showLottie && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 10005,
              pointerEvents: 'none',
            }}
          >
            <Lottie
              options={defaultOptions}
              height={window.innerHeight}
              width={window.innerWidth}
              isStopped={!showLottie} // Stop the animation when showLottie is false
              speed={2}
            />
          </div>
        )}

        {/* SuccessFailPopUp component */}
        {success && resultPopUp && (
          <SuccessFailPopUp
            navigate={navigate}
            setResultPopUp={handleClosePopup}
            success={success}
            subtext1={subtext1}
          />
        )}
      </div>

    </>
  );
};

export default Earn;



