import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from 'react-router-dom';
import BoosterPopup from "../Modals/Booster/Booster";
import booster from '../../images/BoosterTapPoint.svg';
import { useDispatch,useSelector } from 'react-redux';
import TapPointImg from "../../images/TapPointPopupMain.svg"
import {setBosterPopUp} from "../../store/slices/userSlice";
const TapPointsPopup = ({ setOpenTapPointsPopup }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [opnePopup1, setOpenPopup1] = useState(false);
    const [opnePopup2, setOpenPopup2] = useState(false);

    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;
    
    return (
        <>
  
{opnePopup1 && (
                <BoosterPopup setOpenPopup1={setOpenPopup1} />
            )}

            {/* Background overlay with blur */}
            {/* <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.5)",// Semi-transparent background
                    boxShadow: "0px 0px 70px rgba(149, 131, 255, 0.2)",
                    backdropFilter: "blur(0.2px)",     // Blur effect
                    zIndex: 10000002                 // Ensure it stays behind the popup
                }}
                onClick={() => setOpenTapPointsPopup(false)} // Close popup when clicking outside
            /> */}

            {/* Popup */}
        </>
    );
};

export default TapPointsPopup;