import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, keyframes, Typography } from "@mui/material";
import LevelsProgressBar from "../components/LevelsProgressBar";
import Arrowright from "../images/Arrowright.svg";
// import bronze from "../images/Bronze.svg";
// import silver from "../images/Silver.svg";
// import Gold from '../images/GoldLeague.svg'
// import Platinum from '../images/PlatinumLeuages.svg'
// import Diamond  from '../images/DiamandLeaugeIMG.svg'
import star_background from "../images/Star.svg"
import { setLevelsData } from "../Apis";
import { FunctionCommas } from "../utils/commasFun";
import KlinkToken from '../images/Klink-Coin.svg'
import bronzeIMG from "../images/bronzeIMG.svg"
import silverIMG from "../images/silverIMG.png"
import goldIMG from "../images/goldIMG.png"
import platineum from "../images/platinuumIMG.svg"
import diamondIMG from "../images/dimondIMG.svg"

const Levels = () => {
  const userLeague = useSelector((state) => state?.user?.userData?.league);
  const userId = useSelector((state) => state.user.userId);
  const currentPoint = useSelector((state) => state?.point?.currentPoint);
  const userleaguesData = useSelector((state) => state?.user?.userLevelData);
  const bronze = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/Bronze.svg";
  const silver = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/SilverIcon.svg";
  const Gold = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/GoldLeague.svg";
  const Platinum = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/PlatinumLeague.svg";
  const Diamond = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/DiamondSmall.svg";



  // const userBonusperLevel = useSelector((state) => state?.user?.userLevelData.levelUpBonus)
  const accessToken = useSelector((state) => state?.user?.accessToken)
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData)
  useEffect(() => {
    dispatch(setLevelsData(userId, accessToken));
  }, [dispatch, userId]);

  const data = [
    {
      league: "Bronze",
      currentleague: 1,
      title: "Bronze League",
      subTitle: "Your number of shares determines the league you enter",
      img: bronzeIMG,
      // userBonusperLevel: 1000,
      minShares: currentPoint,
      maxShares: userleaguesData?.Bronze?.leagueTo + 1,
    },
    {
      league: "Silver",
      currentleague: 2,
      title: "Silver League",
      subTitle: "Your number of shares determines the league you enter",
      userBonusperLevel: 10000,
      img: silverIMG,
      minShares: currentPoint,
      maxShares: userleaguesData?.Silver?.leagueTo + 1,
    },
    {
      league: "Gold",
      currentleague: 3,
      title: "Gold League",
      subTitle: "Your number of shares determines the league you enter",
      userBonusperLevel: 25000,
      img: goldIMG,
      minShares: currentPoint,
      maxShares: userleaguesData?.Gold?.leagueTo + 1,
    },
    {
      league: "Platinum",
      currentleague: 4,
      title: "Platinum League",
      subTitle: "Your number of shares determines the league you enter",
      userBonusperLevel: 75000,
      img: platineum,
      minShares: currentPoint,
      maxShares: userleaguesData?.Platinum?.leagueTo + 1,
    },
    {
      league: "Diamond",
      currentleague: 5,
      title: "Diamond League",
      subTitle: "Your number of shares determines the league you enter",
      userBonusperLevel: 200000,
      img: diamondIMG,
      minShares: currentPoint,
      maxShares: userleaguesData?.Diamond?.leagueTo,
    },
    // Add more leagues here if needed
  ];

  const userLevelData = data.find((level) => level.league === userLeague);
  const userCurrentLeague = userLevelData.currentleague;
  const [index, setIndex] = useState(data.indexOf(userLevelData));

  const handleLeftClick = () => {
    if (index >= 1) {
      setIndex((prev) => prev - 1);
    }
  };

  const handleRightClick = () => {
    if (index < data.length - 1) {
      setIndex((prev) => prev + 1);
    }
  };

  const isActiveLeague = data[index]?.league === userLeague;

  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      handleRightClick();
    }

    if (touchEndX - touchStartX > 50) {
      handleLeftClick();
    }
  };
  const scaleUp = keyframes`
  0% {
    transform: scale(0);
}

50% {
    transform: scale(0.8);
}

100%{
 transform: scale(1);
}
`;

  return (
    <Box
      sx={{
        height: "100vh",
        zIndex: 10,
        backgroundColor: "transparent",
        marginTop: "29px"
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "29px",
            gap: "22px",
            paddingRight: "23px",
            paddingLeft: "23px",
          }}
        >
          {/* Level name */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "12px",
              width: "267px",
            }}
          >
            <Typography
              component="p"
              sx={{
                color: "#FFF",
                fontWeight: 800,
                fontSize: "24px",
                lineHeight: "28.8px",
                fontFamily: "Inter",
                textAlign: "center",
              }}
            >
              {data[index]?.title}
            </Typography>
            <Typography
              component="p"
              sx={{
                color: "#FFF",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16.94px",
                fontFamily: "Inter",
                textAlign: "center",
                marginTop: "12px"
              }}
            >
              {data[index]?.subTitle}
            </Typography>
            {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              marginTop: "19px"
            }}
          >
   
              <img src={KlinkToken} width={16} height={16} />


      
            <Typography
              sx={{
                color: "#FFFFFF",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "21.78px",
            
               
              }}
            >
          {FunctionCommas(data[index]?.userBonusperLevel)} Bonus

            </Typography>


          </Box> */}
            {data[index]?.league !== "Bronze" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "19px"
                }}
              >
                <img src={KlinkToken} width={16} height={16} style={{ paddingBottom: "3px" }} />
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: 700,
                    lineHeight: "21.78px",
                  }}
                >
                  {FunctionCommas(data[index]?.userBonusperLevel)} Bonus
                </Typography>
              </Box>
            )}
          </Box>

          {/* Level icon */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "310px",
              height: "310px",
              marginX: "auto",
              justifyContent: "center",
            }}
          >
            <img
              onClick={handleLeftClick}
              src={Arrowright}
              width={64}
              alt="Arrowright"
              height={64}
              style={{ rotate: "180deg" }}
            />
            <img
              src={data[index]?.img}


              height={186.01}
              alt="level_icon"
              width={186}
              style={{ position: "relative", filter: 'drop-shadow(30px 30px 20px #e8b5ff23) drop-shadow(-12px 12px 20px #e8b5ff23) drop-shadow(12px -12px 20px #e8b5ff23) drop-shadow(-12px -12px 20px #e8b5ff23)' }}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}

            />
            <img
              onClick={handleRightClick}
              src={Arrowright}
              width={64}
              alt="Arrowleft"
              height={64}
            />
          </Box>

          {/* Progress bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "15px",
              position: "fixed",
              top: "515px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              {!isActiveLeague && data[index]?.currentleague > userCurrentLeague && (
                <Typography
                  sx={{
                    color: "#FFF",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "21.78px",
                    fontFamily: "Inter",
                    textAlign: "center",
                  }}
                >
                  Reach  {FunctionCommas(data[index]?.maxShares)}
                </Typography>
              )}

              {isActiveLeague && (
                <>
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "21.78px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    }}
                  >
                    {FunctionCommas(currentPoint)}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "14.52px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    }}
                  >
                    / {/* Separator */}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "14.52px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    }}
                  >
                    {FunctionCommas(data[index]?.maxShares)}
                  </Typography>
                </>
              )}
            </Box>

            {isActiveLeague && (
              <LevelsProgressBar
                value={currentPoint}
                max={data[index]?.maxShares}
              />
            )}
          </Box>
        </Box>
      </Box>

      <img src={star_background} style={{ position: "absolute", animation: `${scaleUp} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`, zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%", }} />
    </Box>
  );
};

export default Levels;
