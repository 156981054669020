import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import cardData from '../../utils/DailyRewardData'; // Adjust the path accordingly
import { claimDailyReward, RewardsData, setData } from '../../Apis'
import { useDispatch, useSelector } from 'react-redux';
import claimedCheckIcon from '../../images/claimedCheckIcon.svg'
import { FunctionCommas } from "../../utils/commasFun"
import infocircleIcon from '../../images/infocircleIcon.svg'
import success from '../../images/GreenCheckIcon.svg'
import { setUserStreak, setIsDailyRewardsClaim, setUserData, setUserDailyRewardReset } from '../../store/slices/userSlice'

const DailyReward = () => {
    const [claimedCards, setClaimedCards] = useState([]);
    const [rewardsData, setRewardsData] = useState([]);
    const [userRewardData, setUserRewardData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
    const [showTimerPopup, setShowTimerPopup] = useState(false); // Timer popup visibility state
    const [rewardClaimed, setRewardClaimed] = useState(false);
    // const [timeRemaining, setTimeRemaining] = useState('');
    const [timeLeft, setTimeLeft] = useState(null)
    const [timerEnded, setTimerEnded] = useState(false);
    const daysWithShadow = [7, 14, 21, 29];

    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const userData = useSelector((state) => state?.user?.userData)
    const userDailyrewardResetTime = useSelector((state) => state?.user?.dailyRewardsResetAt)

    const userStreak = useSelector((state) => state?.user?.userData?.streak);
    const dailyreward = useSelector((state) => state?.user?.dailyreward)
    const isRewardClaimed = useSelector((state) => state?.user?.isDailyRewardsClaim);
    //   console.log("isRewardClaimed", isRewardClaimed);

    // console.log("userstreak", userstreak);

    const accessToken = useSelector((state) => state?.user?.accessToken);



    


    const calculateTimeLeft = (endTime) => {
        const now = new Date();
        const endTimeDate = new Date(endTime);
        const timeDiff = endTimeDate - now;

        if (timeDiff > 0) {
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
        return "00:00:00"; // Timer has ended
    };

    
    console.log("user verified", userData?.isUserVerified);
    useEffect(() => {
        console.log("userDailyrewardResetTime>>>>>>",userDailyrewardResetTime);
        if (userDailyrewardResetTime) {
            const interval = setInterval(() => {
                const formattedTime = calculateTimeLeft(userDailyrewardResetTime);

                // Update the timer display if time remains
                if (formattedTime !== "00:00:00") {
                    setTimeLeft(formattedTime);
                }

                // If the time is exactly 00:00:00, stop the interval
                if (formattedTime === "00:00:00" && !timerEnded) {
                    setTimerEnded(true);
                    dispatch(setUserDailyRewardReset(userDailyrewardResetTime)); // Adjust reset logic here if needed
                    clearInterval(interval);
                }
            }, 1000); // Update every second

            // Clear the interval on component unmount
            return () => clearInterval(interval);
        } else {
            // If there's no reset time, set the timer as ended or handle accordingly
            setTimeLeft("00:00:00");
            setTimerEnded(true);
        }
    }, [userDailyrewardResetTime, timerEnded, dispatch]);



    const handleCardClick = (day) => {
        if (userStreak !== day - 1 && !rewardClaimed) {
            setErrorMessage(`Your Streak is on Day ${userStreak + 1}. You clicked on Day ${day}.`);
            setTimeout(() => setErrorMessage(''), 3000);
        } else {
            dispatch(claimDailyReward(userId, accessToken))
                .then((response) => {
                    if (response?.success) {
                        setRewardClaimed(true);
                        setClaimedCards((prevClaimedCards) => [...prevClaimedCards, day]);
                        setShowPopup(true);

                        const updatedStreak = userStreak + 1;
                        dispatch(setUserStreak(updatedStreak));
                        dispatch(setIsDailyRewardsClaim(true));

                        setTimeout(() => setShowPopup(false), 3000); // Hide success popup after 3 seconds

                        // Update time left with the new reset time after claiming the reward
                        if (response?.reward?.dailyRewardsResetAt) {
                            dispatch(setUserDailyRewardReset(response.reward.dailyRewardsResetAt));
                            setTimeLeft(calculateTimeLeft(response.reward.dailyRewardsResetAt));

                            // Manually re-trigger timer countdown
                            setTimerEnded(false); // Reset the timer state
                        }
                    } 
                    else {
                        const message = response?.message || 'Failed to claim reward. Please try again.';
                        setErrorMessage(message);
                        setTimeout(() => setErrorMessage(''), 3000);
                    }
                })
                .catch((error) => {
                    setErrorMessage(error?.message || 'Failed to claim reward. Please try again.');
                    setTimeout(() => setErrorMessage(''), 3000);
                });
        }
    };


    return (
        <>
            <Box
                sx={{
                    opacity: userData?.isUserVerified ? 1 : 0.5, // Set opacity based on verification status
                    pointerEvents: userData?.isUserVerified ? 'auto' : 'none', // Disable pointer events if not verified
                }}
            >

                {showPopup && (
                    <Box
                        sx={{
                            backgroundColor: "rgba(81, 218, 76, 0.1)", // Transparent background
                            width: "351px",
                            height: "51.34px",
                            borderRadius: "12px",
                            marginBottom: "16px",
                            border: "1px solid #51DA4C80",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 12px",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "22px",
                                    height: "22px",
                                    borderRadius: "26.4px",
                                    backgroundColor: "rgba(81, 218, 76, 1)",
                                    marginRight: "15px"
                                }}
                            >
                                <img src={success} alt="Claimed" />
                            </Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '18.2px',
                                    textAlign: 'left',
                                    color: "#ffffff",
                                }}
                            >
                                You have successfully claimed the reward!
                            </Typography>
                        </Box>
                    </Box>
                )}

                {isRewardClaimed && !showPopup && (
                    <Box
                        sx={{
                            backgroundColor: "rgba(251, 196, 77, 0.1)",
                            width: "335px",
                            height: "51.33px",
                            borderRadius: "12px",
                            marginBottom: "16px",
                            border: "1px solid #FBC44D80",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0 12px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '15.43px',
                                fontWeight: 700,
                                textAlign: 'center',
                                lineHeight: "20.06px",
                                color: '#FBC44D',
                            }}
                        >
                            {timeLeft}
                        </Typography>
                    </Box>
                )}

                {/* Error Message Popup */}
                {errorMessage && (
                    <Box
                        sx={{
                            backgroundColor: "rgba(251, 196, 77, 0.1)",
                            height: "38.67px",
                            borderRadius: "12px",
                            marginBottom: "16px",
                            border: "1px solid #FBC44D80",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0 12px",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={infocircleIcon} alt="Error" style={{ marginRight: "8px" }} />
                            <Typography
                                sx={{
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '15.6px',
                                    textAlign: 'left',
                                    color: "#ffffff",
                                }}
                            >
                                {errorMessage}
                            </Typography>
                        </Box>
                    </Box>
                )}


                <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap="8px">
                    {dailyreward?.map((card, index) => {
                        // Disable the card where card.day is less than or equal to userRewardData?.Streak
                        const isDisabled = card.day <= userStreak || claimedCards.includes(card.day);

                        let borderStyle;
                        if (isDisabled) {
                            // borderStyle = '1px solid #FFFFFF'; // Disabled styling
                            borderStyle = {
                                border: '1px solid transparent',
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'content-box, border-box'
                            };
                        } else if (claimedCards.includes(card.day)) {
                            borderStyle = {
                                border: '1px solid transparent',
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'content-box, border-box'
                            };
                        } else if (card.day === userStreak + 1 && !isRewardClaimed) {
                            borderStyle = {
                                border: '1px solid transparent',
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'content-box, border-box'
                            };
                        } else if ([7, 14, 21, 29].includes(card.day)) {
                            borderStyle = {
                                border: '1px solid transparent',
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'content-box, border-box'
                            };
                        } else {
                            borderStyle = {
                                border: '1px solid transparent',
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)',
                                backgroundOrigin: 'border-box',
                                backgroundClip: 'content-box, border-box'
                            };
                        }

                        // Determine background color based on card.day
                        let backgroundColor;
                        if (isDisabled) {
                            backgroundColor = 'rgba(149, 131, 255, 0.25)'; // Disabled background
                        } else if ([29].includes(card.day)) {
                            backgroundColor = 'rgba(251, 196, 77, 0.25)'; // Background for Max
                        } else {
                            backgroundColor = '#AA4545'; // Default background
                        }


                        return (
                            <>
                                <Card
                                    key={index}
                                    onClick={() => userData?.isUserVerified && !isDisabled && handleCardClick(card.day)}
                                    sx={{
                                        width: '81.38px',
                                        height: '92px',
                                        borderRadius: '12px',
                                        border: borderStyle,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        backgroundColor: backgroundColor,
                                        marginTop: '0px !important',
                                        opacity: isDisabled ? 0.5 : 1, // Set opacity based on isDisabled
                                        pointerEvents: isDisabled ? 'none' : 'auto', // Disable pointer events when isDisabled is true
                                        boxShadow: card.day === userStreak + 1  ? '0px 0px 30px rgba(149, 131, 255, 0.3)' : 'none', 
                                       
                                       
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            marginTop: '0px !important',
                                            padding: '0px !important',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                width: '52px',
                                                textAlign: 'center',
                                                fontFamily: 'Inter',
                                                fontSize: '10px',
                                                fontWeight: 400,
                                                lineHeight: '13px',
                                                color: '#FFFFFF',
                                                marginTop: '10.5px',
                                            }}
                                        >
                                            {/* Day {card.day} */}
                                            {card.day === 29 ? 'Max' : `Day ${card.day}`}
                                        </Typography>


                                        {isDisabled ? (
                                            <img
                                                style={{ color: '#FFFFFF', fontSize: 20 }}
                                                src={claimedCheckIcon}
                                                alt="Claimed"
                                            />
                                        ) : (
                                            <>

                                                <img
                                                    width={20}
                                                    height={20}
                                                    src={card.image} // Width and height for other days
                                                    alt={card.day}
                                                    style={{
                                                        boxShadow: daysWithShadow.includes(card.day)
                                                            ? '0px 0px 10px rgba(251, 196, 77, 0.75), 0px 0px 10px rgba(251, 196, 77, 0.75)'
                                                            : 'none', // No shadow for other days
                                                        borderRadius: "10px",
                                                    }}
                                                />




                                            </>
                                        )}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 700,
                                                lineHeight: '15.6px',
                                                letterSpacing: '-0.01em',
                                                textAlign: 'center',
                                                color: '#000000',
                                                background: isDisabled
                                                    ? '#FBC44D'
                                                    : [8, 15, 22, 28].includes(card.day)
                                                        ? '#FBC44D'  // Change to transparent for specified dates
                                                        : '#FBC44D',

                                                borderRadius: '4px',
                                                padding: '1px 3px',
                                            }}
                                        >
                                            {FunctionCommas(card.AirdropPoints)}
                                        </Typography>
                                    </CardContent>



                                </Card>


                            </>
                        );
                    })}
                </Box>

            </Box>

            {/* button here  */}

            <Box
                sx={{
                    position: "fixed",
                    bottom: "15%",
                    width:'calc(100% - 43px)',marginLeft:"22px",marginRight:"21px",
                    zIndex: 1000, // Ensure buttons are above other content
                    display: !isRewardClaimed && userData?.isUserVerified ? "flex":"none",
                }}
            >
                <Button
                    sx={{
                        bgcolor: "#802020",
                        width: "100%",
                        height: "55px",
                        color: "#FFFFFF",
                        borderRadius: "12px",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontWeight: 700,
                        lineHeight: "23.4px",
                        textTransform: "none",
                        "&:hover": {
                            bgcolor: "#802020", // Adjust hover color if needed
                        },
                    }}
                onClick={() => userData?.isUserVerified && !rewardClaimed && handleCardClick(userStreak+1)}
                >
                    Claim
                </Button>

            </Box>

        </>

    );
};

export default DailyReward;
