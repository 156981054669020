import React, { useState } from 'react';
import { Box, Typography, IconButton, styled, keyframes } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { BuyUserMultitap, BuyUserEnergyLimit, BuyUserRechargeSpeed ,BuySuperBoost} from '../../../Apis';
import ThreeSectionLodder from '../../ThreeSectionLodder';
import { useNavigate } from 'react-router-dom';
import { FunctionCommas } from '../../../utils/commasFun';
import {setBoosterPopVisible,setSuperBoostPopup}  from "../../../store/slices/userSlice";
import SuperBoostIcon from "../../../images/SuperBoostIcon.svg"
import TotalLifePoints from "../../../images/TotalLifePoints.svg"
function SuperBoostPopup({ onClose, }) {
    const dispatch = useDispatch();
    dispatch(setBoosterPopVisible(false));
    const { userId } = useSelector((state) => state.user);
    const userData = useSelector((state) => state.user.userData);
    const accessToken = useSelector((state)=>state?.user?.accessToken);
    const currentPoint = useSelector((state) => state?.point?.currentPoint)
    const navigate = useNavigate();

    const boostMultiplier = useSelector((state) => state?.user?.boostMultiplier);
    const isLimitReached = useSelector((state) => state?.user?.isLimitReached);
    const isSuperBoostActive = useSelector((state) => state?.user?.isSuperBoostActive);
    const usageCount = useSelector((state) => state?.user?.usageCount);
   


    // const handleBuy = async () => {
    //     // dispatch(setBoosterPopVisible(true));
    //     dispatch(BuySuperBoost(userId));  
    // };
    const handleBuy = async () => {
        try {
            // Dispatch the buy action
            await dispatch(BuySuperBoost(userId));
            dispatch(setSuperBoostPopup(true));
    
            // Call onClose to close the popup after the buy action is successful
            
            onClose();
        } catch (error) {
            console.error("Error during the purchase:", error);
        }
    };


    const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
   const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
    return (

        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left:0,
                right:0,
                background: '#802020',
                height: '393px',
                zIndex: 10000000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '25px',
                animation: `${slideUp} 200ms ease-out forwards`,
                padding:"23px",
                borderRadius:"25px"
                
            }}
        >
          { !false ? 
          <>       
          <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    color: '#fff',
                
                }}
            >
                <CloseIcon />
            </IconButton>
            <img  src={SuperBoostIcon} alt="icon" width={160} height={160} style={{marginTop:"12px"}} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    top:'220px'  
                }}
            >
                <Typography
                    component="p"
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '32px',
                        lineHeight: '38.4px',
                        height: "38px",
                        color: '#fff',
                    }}
                >
                    Super Boost
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16.94px',
                        color: '#fff',
                        textAlign: 'center',
                        marginTop:"12px"
                    }}
                >
                    Increase the amount of points you earn per tap!
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#fff',
                        textAlign: 'center',
                        marginTop:"12px"
                    }}
                >
                    {`+${boostMultiplier}X per tap for this super boost`}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                }}
            >
                <img  src={TotalLifePoints} width={17} height={17} alt="coin_icon" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="p"
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '18px',
                            lineHeight: '21.78px',
                            color: '#fff',
                        }}
                    >
                      
                    </Typography>
                    <Typography
                        component="p"
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14.52px',
                            color: '#fff',
                            marginLeft:"4px"
                        }}
                    >
                         {`your daily use count ${usageCount}`}
                    </Typography>
                </div>
            </Box>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <button
                    style={{
                        width: '100%',
                        background: '#AA4545',
                        outline: 'None',
                        border: 'None',
                        padding: '16px',
                        borderRadius: '12px',
                        marginBottom:'31px',
                        border: '1px solid transparent', // Transparent border to allow background-clip
                        backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                        backgroundOrigin: 'border-box', // Background starts from the border
                        backgroundClip: 'padding-box, border-box', 
                    }}
                    onClick={handleBuy}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 800,
                            fontSize: '18px',
                            lineHeight: '23.4px',
                            color: '#fff',
                        }}
                    >
                        Boost!
                    </Typography>
                </button>
            </Box>
                    
          </> :<ThreeSectionLodder/>}
        </Box>
    );
}

export default SuperBoostPopup;
