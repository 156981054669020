import React from 'react';
import { styled, keyframes } from '@mui/system';
import {Box} from "@mui/material";
import preloadimg from '../images/preloadimg.png'

const ThreeSectionLodder = () => {
    const rotateAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;
  
  const LoaderContainer = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }));
  
  const LoaderSection = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #802020',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,

  
  }));
  const LoaderSection2 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  const LoaderSection3 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));

  const LoaderSection4 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));

  const LoaderSection5 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  const LoaderSection6 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  const LoaderSection7= styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  const LoaderSection8 = styled(Box)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: '10px solid transparent',
    borderTop: '10px solid #BB5C17',
    borderRadius: '50%',
    position: 'absolute',
    animation: `${rotateAnimation} 1s linear infinite`,
  }));
  

  
  const LoaderSectionDelayed1 = styled(LoaderSection2)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  
  const LoaderSectionDelayed2 = styled(LoaderSection3)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  const LoaderSectionDelayed3 = styled(LoaderSection4)(({ theme }) => ({
    animationDelay: '0.33s',
  }));

  const LoaderSectionDelayed4 = styled(LoaderSection5)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  const LoaderSectionDelayed5 = styled(LoaderSection6)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  const LoaderSectionDelayed6 = styled(LoaderSection7)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  const LoaderSectionDelayed7 = styled(LoaderSection8)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  const LoaderSectionDelayed8 = styled(LoaderSection2)(({ theme }) => ({
    animationDelay: '0.33s',
  }));
  

  return (
    <>
     <LoaderContainer>
     <LoaderSection />
    <LoaderSectionDelayed1 />
    <LoaderSectionDelayed2 />
    <LoaderSectionDelayed3/>
    <LoaderSectionDelayed4/>
    <LoaderSectionDelayed5/>
  </LoaderContainer>


  </>

  
  )
}

export default ThreeSectionLodder