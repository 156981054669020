import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { buyUserMultitap, getUserMiningInfo } from '../../Apis';

const initialState = {
  userData: {},
  initailData : {},
  rewardsData: [],
  day: null,
  AirdropPoints: 0,
  streak: 0,
  completedTaskCount: 0,
  isDailyRewardsClaim: false,
  lastRewardClaimAt: null,
  totalTaskCount: 0,
  totalAirdropPoints: 0,
  status: 'idle', // 'loading', 'succeeded', 'failed'
  error: null,
  userminininfo: {},
  dailyreward:{},
  userId: '',
  userRank: 0,
  userReferalList: [],
  userReferalCount: 0,
  userTask: JSON.parse(localStorage.getItem("tasks")) ? JSON.parse(localStorage.getItem("tasks")) : [],
  userTonTickets: JSON.parse(localStorage.getItem("tonTicket")) ? JSON.parse(localStorage.getItem("tonTicket")) : [],
  numberOfTickets: 0,
  totalUsers: 0,
  totalUsersPoints: 0,
  dailyActiveUsers: 0,
  activeUsers: 0,
  isMiningActive: false,
  referrals:  0,
  UsersLeaderboard: [],
  referralCount: 0,
  taskName: '',
   totalReferralEarning : 0, 
   lifeTimePoints: 0,
   refralsInfo: [],
   userLevelData: {},
   accessToken:'',
   isDailyRewardsClaim:true,
   isRefferalReached:false,
   refferalTime:"",
   userDailyreward: {},
   dailyRewardsResetAt: null,
   boosterPopVisible: true,
   miningMultiplier:"1x",
   bosterPopUp:false,

   boostMultiplier:1,
   isLimitReached:false,
   isSuperBoostActive:false,
   usageCount:1,

   openSuperBoostPopup:false,
   tonWalletAddress:null,

   superBoostPopup:false,



};





const userSlice = createSlice({
  name: 'user',
  initialState,
  multitapPurchase: null,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUserStreak(state, action) {
      state.userData.streak = action.payload; // Update the streak
  },
//   setUserDailyRewardReset(state, action) {
//     state.userData.dailyRewardsResetAt = action.payload; // Update the streak
// },
  // setUserMiningInfo : (state,action) => {
  //   state.userminininfo = action.payload;
  //   state.userminininfo.isMiningActive = action.payload?.isMiningActive;
  //   state.userminininfo.AirdropPoints = action.payload?.AirdropPoints;
  // },
  setUserMiningInfo: (state, action) => {
    const miningInfo = action.payload;
    state.userminininfo = miningInfo;
    state.isMiningActive = miningInfo?.isMiningActive; 
    state.AirdropPoints = miningInfo?.AirdropPoints;
    state.lastMiningEndTime = miningInfo?.lastMiningEndTime;

  },
  setUserDailyreward: (state, action) => {
    state.userDailyreward = action.payload;
  },
  setUserInitalData: (state, action) => {
    state.initailData = action.payload;
  },
  
  setIsDailyRewardsClaim(state, action) {
    state.isDailyRewardsClaim = action.payload; 
  },
  setUserDailyRewardReset(state, action) {
    state.dailyRewardsResetAt = action.payload; 
  },
  setRewardData : (state,action) => {
    state.dailyreward = action.payload;
  },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUserInitalData: (state, action) => {
      state.initailData = action.payload;
    },
    setUserRank: (state, action) => {
      state.userRank = action.payload;
    },
    setUserReferallist: (state, action) => {
      state.userReferalList = action.payload;
    },
    setUserReferalCount: (state, action) => {
      state.userData.referrals = action.payload;
    },    
    setUserTasks: (state, action) => {
      state.userTask = action.payload;

    },
    updateTotalKlinkersPoints: (state, action) => {
      state.totalUsersPoints += action.payload;
    },
    updateLifeTimePoints: (state, action) => {
      state.lifeTimePoints += action.payload;
    },
    updateNumberOfTickets: (state, action) => {
      state.userData.numberOfTickets = (state.userData.numberOfTickets || 0) + action.payload;
    },
    setUserStats: (state, action) => {
      const { totalUsers, totalUsersPoints, dailyActiveUsers, activeUsers, UsersLeaderboard,totalTouches } = action.payload.community;
      const {referralCount, totalReferralEarning ,numberOfTickets, lifeTimePoints, refralsInfo, referrals } = action.payload.user;
      state.totalUsers = totalUsers;
      state.totalUsersPoints = totalUsersPoints;
      state.dailyActiveUsers = dailyActiveUsers;
      state.activeUsers = activeUsers;
      state.totalTouches = totalTouches;
      state.UsersLeaderboard = UsersLeaderboard;
      state.referralCount = referralCount;
      state.totalReferralEarning = totalReferralEarning;
      state.lifeTimePoints = lifeTimePoints;
      state.refralsInfo = refralsInfo;
      state.referrals = referrals;
    },
    setuserLevel: (state, action) => {
      state.userLevelData = action.payload;
    },
    setNextBootsMultiTapLevel: (state, action) => {
      state.userData.boosts.multiTap = action.payload;
    },
    setNextBootsEnergyLimitLevel: (state, action) => {
      state.userData.boosts.energyLimit = action.payload;
    },
    setNextBootsRechargingSpeedLevel: (state, action) => {
      state.userData.boosts.rechargingSpeed = action.payload;
    },
    setUserVerifiedStatus : (state,action) =>{
      state.userData.isUserVerified = action.payload;
    },
    setAccessToken:(state,action)=>{
      state.accessToken = action.payload;
    },
    setUserTonTickets : (state,action) => {
      state.userTonTickets = action.payload;
      // state.userTonTickets.numberOfTickets = action.payload;
      state.numberOfTickets = action.payload.length;
    },
  
    setBoostData: (state, action) => {
      state.userData["boosts"] = action.payload;
    },
    setIsRefferalReached(state, action) {
      state.isRefferalReached = action.payload; // Update the daily rewards claim state
    },

    setRefferalTime(state, action) {
      state.refferalTime = action.payload; // Update the daily rewards claim state
    },

    setBoosterPopVisible(state,action){
      state.boosterPopVisible=action.payload;
    },

    setMiningMultiplier(state, action) {
      state.userData.miningMultiplier = action.payload; // Update the daily rewards claim state
    },
    setBosterPopUp(state, action) {
      state.bosterPopUp = action.payload; // Update the daily rewards claim state
    },


    setBoostMultiplier(state, action) {
      state.boostMultiplier = action.payload; // Update the daily rewards claim state
    },
    setIsLimitReached(state, action) {
      state.isLimitReached = action.payload; // Update the daily rewards claim state
    },
    setIsSuperBoostActive(state, action) {
      state.isSuperBoostActive = action.payload; // Update the daily rewards claim state
    },
    setUsageCount(state, action) {
      state.usageCount = action.payload; // Update the daily rewards claim state
    },

    setOpenSuperBoostPopup(state,action){
      state.openSuperBoostPopup=action.payload;
    },
    
    setTonWalletAddress(state,action){
      state.tonWalletAddress=action.payload;
    },
    
    setSuperBoostPopup(state,action){
      state.superBoostPopup=action.payload;
    },
    setMiningHourly(state, action) {
      state.userData.hourlyRewards = action.payload;
    },


  },

});

    export const {setIsRefferalReached, setBoosterPopVisible, setRefferalTime, setUserData, setAccessToken,setUserId,setRewardData, setUserRank,setUserReferallist, setUserReferalCount, setUserTasks, setUserStats, setuserLevel , setNextBootsMultiTapLevel, setNextBootsEnergyLimitLevel, setNextBootsRechargingSpeedLevel,setUserVerifiedStatus, setUserTonTickets, setBoostData,updateLifeTimePoints,updateTotalKlinkersPoints,numberOfTonTickets,updateNumberOfTickets, setUserInitalData, setUserStreak, setUserMiningInfo, setIsDailyRewardsClaim,setUserDailyreward, setUserDailyRewardReset, setMiningMultiplier, setBosterPopUp,setBoostMultiplier,setIsLimitReached,setIsSuperBoostActive,setUsageCount,setOpenSuperBoostPopup,setTonWalletAddress,setSuperBoostPopup,setMiningHourly} = userSlice.actions;

export default userSlice.reducer;
