import { setCurrentEnergy, setMaxEnergy, setTapPoints, setUserTotalTaps } from "../store/slices/miningInfoSlice";
import { setCurrentPoint, setCurrentTokenCount, setUserPoint, reduceCurrentPoint, reduceUserPoints, IncreaseUserPoints, IncreaseCurrentPoint } from "../store/slices/pointSlice";
import {
  setUserData,
  setUserId,
  setUserRank,
  setUserTasks,
  setUserStats,
  setuserLevel,
  setNextBootsMultiTapLevel,
  setNextBootsRechargingSpeedLevel,
  setNextBootsEnergyLimitLevel,
  setUserVerifiedStatus,
  setUserTonTickets,
  setAccessToken,
  setBoostData,
  updateTotalKlinkersPoints,
  updateLifeTimePoints,
  updateNumberOfTickets,
  setUserReferalCount,
  setUserInitalData,
  setUserMiningInfo,
  setRewardData,
  setIsDailyRewardsClaim, setIsRefferalReached, setRefferalTime, setUserDailyreward, setUserDailyRewardReset, setMiningMultiplier, setBoostMultiplier, setIsLimitReached, setIsSuperBoostActive, setUsageCount,setTonWalletAddress,
} from "../store/slices/userSlice";
import { generateUserKey } from "../utils/getKey";
import axios from "axios";


// function detectDeviceOS() {
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

//   if (/android/i.test(userAgent)) {
//     return 'Android';
//   }

//   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//     return 'iOS';
//   }

//   return 'Unknown';
// }


function getDeviceOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  // Any other OS or default to unknown
  return "Unknown OS";
}


export function init() {
  return async (dispatch) => {
    const search = window.Telegram.WebApp.initData;
    let data = null;
    if (search) {
      const converted = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      data = JSON.parse(converted.user);
    }
    if (data) {
      dispatch(setUserId(data.id));
      dispatch(setUserInitalData(data));
      // setIsTelegramMiniApp(true)
    }
  };
} 
async function getLeagueData() {
  // Fetch league data from your database
  // Replace this with your actual API call or data retrieval logic
  return [
    { _id: '66b37cb98ac4206bf5172bab', currentleague: 1, league: "Bronze", leagueFrom: 0, leagueTo: 99999, levelUpBonus: 0 },
    { _id: '66b37cb98ac4206bf5172bac', currentleague: 2, league: "Silver", leagueFrom: 100000, leagueTo: 499999, levelUpBonus: 10000 },
    { _id: '66b37cb98ac4206bf5172bad', currentleague: 3, league: "Gold", leagueFrom: 500000, leagueTo: 999999, levelUpBonus: 25000 },
    { _id: '66b37cb98ac4206bf5172bae', currentleague: 4, league: "Platinum", leagueFrom: 1000000, leagueTo: 2499999, levelUpBonus: 75000 },
    { _id: '66b37cb98ac4206bf5172baf', currentleague: 5, league: "Diamond", leagueFrom: 2500000, leagueTo: 10000000, levelUpBonus: 200000 },
  ];
}

export async function checkAndUpdateLeague(userId, currentPoints, dispatch, accessToken, userLeague) {

  try {
    const leagues = await getLeagueData();
    const currentLeague = leagues.find(league => league.league === userLeague);
    if (currentLeague) {
      // Find the next league based on the current league's level
      const nextLeague = leagues.find(league => league.currentleague === currentLeague.currentleague + 1);
      // Ensure correct data types
      const points = Number(currentPoints);
      const leagueFrom = Number(nextLeague.leagueFrom);

      if (points >= leagueFrom) {
        // Fetch updated user data
        const result = await generateUserKey();
        const userKey = result.userKey;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}?isAuth=true`, {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        const { data } = response;

        dispatch(setCurrentPoint(data?.data?.user?.points));
        dispatch(setUserPoint(data?.data?.user?.points))
        dispatch(setUserData(data?.data?.user));
        dispatch(setMaxEnergy(data?.data?.user?.maxEnergy));
        dispatch(setCurrentEnergy(data?.data?.user?.currentEnergy));
        dispatch(setTapPoints(data?.data?.pointsPerTap));
        dispatch(setAccessToken(data?.data?.token))
        return nextLeague._id;
      } else {
        // User has not reached the next league
        return null;
      }
    } else {
      // User's current league is not found
      return null;
    }

  } catch (error) {
    console.error("Error checking league data:", error);
    return null;
  }
}



// export function setData(userId, setLoading, accessToken) {
//   return async (dispatch) => {
//     try {
//       const startTime = performance.now(); // Record the start time
//       if (!userId) {
//         // console.error("user id not found");
//         return;
//       }
//       const result = await generateUserKey();
//       const userKey = result.userKey;      
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}?isAuth=true`, {
//         headers: {
//           sessionKey:userKey,
//           Authorization: `Bearer ${accessToken}`,
//           'Content-Type': 'application/json',
//         },
//       });
//       const endTime = performance.now();
//       const responseTime = endTime - startTime; // Calculate the response time
//       // console.log('API Response:', response.data);
//       console.log(`API Response Time: ${responseTime.toFixed(2)}ms`);


//       setLoading(false);
//       dispatch(setAccessToken(response?.data?.data?.token))
//       dispatch(setUserPoint(response?.data?.data?.user?.points))
//       dispatch(setCurrentTokenCount(response?.data?.data?.user?.points));
//       dispatch(setUserData(response?.data?.data?.user));
//       dispatch(setTapPoints(response?.data?.data?.pointsPerTap));
//       dispatch(setMaxEnergy(response?.data?.data?.user?.maxEnergy));
//       dispatch(setCurrentEnergy(response?.data?.data?.user?.currentEnergy));
//       // console.log("curent energy ", response?.data?.data?.user?.currentEnergy);

//     } catch (error) {
//       console.error("Error fetching user data:", error);
//       // Handle errors, e.g., show an error message
//     }
//   };
// }


// export function setData(userId,  initailData,  setLoading, accessToken) {
//   return async (dispatch) => {
//     try {
//       const startTime = performance.now(); // Record the start time
//       if (!userId) {
//         console.error("User ID not found");
//         return;
//       }

//       const result = await generateUserKey();
//       const userKey = result.userKey;
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}?isAuth=true`, {
//         headers: {
//           sessionKey: userKey,
//           Authorization: `Bearer ${accessToken}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       const endTime = performance.now();
//       const responseTime = endTime - startTime; // Calculate the response time
//       console.log(`API Response Time: ${responseTime.toFixed(2)}ms`);

//       // Check if deviceOS is null or empty
//       const deviceOS = response?.data?.data?.user?.deviceOS;
//       if (!deviceOS) {
//         console.log('deviceOS is null or empty, calling the deviceOS API');

//         // Get dynamic device OS
//         const dynamicDeviceOS = getDeviceOS();

//         // Call the deviceOS API
//         await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/device-os/${userId}`, {
//           deviceOS: dynamicDeviceOS
//         }, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//             'Content-Type': 'application/json',
//           },
//         });
//       }

//       setLoading(false);
//       dispatch(setAccessToken(response?.data?.data?.token));
//       dispatch(setUserPoint(response?.data?.data?.user?.points));
//       dispatch(setCurrentTokenCount(response?.data?.data?.user?.points));
//       dispatch(setUserData(response?.data?.data?.user));
//       dispatch(setTapPoints(response?.data?.data?.pointsPerTap));
//       dispatch(setMaxEnergy(response?.data?.data?.user?.maxEnergy));
//       dispatch(setCurrentEnergy(response?.data?.data?.user?.currentEnergy));


//       dispatch(setIsRefferalReached(response?.data?.data?.user?.isReferralReached));
//       console.log("Refral end time",response?.data?.data?.user?.dailyRefEndTime)
//       dispatch(setRefferalTime(response?.data?.data?.user?.dailyRefEndTime));

//     } catch (error) {
//       console.error("Error fetching user data:", error);
//       setLoading(false); // Ensure loading is set to false in case of error
//       // Handle errors, e.g., show an error message
//     }
//   };
// }


export function setData(userId, initailData, setLoading, accessToken,) {
  return async (dispatch) => {
    try {
      const startTime = performance.now(); // Record the start time
      if (!userId) {
        console.error("User ID not found");
        return;
      }

      const result = await generateUserKey();
      const userKey = result.userKey;     
      if(userId && initailData){
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}`, {
          params: {
            isAuth: true,              
            initailData: initailData,   
          },
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        console.log("userData:>>>",response?.data?.data?.user?.tonAddress);
        dispatch(setTonWalletAddress(response?.data?.data?.user?.tonAddress));
  
        const endTime = performance.now();
        const responseTime = endTime - startTime; // Calculate the response time
        console.log(`API Response Time: ${responseTime.toFixed(2)}ms`);
  
        // Check if deviceOS is null or empty
        const deviceOS = response?.data?.data?.user?.deviceOS;
        if (!deviceOS) {
          console.log('deviceOS is null or empty, calling the deviceOS API');
  
          // Get dynamic device OS
          const dynamicDeviceOS = getDeviceOS();
  
          // Call the deviceOS API
          await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/device-os/${userId}`, {
            deviceOS: dynamicDeviceOS
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
        }
  
        setLoading(false);
        dispatch(setAccessToken(response?.data?.data?.token));
        dispatch(setUserPoint(response?.data?.data?.user?.points));
        dispatch(setCurrentTokenCount(response?.data?.data?.user?.points));
        dispatch(setUserData(response?.data?.data?.user));
        dispatch(setTapPoints(response?.data?.data?.pointsPerTap));
        dispatch(setMaxEnergy(response?.data?.data?.user?.maxEnergy));
        dispatch(setCurrentEnergy(response?.data?.data?.user?.currentEnergy));
        dispatch(setIsRefferalReached(response?.data?.data?.user?.isReferralReached));
        dispatch(setUserDailyRewardReset(response?.data?.data?.user?.dailyRewardsResetAt));
        console.log("All Data got dispatched....");        
      }else{
        console.log("user Data was not found...", userId, initailData);        
      }

    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false); // Ensure loading is set to false in case of error
      // Handle errors, e.g., show an error message
    }
  };
}



export function SetBoostData(userId, accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        return;
      }
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/boostHistory/${userId}`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      dispatch(setBoostData(response?.data?.user?.boosts));

      console.log("boost multiplier data----",response?.data?.user?.superBoost?.boostMultiplier);
      // form here i will dispatch all the bits of super boost
      dispatch(setBoostMultiplier(response?.data?.user?.superBoost?.boostMultiplier));
      dispatch(setIsLimitReached(response?.data?.user?.superBoost?.isLimitReached));
      dispatch(setIsSuperBoostActive(response?.data?.user?.superBoost?.isSuperBoostActive));
      dispatch(setUsageCount(response?.data?.user?.superBoost?.usageCount));


    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}

export function setLevelsData(userId, accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        // console.error("user id not found");
        return;
      }
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/league/user/${userId}`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      // console.log("setLevelsData", response.data);
      dispatch(setuserLevel(response?.data?.data.leagues))
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}





export function getUserRank(userId, accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        // console.error("user id not found");
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/ranks/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(setUserRank(response.data.rank));
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}




export function getUserMiningInfo(userId, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      console.error("User ID or access token not found");
      return; // Return null if userId or accessToken is not provided
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/mining/userInfo/${userId}`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      ;
      // console.log("mining data",data.data.user); // Log the response data
      dispatch(setUserMiningInfo(response?.data?.data?.user))
      console.log("response?.data?.data?.user", response);


    } catch (error) {
      console.error('Error fetching user info:', error); // Log any errors
    }
  };
}


export function handleStartMining(userId, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      console.error("User ID or access token not found");
      return; // Return null if userId or accessToken is not provided
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/mining/startMinig/${userId}`, {},
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      dispatch(setUserMiningInfo(response?.data?.data?.user))
    } catch (error) {
      console.error(error);

    }
  };
}


export function claimMiningRewards(userId, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      console.error("User ID or access token not found");
      return null; // Return null if userId or accessToken is not provided
    }

    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/mining/ClaimRewards/${userId}`, {},
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Assuming the API response returns success or failure status
      if (response.status === 200) {
        console.log("Mining rewards claimed successfully:", response.data);
        return response.data; // Return the data when successful
      } else {
        console.error("Failed to claim mining rewards:", response);
        return null;
      }
    } catch (error) {
      console.error("Error while claiming mining rewards:", error);
      return null;
    }
  };
}



// export function claimDailyReward(userId, accessToken) {
//   return async (dispatch) => {
//     if (!userId || !accessToken) {
//       // console.log("user id not found");
//       return;
//     }
//     try {
//       const result = await generateUserKey();
//       const userKey = result.userKey;
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/v1/dailyCheckIn/claim-reward/${userId}`, {},
//         {
//           headers: {
//             sessionKey: userKey,
//             Authorization: `Bearer ${accessToken}`,
//             'Content-Type': 'application/json',
//           },
//         } // Include token in headers if required
//       );
//       // dispatch(setUserData(userId,accessToken));
//       dispatch(setUserDailyRewardReset(response?.data?.reward?.dailyRewardsResetAt))
//       dispatch(setMiningMultiplier(response?.data?.reward?.miningMultiplier))
//       return response.data; // Handle the response data as needed

//     } catch (error) {
//       console.error('Error claiming daily reward:', error);
//       throw error; // Optionally, rethrow to handle the error in the calling function
//     }
//   };
// }
export function claimDailyReward(userId, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      return;
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/dailyCheckIn/claim-reward/${userId}`, {},
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        } // Include token in headers if required
      );
      if(response.data.success){
        dispatch(setUserDailyRewardReset(response?.data?.reward?.dailyRewardsResetAt))
        dispatch(setMiningMultiplier(response?.data?.reward?.miningMultiplier))
        // dispatch(setMiningHourly(response?.data.reward?.hourlyRewards))
      }
      // dispatch(setUserData(userId,accessToken));
      return response.data; // Handle the response data as needed

    } catch (error) {
      console.error('Error claiming daily reward:', error);
      throw error; // Optionally, rethrow to handle the error in the calling function
    }
  };
}


export const RewardsData = (accessToken, userId) => {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      console.error("User ID or access token not found");
      return null; // Return null if userId or accessToken is not provided
    }

    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/dailyCheckIn/getRewardsData/${userId}`,
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        } // Include token in headers if required
      );

      // Log response data for debugging
      const isDailyRewardsClaim = response.data?.data?.user?.isDailyRewardsClaim;
      console.log("aaaaaaa>>", isDailyRewardsClaim)
      dispatch(setIsDailyRewardsClaim(isDailyRewardsClaim))
      dispatch(setRewardData(response?.data?.data?.rewardsData))
      // dispatch(setUserDailyreward(response?.data?.data?.user))

    } catch (error) {
      console.error('Error fetching rewards data:', error);
      return null; // Return null in case of error
    }
  };
};

export function getUserTask(userId, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      return;
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/task/getUserTasks/${userId}`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      const data = response.data;
      // window.location.reload();
      // console.log("data", data);

      // const  user = response.data.user
      // console.log("user", user);
      // if(JSON.parse(localStorage.getItem('tasks'))?.length===data?.tasks?.length) {
      //   return
      // };
      dispatch(setUserTasks(data.tasks));
      // dispatch(setUserPoint(user.points));
      // console.log("setUserPoint",user.points);
      // dispatch(setCurrentPoint(user.points));
      // console.log("setCurrentPoint",user.points);

      // localStorage.setItem("tasks", JSON.stringify(data.tasks));

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
}

export function callTapApi(currentPoints, userPoint, userId, tapPoints, totalTaps, currentEnergy, accessToken, userLeague, setOpenTappedOutPopup) {
  console.log("callTapApi userLeague==>", userLeague);
  //   const result = await generateUserKey();
  // console.log("Sesstion key ",result );

  // if (clickCounter.current === 0) return;
  return async (dispatch) => {



    try {
      if (!userId || !accessToken) {
        // console.log("userId Not Found");
        return;
      }
      const result = await generateUserKey();
      const userKey = result.userKey;
      let pointsToAdd = (+currentPoints - +userPoint);
      if (pointsToAdd < 0) pointsToAdd = 0;
      // console.log("pointsToAdd", pointsToAdd);
      const data = {
        points: pointsToAdd + tapPoints,
        totalTaps: totalTaps,
        currentEnergy: currentEnergy
      }
      // console.log("data-----------?",data);
      if (pointsToAdd < 0) return;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/add-point`, data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response) {


        dispatch(setUserPoint(response?.data?.data?.points))
        dispatch(setCurrentEnergy(response?.data?.data?.currentEnergy))
        // console.log("setCurrentEnergy", response?.data?.data?.currentEnergy);
        dispatch(setUserTotalTaps(0));
        // Update stats
        dispatch(updateLifeTimePoints(pointsToAdd + tapPoints));
        dispatch(updateTotalKlinkersPoints(pointsToAdd + tapPoints));
        await checkAndUpdateLeague(userId, currentPoints + pointsToAdd, dispatch, accessToken, userLeague);

        const lostPoint = currentPoints - response?.data?.data?.points;
        if (lostPoint > 0) dispatch(setCurrentTokenCount(lostPoint))
      }
    } catch (error) {
      console.error('Error updating score:', error);
      if (error.response && error.response.status === 422) {
        setOpenTappedOutPopup(true)

        const errorMessage = error.response.data.error;

        // Extract remaining time from error message
        const timeMatch = errorMessage.match(/(\d+):(\d+):(\d+)/);
        if (timeMatch) {
          const [_, hours, minutes, seconds] = timeMatch;
          const remainingTime = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
          console.log("timeremaining", remainingTime)
          // Show timer popup with remaining time

        }
        if (error.response && error.response.status === 404) {
          console.error('Endpoint not found (404)');
        }
      }
    } finally {
      // clickCounter.current = 0;
    }
  }
};




export function userCompleteTask(id, setCompletedTasks, userId, setShowLoader, accessToken, taskPoints, setClaimLoading, setSuccess, setSubtext1, currentPoint, userLeague) {

  return async (dispatch) => {
    const data = {
      taskId: id,
    };

    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/task/execute/${userId}`,
        data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      if (response.status === 200 || response.status === 201) {
        setShowLoader(false)
        setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, id]);
        dispatch(getUserTask(userId, accessToken))
        // dispatch(IncreaseUserPoints(taskPoints))
        // dispatch(IncreaseCurrentPoint(taskPoints))
        dispatch(updateLifeTimePoints(taskPoints));
        dispatch(updateTotalKlinkersPoints(taskPoints));

        await checkAndUpdateLeague(userId, currentPoint, dispatch, accessToken, userLeague);
        await sleep(1000)
        setShowLoader(false);
        setClaimLoading(true);
        setSuccess(true);
        setSubtext1("Your points have been successfully redeemed ");

      }

    } catch (error) {
      console.error("Error completing the task:", error);
      // setShowLoader(false);
      setClaimLoading(true);
      setSuccess(true);
      setShowLoader(false);
    }
  }
}


export function getUserStats(userId, setloading, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      // console.log("user id not found");
      return;
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      setloading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/stats`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      const data = response.data.data;
      setloading(false)
      const useractivity = response.data.data.user
      // console.log("data of user ", response.data.data.user);
      dispatch(setUserStats(data));
      // dispatch(setUserStats(data.klinkLeaderboard));



    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle errors, e.g., show an error message
    }
  };
}

export function getCommunityStats(userId, setLoading, accessToken) {
  return async (dispatch) => {
    if (!userId || !accessToken) {
      return;
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/user/communityStats/${userId}`,
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = response.data.data;
      // console.log("data ===>", data);

      setLoading(false);
      dispatch(setUserStats(data));
      dispatch(setUserReferalCount(data.user.referralCount));

    } catch (error) {
      console.error("Error fetching community data:", error);
    }
  };
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function BuyUserMultitap(userId, accessToken, navigate, setResultPopUp, setSuccess, setModalLoadder, setOpenModal, setPopUpMessage, setTafficLight) {

  return async (dispatch) => {
    // console.log("userID", userId);
    const data = {
      userId: userId,
    };
    // console.log("user task id ", data );
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      setModalLoadder(true);
      setOpenModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyMultiTap`,
        data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))

      if (response) {
        const user = response.data.user
        // console.log("user", user);
        const multiTapValue = user.boosts.multiTap.currentValue;
        // console.log("multiTapValue", multiTapValue);

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        dispatch(setTapPoints(multiTapValue));
        dispatch(setNextBootsMultiTapLevel(user?.boosts?.multiTap));
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setOpenModal(false);
        setPopUpMessage(`You have successfully purchased Level ${multiTapValue} Multitap`)




        // await sleep(2000);
        // navigate("/")
      }

    } catch (error) {
      console.error("Error completing the task:", error?.response?.status);

      if (error?.response?.status == 409) {
        setOpenModal(false);
        setModalLoadder(false);
        setTafficLight(true);
      } else {
        setPopUpMessage('Something went wrong,Please try again...')
        setModalLoadder(false);
        setResultPopUp(true);
        setOpenModal(false);
        setSuccess(false);
      }

    }
  }
}

// export function emailVerification(
//   userId,
//   tronAddress,
//   accessToken
// ) {
//   return async (dispatch) => {
//     try {
//       const result = await generateUserKey();
//       const userKey = result.userKey;
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/v1/user/addTonWallet`,
//         { tonAddress: tronAddress, userId },  // Rename 'address' to 'tonAddress'
//         {
//           headers: {
//             sessionKey: userKey,
//             Authorization: `Bearer ${accessToken}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );
//       console.log("emailVerification called")

//       dispatch(setUserVerifiedStatus(true));

//     } catch (error) {
//       console.error("Email verification failed", error);
     
//     } finally {
    
//     }
//   };
// }

// api service
export function emailVerification(userId, tonAddress, accessToken) {
  return async (dispatch) => {
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/addTonWallet`,
        { tonAddress: tonAddress, userId },  // Rename 'address' to 'tonAddress'
        {
          headers: {
            sessionKey: userKey,
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log("emailVerification called");

      dispatch(setUserVerifiedStatus(true));
      dispatch(setTonWalletAddress(tonAddress));

    } catch (error) {
      console.error("Email verification failed", error);
      // Return a descriptive error message
      throw error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An unexpected error occurred during email verification.";
    }
  };
}


export function onBoardUser(userId, accessToken) {
  return async (dispatch) => {
    try {
      if (!userId || !accessToken) {
        // console.error("userId Not Found");
        return;
      }
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/isVisited`, { userId }, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      window.location.reload()
    } catch (error) {

      console.log(error);
    }
  }

}


export function BuyUserEnergyLimit(userId, accessToken, navigate, setResultPopUp, setSuccess, setModalLoadder, setOpenModal, setPopUpMessage, setTafficLight) {

  return async (dispatch) => {
    const data = {
      userId: userId,
    };
    try {
      setModalLoadder(true);
      setOpenModal(true);
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyEnergyLimit`,
        data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))

      if (response) {
        const user = response.data.user;
        const EnergyLimitValue = user.boosts.energyLimit.currentValue;
        const Energylevel = user.boosts.energyLimit.currentLevel;

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        dispatch(setNextBootsEnergyLimitLevel(user?.boosts?.energyLimit));
        setOpenModal(false);
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setPopUpMessage(`You have successfully purchased Level ${Energylevel} Energy Limit`)

      }




    } catch (error) {
      console.error("Error completing the task:", error);
      if (error?.response?.status == 409) {
        setOpenModal(false);
        setModalLoadder(false);
        setTafficLight(true);
      } else {
        setPopUpMessage('Something went wrong,Please try again...')
        setModalLoadder(false);
        setResultPopUp(true);
        setOpenModal(false);
        setSuccess(false);
      }
    }
  }
}





export function BuyUserRechargeSpeed(userId, accessToken, navigate, setResultPopUp, setSuccess, setModalLoadder, setOpenModal, setPopUpMessage, setTafficLight) {

  return async (dispatch) => {
    // console.log("userID", userId);
    const data = {
      userId: userId,
    };
    // console.log("user task id ", data );
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      setModalLoadder(true);
      setOpenModal(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/boots/buyRechargingSpeed`,
        data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      // console.log("userBuyMultitap", response?.data?.user?.points);
      // console.log("userBuyMultitap", response?.data?.user?.boosts?.multiTap?.currentValue);
      // dispatch(setUserPoint(response?.data?.user?.points))
      // dispatch(setCurrentPoint(response?.data?.user?.points));
      // dispatch(setTapPoints(response?.data?.user?.boosts?.multiTap?.currentValue));
      // dispatch(setUserData(response?.data?.user))

      if (response) {
        const user = response.data.user;
        const RechargeSpeedValue = user.boosts.rechargingSpeed.currentValue;

        dispatch(setUserPoint(user.points));
        dispatch(setCurrentPoint(user.points));
        // dispatch(setTapPoints(RechargeSpeedValue));
        dispatch(setNextBootsRechargingSpeedLevel(user?.boosts?.rechargingSpeed));
        // dispatch(setUserData(user));
        setOpenModal(false);
        setModalLoadder(false);
        setResultPopUp(true);
        setSuccess(true);
        setPopUpMessage(` You have successfully purchased Level ${RechargeSpeedValue} Recharging Speed`)

      }

      // console.log("RechargeSpeed", response);




    } catch (error) {
      console.error("Error completing the task:", error);
      if (error?.response?.status == 409) {
        setOpenModal(false);
        setModalLoadder(false);
        setTafficLight(true);
      } else {
        setPopUpMessage('Something went wrong,Please try again...')
        setModalLoadder(false);
        setResultPopUp(true);
        setOpenModal(false);
        setSuccess(false);
      }

    }
  }
}


export function getKlinkRegistrationInfo(userId, setClaim, setTaskKlinkId, setKlinkTask, accessToken, setClaimLoading) {
  return async (dispatch) => {
    try {
      setClaimLoading(true);
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/task/getUserRegesterTasks/${userId}`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      setKlinkTask(response?.data?.tasks?.task);
      setTaskKlinkId(response?.data?.tasks?.task?._id);
      setClaim(response?.data?.tasks?.task?.completed);
      setClaimLoading(false);
    } catch (error) {
      console.log("Error While geting task data", error)
      setClaimLoading(false);
    }
  }
}

export function claimKlinkRegistrationPointInfo(userId, taskId, accessToken, setClaimLoading) {
  return async (dispatch) => {
    try {
      setClaimLoading(true);
      if (!taskId || !accessToken) {
        // console.log("TaskId not Found");
        return;
      }
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/task/claimRegBonum/${userId}`, { taskId }, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );
      // console.log("response==>", response);
      // console.log("currentPoints==>", currentPoints+taskPoints);
      // console.log("userLeague==>", userLeague);
      // await checkAndUpdateLeague(userId, currentPoints+taskPoints, dispatch, accessToken, userLeague); 

      window.location.reload();

    } catch (error) {
      setClaimLoading(false);
      console.log(error);
    }
  }
}


export function getUserTonTickets(userId, accessToken) {
  return async (dispatch) => {
    if (!userId) {
      // console.log("user id not found");
      return;
    }
    try {
      const result = await generateUserKey();
      const userKey = result.userKey;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/allTickets`, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );


      const data = response.data;
      // if(JSON.parse(localStorage.getItem('tonTicket'))?.length===data?.length) {
      //   return
      // };
      dispatch(setUserTonTickets(data));
      // localStorage.setItem("tonTicket",JSON.stringify(data));

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
}

export function BuyUserTonTickets(userId, id, accessToken, navigate, SetCongratsSuccessPopup, SetTicketLimitExceed, setSuccess, setModalLoadder, setOpenPreTonConfirmModal, setPopupMessage, setSubtext) {
  return async (dispatch) => {
    const data = {
      ticketID: id,
      userTgId: userId,
    };



    try {
      setOpenPreTonConfirmModal(true);
      setModalLoadder(true);
      const res = await generateUserKey();
      const userKey = res.userKey;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/tickets/buyTickets`, data, {
        headers: {
          sessionKey: userKey,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
      );

      // Check if response exists and the status is 201

      const result = response.data;
      const user = result?.user;
      dispatch(reduceCurrentPoint(result?.ticketHistory?.ticketDetails?.ticketCost))
      dispatch(reduceUserPoints(result?.ticketHistory?.ticketDetails?.ticketCost))
      //  dispatch(getUserStats());
      // dispatch(setUserPoint(user?.points));
      // dispatch(setCurrentPoint(user?.points));



      setOpenPreTonConfirmModal(false);
      setModalLoadder(false);
      SetCongratsSuccessPopup(true);
      // console.log("numberOfTickets", result?.ticketHistory?.ticketDetails?.numberOfTickets);

      dispatch(updateNumberOfTickets(result?.ticketHistory?.ticketDetails?.numberOfTickets));
      // setPopupMessage(response?.data?.message)
      setSuccess(true);
      setSubtext(`You have claimed your ${result?.ticketHistory?.ticketDetails?.numberOfTickets} tickets`)


    } catch (error) {
      // console.log("error", error?.response?.data?.message)
      setOpenPreTonConfirmModal(false);
      setModalLoadder(false);
      setSuccess(false);
      if (error?.response?.data?.error) {
        setSubtext(error?.response?.data?.message)
        SetTicketLimitExceed(true);
      } else {
        setPopupMessage("Connect your TON account")
        setSubtext("Sign up and connect your TON wallet to start redeeming rewards directly to your account")
        SetCongratsSuccessPopup(true);
      }
    }
  }
}


export function genrateAuthToken(userId, setAccessToken) {
  return async (dispatch) => {
    try {
      if (!userId) {
        // console.error("userId not found");
        return;
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/token/${userId}`);
      dispatch(setAccessToken(response?.data?.token));
    } catch (err) {

    }
  }
}



// this code is reposnsible for handeling super boost logic

export function BuySuperBoost(userId) {
  console.log("BuySuperBoost called")
  return async (dispatch) => {
    try {
      if (!userId) {
        console.error("userId not provided");
        return;
      }

      // Send POST request to activate the super boost
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/boots/activate-superBoost/${userId}`);

      // Log the response for debugging purposes
      console.log("Super boost activated successfully:", response.data);

      // Optionally, you can dispatch an action to update the application state with the result
      // dispatch({ type: 'SUPER_BOOST_ACTIVATED', payload: response.data });

    } catch (error) {
      // Capture error using Sentry and log to the console
  
      console.error("Error activating super boost:", error);

      // Optionally, dispatch an action to handle the error in the UI
      // dispatch({ type: 'SUPER_BOOST_ACTIVATION_FAILED', payload: error });
    }
  };
}
