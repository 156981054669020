import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import pointReducer from './slices/pointSlice'
import miningInfoReducer from "./slices/miningInfoSlice"
import notEnoughPointReducer from './slices/notEnoughPointSlice'

const store = configureStore({

    reducer: {

        user: userReducer,
        point: pointReducer,
        miningInfo : miningInfoReducer,
        notEnoughPoint: notEnoughPointReducer,
    }

});

export default store;