import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  keyframes
} from "@mui/material";
import star_background from "../images/Star_background.svg"
import { styled } from "@mui/system";
import MyProgress from "../components/Progress";
import { useDispatch } from "react-redux";
import { setCurrentTokenCount } from '../store/slices/pointSlice'
import { SetBoostData, callTapApi, claimMiningRewards, getUserMiningInfo } from "../Apis";
import KlinkCoin from '../images/KlinkCoin.svg'
import { increaseUserTapCount, reduceCurrentEnergy, restoreEnergy, setCurrentEnergy, setUserTotalTaps } from "../store/slices/miningInfoSlice";
import ArrowRight from "../images/Arrowright.svg"
import grayRightArrow from '../images/grayRightArrow.svg'
import { useNavigate } from "react-router-dom";
import ref from '../images/Ref.svg'
import klinkIcon from '../images/totalpointsSmallIcon.svg'
import tap from '../images/pendingtaskicon.svg'
import booster from '../images/Booster.svg'
import { setBosterPopUp, setOpenSuperBoostPopup, setBoosterPopVisible } from "../store/slices/userSlice"
import sugarBoyTapping from "../images/sugarBoyTapping.svg"
import totalPointsImg from "../images/TotalPoibtsIMG.svg"
import boosterIMG from "../images/boosterIMG.png"
import whiteRightArrow from "../images/Arrow right.png"
import WalletIMG from "../images/WallletImg.png"



// import bronze from "../images/BronzeSmall.svg"
// import silver from '../images/SilverIcon.svg'; // Replace with the actual path to the silver image
// import gold from '../images/Gold.svg'; // Replace with the actual path to the gold image
// import Platinum from '../images/Platinum-smallIMG.svg'
// import diamond from '../images/Diamand-smallImg.svg'; // Replace with the actual path to the diamond image


import UserRegisterStatus from "../components/UserRegisterStatus";
import KlinkToken from '../images/Klink-Coin.svg'
// import KlinkBubbel from "../images/KlinkBubbel.svg"
// import CountUp from 'react-countup';
import pointsSvg1 from "../images/Points_1.svg"
import pointsSvg2 from "../images/Points_2.svg"
import pointsSvg3 from "../images/Points_3.svg"
import pointsSvg4 from "../images/Points_4.svg"
import pointsSvg5 from "../images/Points_5.svg"
import { FunctionCommas } from "../utils/commasFun";
import { CountUp } from 'countup.js';
import { useSpring, animated } from '@react-spring/web';
import {
  ImpactOccurredFunction,
  NotificationOccurredFunction,
  useHapticFeedback,
} from "@vkruglikov/react-telegram-web-app";
import zIndex from "@mui/material/styles/zIndex";
import BoosterPopup from "../components/Modals/Booster/Booster";
import TokenPopup from "../components/Modals/TicketsModal/Tickets";
import TapPointsPopup from "../components/Modals/TapPointsPopup";
import NotEnoughPointPopup from "../components/Modals/NotEnoughPoint";
import SuperBoostPupup from "../components/Modals/SuperBoostPupup"
import TappedOutPopup from "../components/Modals/TappedOutPopup";
import DailyRewardPop from "../components/Modals/DailyRewardPopup";
import DailyrewardwalletConnectPopup from "../components/Modals/DailyrewardwalletConnectPopup";
import MiningRewardPopup from "../components/Modals/MiningRewardClaimPopup";
import { ContactlessOutlined } from "@mui/icons-material";

import bronzeIMG from "../images/bronzeIMG.svg"
import silverIMG from "../images/silverIMG.png"
import goldIMG from "../images/goldIMG.png"
import platineum from "../images/platinuumIMG.svg"
import diamondIMG from "../images/dimondIMG.svg"
import SugarBoyIMG from "../images/SugarBoyIMG.svg"


import SuperBoostPopup from "../components/Modals/Booster/SuperBoostPopup";

const isDesktop = window.innerWidth > 1000;

const isDesktop1 = window.innerWidth < 1500;



const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 5;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
`;

const progressBarStyle = {
  position: "relative",
  width: '100%',
  height: 'auto',
  zIndex: 1, // Ensure the progress bar is on top
};


export default function CoinApp(props) {
  const [fontSizeAnimation, setFontSizeAnimation] = useState("");
  const [openDailyrewardwalletConnectPopup, setOpenDailyrewardwalletConnectPopup] = useState(false);
  const [openMinignRewadPopup, setpenMinignRewadPopup] = useState(false);
  const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();
  const [openTappedOutPopup, setOpenTappedOutPopup] = useState(false);
  const [userCurrentSkinID, setUserCurrentSkinID] = useState();
  const [isShaking, setIsShaking] = useState(false);
  const userMiningInfo = useSelector((state) => state?.miningInfo?.miningInfo)
  const userCurrentEnergy = useSelector((state) => state?.miningInfo?.miningInfo?.currentEnergy)
  // console.log("userCurrentEnergy", userCurrentEnergy);
  const [textPoints, setTextPoints] = useState([]);
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const currentPoints = useSelector((state) => state.point.currentPoint);
  const userPoint = useSelector((state) => state?.point?.userPoint);
  const totalTaps = useSelector((state) => state?.miningInfo?.totalTaps)
  const userData = useSelector((state) => state?.user?.userData);
  const userReferrals = useSelector((state) => state?.user?.userData?.referrals)
  const AirdropPoints = useSelector((state) => state?.user?.userminininfo?.AirdropPoints);
  const completedTaskCount = useSelector((state) => state?.user?.userminininfo?.completedTaskCount)
  const notEnoughPoint = useSelector((state) => state.notEnoughPoint.notEnoughPoint);
  const isRewardClaimed = useSelector((state) => state?.user?.isDailyRewardsClaim);
  const superBoostPopup = useSelector((state) => state?.user?.superBoostPopup);
  const dailyreward = useSelector((state) => state?.user?.dailyreward)
  const dailyTapCount = useSelector((state) => state?.user?.userData?.dailyTapCount);
  const isMiningActive = useSelector((state) => state?.user?.userminininfo?.isMiningActive)
  // const UserMaxEngergy = useSelector((state) => state?.user?.userData?.boosts?.energyLimit?.currentValue);
  const [shrinkImage, setShrinkImage] = useState(false);
  const accessToken = useSelector((state) => state?.user?.accessToken);
  const isMiningRewardAvailable = useSelector((state) => state?.user?.userminininfo?.isMiningRewardAvailable)

  // const [coords, setCoords] = useState({ x: 4, y: 4 });
  const [initialUserPoint, setInitialUserPoint] = useState(currentPoints);
  const [accountConnected, setAccountConnected] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [isAnimating, setIsAnimating] = useState(false);
  const [openTapPointsPopup, setOpenTapPointsPopup] = useState(false);
  const [expandAnimation, setExpandAnimation] = useState("");
  const [openDailyRewardPop, setOpenDailyRewardPop] = useState(false);
  const navigate = useNavigate();
  const [isTapped, setIsTapped] = useState(false);
  const countUpRef = useRef(null);
  const countUpInstanceRef = useRef(null);
  const bronze = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/BronzeSmall.svg";
  const silver = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/SilverIcon.svg";
  const gold = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/GoldLeague.svg";
  const Platinum = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/Platinum-smallIMG.svg";
  const diamond = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/DiamondSmall.svg";
  const KlinkBubbel = "https://klink-assets.s3.eu-central-1.amazonaws.com/Klink-Tg-Bot/KlinkBubble.svg";

  const tronWalletAddress = useSelector((state) => state?.user?.tonWalletAddress);

  

  

  const openSuperBoostPopup = useSelector((state) => state?.user?.openSuperBoostPopup);
  const tonWalletAddress = useSelector((state) => state.user.tonWalletAddress);

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if the popup has been shown in the current app session
    const popupShown = localStorage.getItem('popupShown');
  
    if (!popupShown) {
      setShowPopup(true); // Show the popup
      localStorage.setItem('popupShown', 'true'); // Set flag to prevent re-showing in this session
    }
  }, []);



  const expand = keyframes`
   from, to { width: ${isDesktop1 ? "55vw" : "100vw"}; }
   20% { width: ${isDesktop1 ? "55vw" : "100vw"}; }
   50% { width: ${isDesktop1 ? "55vw" : "100vw"}; }
`;



  useEffect(() => {
    if (!isRewardClaimed && userData?.isUserVerified) {
      setTimeout(() => {
        setOpenDailyRewardPop(true);
      }, 2000);
    }
  }, [dailyreward]);

  useEffect(() => {
    if (!userData?.isUserVerified) {
      setTimeout(() => {
        setOpenDailyrewardwalletConnectPopup(true);
      }, 3000);
    }
  }, []);

  // useEffect(()=> {
  //   dispatch(getUserMiningInfo(userId, accessToken));
  // }, [isMiningRewardAvailable])

  // const handleClaimRewards = async () => {
  //   try {
  //     const rewards = await dispatch(claimMiningRewards(userId, accessToken));
  //     if (rewards) {
  //       setpenMinignRewadPopup(false); // Close popup after successful claim
  //       dispatch(getUserMiningInfo(userId, accessToken));
  //     }
  //   } catch (error) {
  //     console.error("Failed to claim rewards:", error);
  //   }
  // };


  const skewAnimation = (x, y) => `@keyframes skewAnimation { 0% { transform: perspective(1000px) rotateX(${y / 10}deg) rotateY(${x / 10}deg); } 100% { transform: perspective(1000px) rotateX(0) rotateY(0); } }`;

  // const { number } = useSpring({
  //   number: currentPoints,
  //   from: { number: currentPoints },
  //   // delay: 200,
  //   config: { mass: 1,tension: 20, friction: 10},

  // });


  useEffect(() => {
    if (countUpRef.current) {
      // Clear any previous CountUp instance
      countUpInstanceRef.current = null; // Remove the previous instance reference

      const countUp = new CountUp(countUpRef.current, currentPoints, {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        duration: 1,
        startVal: parseInt(countUpRef.current.innerText.replace(/,/g, ''), 10) || currentPoints,
        formattingFn: FunctionCommas
      });

      countUp.start();
      countUpInstanceRef.current = countUp; // Save the instance reference
    }
  }, [currentPoints]);


  const leagueImages = {
    Bronze: bronzeIMG,
    Silver: silverIMG,
    Gold: goldIMG,
    Platinum: platineum,
    Diamond: diamondIMG,
  };
 



  // const handleClick = async (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   if (userCurrentEnergy <= 0) return;

  //   // Dispatch actions to update state
  //   dispatch(increaseUserTapCount());
  //   dispatch(reduceCurrentEnergy(userData?.boosts?.rechargingSpeed?.currentValue));
  //   dispatch(setCurrentTokenCount(userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints : 1));

  //   const rect = e.currentTarget.getBoundingClientRect();
  //   const clickX = e.clientX - rect.left;  // X position of click relative to the left edge of the coin
  //   const centerX = rect.width / 2;
  //   const x = ((e.clientX - rect.left) / rect.width) * 100; // Normalize x coordinate
  //   const y = ((e.clientY - rect.top) / rect.height) * 100; // Normalize y coordinate

  //   // Determine if click is on left or right side
  //   const isLeftSide = clickX <= centerX;

  //   setCoords({ x, y });
  //   setIsAnimating(true);

  //   // Vibration API
  //   if (navigator.vibrate) {
  //     navigator.vibrate(50);
  //   } else {
  //     console.log('Vibration API not supported');
  //   }

  //   const touchEvent = e.touches && e.touches.length > 0;
  //   const touchX = touchEvent ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
  //   const touchY = touchEvent ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

  //   // Update text points
  //   setTextPoints([...textPoints, { x: touchX, y: touchY, id: Date.now() }]);
  //   impactOccurred("heavy");

  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   timeoutRef.current = setTimeout(() => {
  //     dispatch(callTapApi(currentPoints, userPoint, userId, userMiningInfo?.tapPoints, totalTaps, userMiningInfo?.currentEnergy, accessToken));
  //   }, 495);
  // };


  const removePoint = (id) => {
    setTextPoints(prevPoints => prevPoints.filter(point => point.id !== id));
  };

  // const handleClick = (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   const multitapValue = userData?.boosts?.multiTap?.currentValue;
  //   const currentEnergy = userMiningInfo.currentEnergy;
  //   if (currentEnergy < multitapValue) {
  //     return;
  //   }
  //   if (currentEnergy <= 0 ) {
  //       return;
  //   }

  //   dispatch(increaseUserTapCount());
  //   dispatch(reduceCurrentEnergy(userData?.boosts?.multiTap?.currentValue));
  //   dispatch(setCurrentTokenCount(userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints : 1));

  //   const card = e.currentTarget;
  //   const rect = card.getBoundingClientRect();

  //   let x;
  //   let y;

  //   if (e.touches) {
  //     const touch = e.touches[0];
  //     x = touch.clientX - rect.left - rect.width / 2;
  //     y = touch.clientY - rect.top - rect.height / 2;
  //   } else {
  //     x = e.clientX - rect.left - rect.width / 2;
  //     y = e.clientY - rect.top - rect.height / 2;
  //   }

  //   // card.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;

  //   // setTimeout(() => {
  //   //   card.style.transform = '';
  //   // }, 100);

  //   setIsAnimating(true);
  //   setExpandAnimation(`${expand} 0.5s ease`);

  //   if (navigator.vibrate) {
  //     navigator.vibrate(50);
  //   } else {
  //     console.log('Vibration API not supported');
  //   }

  //   if (e.touches) {
  //     const touch = e.touches[0];
  //     setTextPoints(prevPoints => [...prevPoints, { id: Date.now(), x: touch.pageX - rect.left, y: touch.pageY - rect.top }]);
  //   } else {
  //     setTextPoints(prevPoints => [...prevPoints, { id: Date.now(), x: e.pageX - rect.left, y: e.pageY - rect.top }]);
  //   }

  //   impactOccurred("medium");

  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  //   setTimeout(() => {
  //     setExpandAnimation("");
  //   }, 50);

  //   timeoutRef.current = setTimeout(() => {
  //     dispatch(callTapApi(currentPoints, userPoint, userId, userMiningInfo?.tapPoints, totalTaps, userMiningInfo?.currentEnergy, accessToken));
  //   }, 495);
  // };

  const [opnePopup1, setOpenPopup1] = useState(false);
  const [opnePopup2, setOpenPopup2] = useState(false);

  const bosterPopUp = useSelector((state) => state?.user?.bosterPopUp)

  if (bosterPopUp && !opnePopup1) {
    setOpenPopup1(true);
  }
  if (opnePopup1 === false) {
    dispatch(setBosterPopUp(false))
  }



  const boostMultiplier = useSelector((state) => state?.user?.boostMultiplier)
  const isLimitReached = useSelector((state) => state?.user?.isLimitReached)
  const isSuperBoostActive = useSelector((state) => state?.user?.isSuperBoostActive)
  const usageCount = useSelector((state) => state?.user?.usageCount)




  const handleClick = (e) => {
    console.log("boostMultiplier.....,", boostMultiplier);

    dispatch(SetBoostData(userId, accessToken,));


    if (!isLimitReached && isSuperBoostActive && usageCount <= 3) {
      var multiplierValue = boostMultiplier;
    }

    e.stopPropagation();
    e.preventDefault();

    const multitapValue = userData?.boosts?.multiTap?.currentValue;
    const multiplier = multiplierValue && multiplierValue > 1 ? multiplierValue : 1; // Use multiplier if boost is active, otherwise default to 1
    const currentEnergy = userMiningInfo.currentEnergy;

    if (currentEnergy < multitapValue || currentEnergy <= 0) {
      return;
    }

    if (dailyTapCount >= 2500) {
      setOpenTappedOutPopup(true);

      dispatch(callTapApi(
        currentPoints * multiplier, // Apply multiplier to the points
        userPoint,
        userId,
        userMiningInfo?.tapPoints * multiplier, // Apply multiplier to the tap points
        totalTaps,
        userMiningInfo?.currentEnergy,
        accessToken,
        userData.league,
        setOpenTappedOutPopup
      ));

      return; // Ensure no further execution
    }

    dispatch(increaseUserTapCount());
    dispatch(reduceCurrentEnergy(userData?.boosts?.multiTap?.currentValue));
    dispatch(setCurrentTokenCount(userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints * multiplier : 1)); // Apply multiplier to token count

    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();

    let x;
    let y;

    if (e.touches) {
      const touch = e.touches[0];
      x = touch.clientX - rect.left - rect.width / 2;
      y = touch.clientY - rect.top - rect.height / 2;
    } else {
      x = e.clientX - rect.left - rect.width / 2;
      y = e.clientY - rect.top - rect.height / 2;
    }

    const pointsEarned = userMiningInfo?.tapPoints ? userMiningInfo?.tapPoints * multiplier : 1; // Calculate the points with multiplier

    // Add the pointsEarned to the textPoints state, so the bounce image shows the correct value
    if (e.touches) {
      const touch = e.touches[0];
      setTextPoints(prevPoints => [
        ...prevPoints,
        {
          id: Date.now(),
          x: touch.pageX - rect.left,
          y: touch.pageY - rect.top,
          points: pointsEarned // Show the correct points in bounce
        }
      ]);
    } else {
      setTextPoints(prevPoints => [
        ...prevPoints,
        {
          id: Date.now(),
          x: e.pageX - rect.left,
          y: e.pageY - rect.top,
          points: pointsEarned // Show the correct points in bounce
        }
      ]);
    }

    setIsTapped(true);
    setTimeout(() => setIsTapped(false), 150);

    if (navigator.vibrate) {
      navigator.vibrate(50);
    } else {
      console.log('Vibration API not supported');
    }

    impactOccurred("medium");

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setTimeout(() => {
      setExpandAnimation("");
      setShrinkImage(false); // Reset image size after click
    }, 50);

    timeoutRef.current = setTimeout(() => {
      dispatch(callTapApi(
        currentPoints * multiplier, // Apply multiplier to the points
        userPoint,
        userId,
        userMiningInfo?.tapPoints * multiplier, // Apply multiplier to the tap points
        totalTaps,
        userMiningInfo?.currentEnergy,
        accessToken,
        userData.league,
        setOpenTappedOutPopup
      ));
    }, 495);
  };



  // console.log("userMiningInfo ===========>", userCurrentEnergy);
  const AirdropRedirect = () => {
    return navigate("/airdrop")
  }

  const handelRedirect = () => {
    return navigate("/user-level-details")
  }

  const getStyle = () => {
    const baseStyle = {
      position: "absolute",
      top: "73vh",
      color: "aliceblue",
      animation: fontSizeAnimation,
      fontFamily: "Inter",
      fontSize: isDesktop ? "18px" : "13px",
      // width: "92%", // Default width value
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "5px",
      zIndex: 1,
      filter: "none",



      "@media (minWidth: 769px)": {
        left: "25vw", // Default left value for larger screens
      },

      // Media query for screens 768px or less
      "@media (maxWidth: 768px)": {
        width: "92vw", // Adjust width for smaller screens
        // Additional styles specific to smaller screens
      },
    };

    return baseStyle;
  };


  // following line 

  const onClose = () => {
    dispatch(setOpenSuperBoostPopup(false));
    dispatch(setBoosterPopVisible(true));
  }








  // const removePoint = (id) => {
  //   const result = textPoints.filter((point) => point?.id != point?.id);
  //   // setTextPoints(result);
  // };
  const scaleUp = keyframes`
      0% {
        transform: scale(0);
    }
   
    50% {
        transform: scale(0.8);
    }

    100%{
     transform: scale(1);
    }
`;

  const fromRight = keyframes`
 0% {
        transform: translateX(-70%);
      opacity: 0;
    }
   
   70%{
    transform: translateX(5%);
    opacity: 0.8;
   }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;
  const fromLeft = keyframes`
  0% {
        transform: translateX(70%);
        opacity: 0;
    }
   

    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

  const FadeContainer = styled(Box)`
 animation: fadeIn 1s ease-in-out;
 @keyframes fadeIn {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
`;

  const animation = `

  @keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
  const animation2 = `
   @keyframes rotateAnti {
  0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
  }
`;

  const StyledImage = styled(Box)`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius:100%;
  overflow:hidden;
 animation: rotate 25s linear infinite;
  ${animation}
`;
  const StyledImage2 = styled(Box)`
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
 animation: rotateAnti 20s linear infinite;
  ${animation2}
`;
  // const skewAnimation = (x, y) => keyframes`
  // 0% {
  //   transform: perspective(600px) rotateX(0) rotateY(0);
  // }
  // 100% {
  //   transform: perspective(600px) rotateY(${coords?.x}deg) rotateY(${coords?.y}deg);
  // }
  // `;


  const handelWalletClick = ()=>{
    // if(tronWalletAddress){
    // navigate("/WalletConnected");
    // }
    // else{
    //   navigate("/register")
    // }
    navigate("/WalletConnected");
  }




  return (
    <>
      {opnePopup1 && (
        <BoosterPopup setOpenPopup1={setOpenPopup1} />
      )}

      {opnePopup2 && (
        <TokenPopup setOpenPopup2={setOpenPopup2} />
      )}

      {openTapPointsPopup && (
        <TapPointsPopup setOpenTapPointsPopup={setOpenTapPointsPopup} />
      )}
      {notEnoughPoint && (
        <NotEnoughPointPopup />
      )}

{superBoostPopup && (
        <SuperBoostPupup />
      )}

      {/* {openTappedOutPopup && (
        <TappedOutPopup setOpenTappedOutPopup={setOpenTappedOutPopup} />
      )} */}
      {/* {openDailyRewardPop && userData?.isUserVerified && (
        <DailyRewardPop setOpenDailyRewardPop={setOpenDailyRewardPop} />
      )} */}
      {openDailyRewardPop && userData?.isUserVerified && showPopup &&(
        <DailyRewardPop setOpenDailyRewardPop={setOpenDailyRewardPop} />
      )}

      {/* {openDailyrewardwalletConnectPopup && (
        <DailyrewardwalletConnectPopup setOpenDailyrewardwalletConnectPopup={setOpenDailyrewardwalletConnectPopup} />
      )} */}

{openDailyrewardwalletConnectPopup && showPopup &&(
        <DailyrewardwalletConnectPopup setOpenDailyrewardwalletConnectPopup={setOpenDailyrewardwalletConnectPopup} />
      )}

      {isMiningRewardAvailable && userData?.isUserVerified && setpenMinignRewadPopup && (
        <MiningRewardPopup
          setpenMinignRewadPopup={setpenMinignRewadPopup}

        />
      )}





      {openSuperBoostPopup && (
        <SuperBoostPopup onClose={onClose} />
      )}

      


      {/* <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: "1.06rem" }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between", width: '90vw' }} >

          <Box sx={{ display: 'flex', width: '19.02rem', height: '2.12rem', gap: '0.5rem' }}>

          
            <Box
              sx={{
                display: "flex",
                width: "5.5rem",
                height: "2.12rem",
                justifyContent: 'space-between',
                alignItems: "center",
                zIndex: 10000000,
                boxSizing: 'border-box',
                bgcolor: '#443961E5',
                borderRadius: '6px',
                paddingTop: '0.563rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.563rem'
              }}
              onClick={AirdropRedirect}
            >
              <img src={klinkIcon} width={16} height={16} style={{ objectFit: "cover", marginRight: "5px" }} alt="league_image" />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "10px",
                  lineHeight: "12.1px",
                  display: "flex",
                  justifyContent: "center",
                  lineHeight: "23.4px",
                  color: '#FFFFFF',

                }}
              >
                {FunctionCommas(KlinkAirdropPoints)}
              </Typography>

              <img src={grayRightArrow} alt="arrow_image" width={16} height={16} style={{}} />
            </Box>

          
            <Box
              sx={{
                display: "flex",
                width: "5.5rem",
                height: "2.12rem",
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                alignItems: "center",
                gap: "0px",
                zIndex: 10000000,
                bgcolor: '#443961E5',
                borderRadius: '6px',
                paddingTop: '0.563rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.563rem'

              }}
              onClick={handelRedirect}
            >
              <img src={leagueImages[userData.league] || bronze} width={16} height={16} style={{ objectFit: "cover", marginRight: "5px" }} alt="league_image" />
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "10px",
                  lineHeight: "12.1px",
                  display: "flex",
                  justifyContent: "center",
                  lineHeight: "23.4px",
                  color: '#FFFFFF'

                }}
              >
                {userData?.league}
              </Typography>

              <img src={grayRightArrow} alt="arrow_image" width={16} height={16} style={{}} />
            </Box>


  
            <Box
              sx={{
                display: "flex",
                width: "2.966rem",
                height: "2.12rem",
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                alignItems: "center",
                gap: "0px",
                zIndex: 10000000,
                bgcolor: '#443961E5',
                borderRadius: '6px',
                paddingTop: '0.563rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.563rem'

              }}
           
            >
              <img src={tap} width={13} height={16} style={{ objectFit: "cover", marginRight: "3px" }} alt="league_image" />

              <Typography
                sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '10px', lineHeight: "12.2px", color: "#FFFFFF" }}
              >{completedTaskCount}</Typography>

            </Box>


          
            <Box
              sx={{
                display: "flex",
                width: "2.966rem",
                height: "2.12rem",
                justifyContent: 'space-between',
                boxSizing: 'border-box',
                alignItems: "center",
                gap: "0px",
                zIndex: 10000000,
                bgcolor: '#443961E5',
                borderRadius: '6px',
                paddingTop: '0.563rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.563rem'

              }}
            
            >
              <img src={ref} width={14} height={16} style={{ objectFit: "cover", marginRight: "3px" }} alt="league_image" />

              <Typography
                sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '10px', lineHeight: "12.2px", color: "#FFFFFF" }}
              >{userReferrals}</Typography>

            </Box>



          </Box>



          <UserRegisterStatus tap={true} />
        </Box>
      </Box> */}

      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",

          // overflow: 'hidden' 

        }}
      >

        <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%", }} />

        <Box
          sx={{
            position: "absolute",
            top: "90px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "326px",
            height: "114px",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: "column",

          }}
        >



          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "25rem", height: "4.93rem",  }} onClick={() => setOpenTapPointsPopup(true)}>

            {/* <Typography sx={{ color: "#FFFFFF", fontFamily: 'Inter', fontWeight: 400, fontSize: '0.87rem', lineHeight: '1rem', marginBottom: '0.75rem' }}>Tap Points</Typography> */}


            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                width: "15.93rem",
                height: "3.12rem",
              }}
            >

              <img src={totalPointsImg} width={38} height={38} style={{
                animation: ` ${scaleUp} 0.2s cubic-bezier(0.25, 0.8, 0.25, 1)`
              }} />

              {/* this is count referece */}

              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: "42px",
                  fontWeight: 700,
                  fontFamily: "Inter",
                  lineHeight: "50.4px",
                  animation: `fromLeft 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                }}
              >
                <div ref={countUpRef} />
              </Typography>

              {/* <img src={ArrowRight} alt="arrow_image" width={32} height={32} /> */}

            </Box>

            <Box
              sx={{
                height: "38px",
                width: "38px",
                bgcolor: "#AA4545",
                borderRadius: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Adding shadow at the bottom
                marginRight:"-30px",
                marginLeft:"20px"
              }}
              onClick={handelWalletClick}
            >
              <img
                src={WalletIMG}
                alt="your-alt-text"
                style={{ width: "50%", height: "50%" }}
              />
            </Box>

          </Box>

          {/* league box */}

          <Box
            sx={{
              display: "flex",
              width: "140px",
              height: "32px",
              justifyContent: 'space-between',
              boxSizing: 'border-box',
              alignItems: "center",
              gap: "0px",
              zIndex: 10000000,
              paddingTop: '0.563rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              paddingBottom: '0.563rem'

            }}
            onClick={handelRedirect}
          >
            <img src={leagueImages[userData.league]} width={24} height={24} style={{ objectFit: "cover", marginRight: "5px" }} alt="league_image" />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 700,
                fontSize: "18px",
                lineHeight: "23.4px",
                display: "flex",
                justifyContent: "center",
                color: '#FFFFFF'

              }}
            >
              {userData?.league}
            </Typography>

            <img src={whiteRightArrow} alt="arrow_image" width={32} height={32} style={{}} />
          </Box>



        </Box>



        <div style={{ position: "relative", top: "30vh" }}>
          <Box
            className="tap-area"
            sx={{
              position: "relative",
              width: '100%', height: 'auto',

            }}
            onTouchStart={handleClick}

          >

            <Box
              component="img"
              src={sugarBoyTapping}
              alt="Coin Logo"
              sx={{
                width: shrinkImage ? "255px" : "270px", // Shrink size when clicked
                height: shrinkImage ? "255px" : "270px",
                transition: 'width 0.3s ease, height 0.3s ease', // Smooth transition
                animation: `${scaleUp} 0.2s cubic-bezier(0.50, 0.8, 0.25, 1)`,
                animation: `${isAnimating ? skewAnimation(coords.x, coords.y) : 'none'} 100ms`,
                // transition: 'transform 0.15s ease-in-out',
                transform: isTapped ? 'scale(0.95)' : 'scale(1)',
                // animation: expandAnimation,
              }}
            />
            <Box
              sx={{
                position: "absolute",
                background: "#000",
                width: "250px",
                height: "250px",
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
                background: "radial-gradient(circle, #E8B5FF, #FFD5C9);",
                borderRadius: "100%",
                zIndex: -2,
                filter: "blur(80.6px)",
                pointerEvents: "none",
                transition: "none",
                willChange: "transform, opacity",
              }}
            ></Box>
            {textPoints.map((point) => (
              <Box
                key={point.id}
                sx={{
                  position: "absolute",
                  left: point.x - 10,
                  top: point.y - 20,
                  animation: `${floatUpAndFadeOut} 2.5s ease forwards`,
                  fontSize: "35px",
                  fontFamily: "Inter",
                  color: "#FBC44D",
                }}
                onAnimationEnd={() => setTimeout(() => removePoint(point.id), 1000)}
              >
                {(userMiningInfo?.tapPoints === 1 && !isSuperBoostActive) && <img src={pointsSvg1} alt="svg_1" />}
                {(userMiningInfo?.tapPoints === 2 && !isSuperBoostActive) && <img src={pointsSvg2} alt="svg_2" />}
                {(userMiningInfo?.tapPoints === 3 && !isSuperBoostActive) && <img src={pointsSvg3} alt="svg_3" />}
                {(userMiningInfo?.tapPoints === 4 && !isSuperBoostActive) && <img src={pointsSvg4} alt="svg_4" />}
                {(userMiningInfo?.tapPoints === 5 && !isSuperBoostActive) && <img src={pointsSvg5} alt="svg_5" />}
                {isSuperBoostActive && <img src={SugarBoyIMG} alt="superBoostCoin" /> }
              </Box>
            ))}
          </Box>
        </div>
        {/* <div style={getStyle()} className="mining-info-container">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

            }}
          >
            <img
              style={{ verticalAlign: "bottom", marginRight: "5px" }}
              width={"20px"}
              height={"20px"}
              src={KlinkToken}
              alt="flash-on"
            />
            <div style={{ marginRight: "auto" }}>
             <span 
             style={{
              fontFamily: "Inter",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "21.78px",
             }}
             > {FunctionCommas(userCurrentEnergy)}</span> <span
             
             style={{
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "14.52px",
             }}
             >/ {FunctionCommas(userData?.boosts?.energyLimit?.currentValue)}</span>
            </div>
          </Box>
          <MyProgress value={userCurrentEnergy} max={userData?.boosts?.energyLimit?.currentValue}  />
        </div> */}
        <div style={getStyle()} className="mining-info-container">
          {/* main box flex display */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center", // Ensure vertical alignment
              justifyContent: "space-between", // Spread content to left, center, and right
              // border: "2px solid black",
            }}
          >
            {/* Empty space to align the first box in the center */}
            <Box sx={{ minWidth: "92px", visibility: "hidden" }}></Box>

            {/* First box in the middle */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "92px",
                height: "22px",
                flexWrap: "nowrap",
                // border: "2px solid red",
                marginTop: "15px"
              }}
            >
              {/* coin image of 1000/1000 */}
              <img
                style={{ verticalAlign: "bottom", marginRight: "5px" }}
                width={"16px"}
                height={"16px"}
                src={KlinkToken}
                alt="flash-on"
              />
              <div style={{ height: "22px", whiteSpace: "nowrap" }}>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "21.78px",
                    marginRight: "2px",
                  }}
                >
                  {FunctionCommas(userCurrentEnergy)}
                </span>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "14.52px",
                  }}
                >
                  / {FunctionCommas(userData?.boosts?.energyLimit?.currentValue)}
                </span>
              </div>
            </Box>

            {/* Second box with boost and Tokens, aligned to the right */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "34px",
              }}
            >
              {/* boosters div */}
              <div
                style={{
                  display: "flex",
                  border: "1px solid #FBC44D",
                  width: "80px",
                  height: "34px",
                  borderRadius: "6px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
                onClick={() => setOpenPopup1(true)}
              >
                <img width={"15.6px"} height={"16px"} src={boosterIMG} />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "10px",
                    lineHeight: "12.1px",
                    color: "#FFFFFF",
                  }}
                >
                  Boosters
                </Typography>
              </div>
            </Box>
          </Box>

          <MyProgress value={userCurrentEnergy} max={userData?.boosts?.energyLimit?.currentValue} />
        </div>
      </Box>

    </>
  );
}
