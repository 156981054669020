// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Tooltip from '@mui/material/Tooltip';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import TronWeb from 'tronweb/dist/TronWeb';
// import { Box, Button, Grid, IconButton, keyframes, TextField, Typography, Snackbar } from "@mui/material";
// import connectWallet1 from "../images/connectWallet1.svg"
// import connectWallet2 from "../images/ConnectWallet2.png"
// import arrowRight from "../images/rightArrow.png"
// import { setTonWalletAddress } from "../store/slices/userSlice"



// const WalletConnected = () => {

//     const [copySuccess, setCopySuccess] = useState(false);
//     const [openSnackbar, setOpenSnackbar] = useState(false);
//     const tronWalletAddress = useSelector((state) => state?.user?.tonWalletAddress);
//     // const tronWalletAddress = 'TT4RMPfHZiz1ZiZXszEHuueAFc5yEu8QW7';
//     const [walletBalance, setWalletBalance] = useState(null);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
    
//     const tronWeb = new TronWeb({
//         fullHost: 'https://api.trongrid.io', // Mainnet
//     });
//     // Fetch wallet balance
//     useEffect(() => {
//         const fetchWalletBalance = async () => {
//             try {
//                 if (tronWalletAddress) {
//                     const balance = await tronWeb.trx.getBalance(tronWalletAddress);
//                     setWalletBalance(tronWeb.fromSun(balance)); // Converts Sun (smallest TRX unit) to TRX
//                 }
//             } catch (error) {
//                 console.error("Failed to fetch wallet balance:", error);
//             }
//         };

//         fetchWalletBalance();
//     }, [tronWalletAddress]);

//     const handleCopy = () => {
//         if (tronWalletAddress) {
//             // Fallback for all contexts
//             const textArea = document.createElement("textarea");
//             textArea.value = tronWalletAddress;
//             document.body.appendChild(textArea);
//             textArea.focus();
//             textArea.select();

//             try {
//                 document.execCommand('copy');
//                 setCopySuccess(true);
//                 setOpenSnackbar(true);
//                 setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
//             } catch (err) {
//                 console.error("Fallback: Oops, unable to copy", err);
//             }

//             document.body.removeChild(textArea);
//         }
//     };

//     const handleCloseSnackbar = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpenSnackbar(false);
//     };



//     const slideUp = keyframes`
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;



//     const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;
//     return (
//         <div
//             style={{
//                 // background:"#fff",
//                 width: "100%",
//                 height: "82vh",
//                 overflowY: "scroll",
//                 overflowX: "hidden",
//                 boxSizing: "border-box",
//                 // paddingBottom: "10px",
//             }}
//         >
//             <Box
//                 sx={{
//                     paddingRight: "20px",
//                     paddingLeft: "20px",
//                     paddingTop: "40px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     gap: "65px",
//                     // overflowY: "scroll",

//                 }}
//             // className={`scroll-transition ${isScrolled ? 'scroll-up' : ''}`}

//             >
//                 {/* register info box */}
//                 <Box
//                     sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignContent: "center",
//                         gap: "25px",
//                         flexDirection: "column"
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             display: "flex",
//                             justifyContent: "start",
//                             gap: "12px",
//                             flexDirection: "column"
//                         }}
//                     >
//                         <Typography
//                             component={"h1"}
//                             sx={{
//                                 fontFamily: "inter",
//                                 fontWeight: 800,
//                                 fontSize: "24px",
//                                 lineHeight: "28.8px",
//                                 color: "#fff",
//                                 textAlign: "center", // Center the entire text
//                             }}
//                         >
//                             Your connected TRON Wallet
//                             <br />
//                             <span
//                                 style={{
//                                     display: "inline-block", // Ensures the span behaves like inline text
//                                     width: "100%", // Takes up full width to center
//                                     textAlign: "center", // Centers the text within the span
//                                 }}
//                             >
//                                 address and balance.
//                             </span>
//                         </Typography>

//                         <Box sx={{ width: "calc(100% - 30px)", height: "70px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: "15px", marginRight: "15px" }}>
//                             {/* First box with 64x64 size and image filling the box */}
//                             <Box sx={{ width: "64px", height: "64px", marginRight: "40px" }}>
//                                 <img src={connectWallet1} alt="First Box" style={{ width: "100%", height: "100%" }} />
//                             </Box>

//                             {/* Middle box with 64x12 size, containing 3 images of 12x12 */}
//                             <Box sx={{ width: "64px", height: "12px", display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "40px" }}>
//                                 <img src={arrowRight} alt="Image 1" style={{ width: "12px", height: "12px" }} />
//                                 <img src={arrowRight} alt="Image 2" style={{ width: "12px", height: "12px" }} />
//                                 <img src={arrowRight} alt="Image 3" style={{ width: "12px", height: "12px" }} />
//                             </Box>

//                             {/* Last box with 64x64 size and image filling the box */}
//                             <Box sx={{ width: "64px", height: "64px" }}>
//                                 <img src={connectWallet2} alt="Third Box" style={{ width: "100%", height: "100%" }} />
//                             </Box>
//                         </Box>
//                     </Box>

//                     <Box
//                         sx={{
//                             marginTop: "60px",
//                             border: '1px solid #FFFFFF',
//                             bgcolor: "#802020",
//                             color: "#FFFFFF",
//                             borderRadius: '8px',
//                             padding: '16px',
//                             display: "flex",
//                             flexDirection: "column",
//                             justifyContent: "center",
//                             height: "100px",
//                         }}
//                     >
//                         {/* First Row - Wallet Address */}
//                         <Grid
//                             container
//                             sx={{
//                                 justifyContent: "center", // Center horizontally
//                                 marginBottom: "16px", // Adds space below the first row
//                             }}
//                         >
//                             <Grid
//                                 item
//                                 xs={6}
//                                 sx={{
//                                     textAlign: "center",
//                                     paddingRight: '16px',
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body1"
//                                     sx={{
//                                         color: "#FFFFFF",
//                                         fontFamily: "inter",
//                                         fontWeight: 700,
//                                         fontSize: "14px",
//                                         lineHeight: "18.2px",
//                                     }}
//                                 >
//                                     Wallet Address:
//                                 </Typography>
//                             </Grid>
//                             <Grid
//                                 item
//                                 xs={6}
//                                 sx={{
//                                     textAlign: "center",
//                                     paddingLeft: '16px',
//                                     display: "flex",
//                                     alignItems: "center",
//                                     justifyContent: "center",
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body1"
//                                     sx={{
//                                         color: "#FFFFFF",
//                                         fontFamily: "inter",
//                                         fontWeight: 700,
//                                         fontSize: "14px",
//                                         lineHeight: "18.2px",
//                                     }}
//                                 >
//                                     {tronWalletAddress ? `${tronWalletAddress.slice(0, 6)}...${tronWalletAddress.slice(-4)}` : ""}
//                                 </Typography>
//                                 {tronWalletAddress && <Tooltip title={copySuccess ? "Copied!" : "Copy Address"} >
//                                     <Button
//                                         onClick={handleCopy}
//                                         sx={{ marginLeft: "8px", color: "#FFFFFF", minWidth: "auto", }}
//                                     >
//                                         <ContentCopyIcon />
//                                     </Button>
//                                 </Tooltip>}
//                             </Grid>
//                         </Grid>

//                         {/* Divider Line */}
//                         <Box
//                             sx={{
//                                 borderTop: '1px solid #FFFFFF4D', // Creates the horizontal line
//                                 width: '100%', // Make sure it takes full width
//                                 marginBottom: "16px", // Adds space below the divider
//                             }}
//                         />

//                         {/* Second Row - Wallet Balance */}
//                         <Grid
//                             container
//                             sx={{
//                                 justifyContent: "center",
//                             }}
//                         >
//                             <Grid
//                                 item
//                                 xs={6}
//                                 sx={{
//                                     textAlign: "center",
//                                     paddingRight: '16px',
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body1"
//                                     sx={{
//                                         color: "#FFFFFF",
//                                         fontFamily: "inter",
//                                         fontWeight: 700,
//                                         fontSize: "14px",
//                                         lineHeight: "18.2px",
//                                     }}
//                                 >
//                                     Wallet Balance:
//                                 </Typography>
//                             </Grid>
//                             <Grid
//                                 item
//                                 xs={6}
//                                 sx={{
//                                     textAlign: "center",
//                                     paddingLeft: '16px',
//                                 }}
//                             >
//                                 <Typography
//                                     variant="body1"
//                                     sx={{
//                                         color: "#FFFFFF",
//                                         fontFamily: "inter",
//                                         fontWeight: 700,
//                                         fontSize: "14px",
//                                         lineHeight: "18.2px",
//                                     }}
//                                 >
//                                     {walletBalance}
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </Box>


//                     <Snackbar
//                         open={openSnackbar}
//                         autoHideDuration={3000}
//                         onClose={handleCloseSnackbar}
//                         message="Address copied to clipboard!"
//                         anchorOrigin={{
//                             vertical: 'bottom',
//                             horizontal: 'center',
//                         }}
//                         ContentProps={{
//                             sx: {
//                                 borderRadius: "10px",
//                                 backgroundColor: "#BB5C17",
//                                 width: "100%", // Set full width relative to the container
//                                 maxWidth: "400px", // Set a max width to limit the size
//                                 marginBottom: "390px",
//                             },
//                         }}
//                     />

//                 </Box>





//             </Box>

//         </div >
//     );
// };
// export default WalletConnected



import { Box, Button, Grid, IconButton, keyframes, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import connectWallet1 from "../images/connectWallet1.svg"
import connectWallet2 from "../images/ConnectWallet2.png"
import arrowRight from "../images/rightArrow.png"
import TonWeb from "tonweb";
import TonConnect from '@tonconnect/sdk';
import { useTonConnectModal, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { setTonWalletAddress } from "../store/slices/userSlice"



const WalletConnected = () => {

    const [boxMessage, setBoxMessage] = useState("Email Verification Failed");
    const userId = useSelector((state) => state.user.userId);
    const userData = useSelector((state) => state?.user?.userData);
    const accessToken = useSelector((state) => state?.user?.accessToken)
    const [copySuccess, setCopySuccess] = useState(false);

    const [walletBalance, setWalletBalance] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tonConnect = new TonConnect();
    const { open } = useTonConnectModal();
    const [tonConnectUI] = useTonConnectUI(); // Get the TonConnect UI instance
    const wallet = useTonWallet();
    const address = useTonAddress(); // Get the connected wallet address''
    const [walletAdd, setWalletAdd] = useState(null)
  
  

    // Fetch wallet balance
    useEffect(() => {
        const fetchWalletBalance = async () => {
            try {
                if (address) {
                    const tonweb = new TonWeb();
                    const balance = await tonweb.provider.getBalance(address);
                    setWalletBalance(TonWeb.utils.fromNano(balance));
                    localStorage.setItem("walletAddress", address);
                    dispatch(setTonWalletAddress(address))
                }
            } catch (error) {
                console.error("Failed to fetch wallet balance:", error);
            }
        };

        fetchWalletBalance();
    }, [address]);

    const handleConnect = useCallback(() => {
        if (address) {
            setWalletAdd(address)
            // dispatch(setTonWalletAddress(address))
            alert("Wallet is already connected!");
            return;
        }
        open();
    }, [open, address,dispatch]);

    const disconnectWallet = async () => {
        try {
            // Directly call disconnect without checking the return value
            await tonConnectUI.disconnect();
            console.log("Wallet disconnected successfully.");
            setWalletAdd(null)
            setWalletBalance(null);  // Clear wallet balance
        } catch (error) {
            console.error("Error disconnecting wallet:", error);
        }
    };

    const handleCopy = () => {
        if (address) {
          // Fallback for all contexts
          const textArea = document.createElement("textarea");
          textArea.value = address;
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
      
          try {
            document.execCommand('copy');
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
          } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
          }
      
          document.body.removeChild(textArea);
        }
      };
      
      





    const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;



    const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
    return (
        <div
            style={{
                // background:"#fff",
                width: "100%",
                height: "82vh",
                overflowY: "scroll",
                overflowX: "hidden",
                boxSizing: "border-box",
                // paddingBottom: "10px",



            }}


        >
           

            <Box
                sx={{
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    paddingTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "65px",
                    // overflowY: "scroll",

                }}
            // className={`scroll-transition ${isScrolled ? 'scroll-up' : ''}`}

            >
                {/* register info box */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        gap: "25px",
                        flexDirection: "column"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            gap: "12px",
                            flexDirection: "column"
                        }}
                    >
                        <Typography
                            component={"h1"}
                            sx={{
                                fontFamily: "inter",
                                fontWeight: 800,
                                fontSize: "24px",
                                lineHeight: "28.8px",
                                color: "#fff",
                                textAlign: "center", // Center the entire text
                            }}
                        >
                            Connect your TON wallet to
                            <br />
                            <span
                                style={{
                                    display: "inline-block", // Ensures the span behaves like inline text
                                    width: "100%", // Takes up full width to center
                                    textAlign: "center", // Centers the text within the span
                                }}
                            >
                                this bot
                            </span>
                        </Typography>

                        <Box sx={{ width: "calc(100% - 30px)", height: "70px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: "15px", marginRight: "15px" }}>
                            {/* First box with 64x64 size and image filling the box */}
                            <Box sx={{ width: "64px", height: "64px", marginRight: "40px" }}>
                                <img src={connectWallet1} alt="First Box" style={{ width: "100%", height: "100%" }} />
                            </Box>

                            {/* Middle box with 64x12 size, containing 3 images of 12x12 */}
                            <Box sx={{ width: "64px", height: "12px", display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "40px" }}>
                                <img src={arrowRight} alt="Image 1" style={{ width: "12px", height: "12px" }} />
                                <img src={arrowRight} alt="Image 2" style={{ width: "12px", height: "12px" }} />
                                <img src={arrowRight} alt="Image 3" style={{ width: "12px", height: "12px" }} />
                            </Box>

                            {/* Last box with 64x64 size and image filling the box */}
                            <Box sx={{ width: "64px", height: "64px" }}>
                                <img src={connectWallet2} alt="Third Box" style={{ width: "100%", height: "100%" }} />
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            marginTop: "60px",
                            border: '1px solid #FFFFFF',
                            bgcolor: "#802020",
                            color: "#FFFFFF",
                            borderRadius: '8px',
                            padding: '16px',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "100px",
                        }}
                    >
                        {/* First Row - Wallet Address */}
                        <Grid
                            container
                            sx={{
                                justifyContent: "center", // Center horizontally
                                marginBottom: "16px", // Adds space below the first row
                            }}
                        >
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    textAlign: "center",
                                    paddingRight: '16px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "inter",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        lineHeight: "18.2px",
                                    }}
                                >
                                    Wallet Address:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    textAlign: "center",
                                    paddingLeft: '16px',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "inter",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        lineHeight: "18.2px",
                                    }}
                                >
                                    {address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ""}
                                </Typography>
                               {address && <Tooltip title={copySuccess ? "Copied!" : "Copy Address"} >
                                    <Button
                                        onClick={handleCopy}
                                        sx={{ marginLeft: "8px", color: "#FFFFFF", minWidth: "auto",}}
                                    >
                                        <ContentCopyIcon />
                                    </Button>
                                </Tooltip>}
                            </Grid>
                        </Grid>

                        {/* Divider Line */}
                        <Box
                            sx={{
                                borderTop: '1px solid #FFFFFF4D', // Creates the horizontal line
                                width: '100%', // Make sure it takes full width
                                marginBottom: "16px", // Adds space below the divider
                            }}
                        />

                        {/* Second Row - Wallet Balance */}
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                            }}
                        >
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    textAlign: "center",
                                    paddingRight: '16px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "inter",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        lineHeight: "18.2px",
                                    }}
                                >
                                    Wallet Balance:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    textAlign: "center",
                                    paddingLeft: '16px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "inter",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        lineHeight: "18.2px",
                                    }}
                                >
                                    {walletBalance}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {
                        (wallet) ? (
                            <button
                                style={{
                                    padding: "10px",
                                    borderRadius: "12px",
                                    background: "#AA4545",
                                    fontFamily: "inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    border: "1px solid transparent",
                                    backgroundImage: "linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)",
                                    backgroundOrigin: "border-box",
                                    backgroundClip: "padding-box, border-box",
                                }}
                                onClick={disconnectWallet} // Call disconnect function when clicked
                            >
                                Disconnect Wallet
                            </button>
                        ) : (
                            <button
                                style={{
                                    padding: "10px",
                                    borderRadius: "12px",
                                    background: "#AA4545",
                                    fontFamily: "inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    border: "1px solid transparent",
                                    backgroundImage: "linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)",
                                    backgroundOrigin: "border-box",
                                    backgroundClip: "padding-box, border-box",
                                }}
                                onClick={handleConnect} // Call connect function when clicked
                            >
                                Connect Wallet
                            </button>
                        )
                    }

                </Box>





            </Box>

        </div >
    );
};
export default WalletConnected