import React from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UnsuccessImage from '../../images/CongratsSuccessModal.svg'; // Update the path if needed
import KlinkToken from '../../images/Klink-Coin.svg'; // Add your KlinkToken image path here
import { useNavigate } from 'react-router-dom';

const TicketLimitExceedModal = ({ SetTicketLimitExceed, customMessage, subtext}) => {
  const navigate = useNavigate();

  const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        background: "#1B1529FA",
        height: "393px",
        zIndex: 1000000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        animation: `${slideUp} 200ms ease-out forwards`,
        borderRadius: "25px",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      <IconButton
        onClick={() => SetTicketLimitExceed(false)}
        sx={{
          position: "absolute",
          top: "12px",
          right: "12px",
          color: "#fff",
        }}
      >
        <CloseIcon width={16} height={16} />
      </IconButton>
      <img
        src={UnsuccessImage}
        alt="Failure"
        width={160}
        height={160}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            marginBottom: "15px"
          }}
        >
         
        </Box>
        <Typography
          component="p"
          sx={{
            fontFamily: "Inter",
            fontWeight: 800,
            fontSize: "32px",
            lineHeight: "38.4px",
            color: "#fff",
            letterSpacing: "-1px",
          }}
        >
          Ticket Limit Exeeds
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#fff",
            marginTop: "12px",
            padding: "0 20px",
          }}
        >
         {subtext}
        </Typography>
        {/* <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#fff",
            padding: "0 20px",
            textAlign: "center",
          }}
        >
         
        </Typography> */}
      </Box>
      <button
        style={{
          width: "350px",
          background: "#674EFF",
          height: "55px",
          outline: "none",
          border: "none",
          borderRadius: "12px",
          marginTop: "10px",
          cursor: "pointer",
          marginBottom: "21px"
        }}
        onClick={() => SetTicketLimitExceed(false)}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 873,
            fontSize: "18px",
            lineHeight: "23.4px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Done
        </Typography>
      </button>
    </Box>
  );
};

export default TicketLimitExceedModal;