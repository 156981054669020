import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, styled, keyframes, useMediaQuery, } from "@mui/material";
import CloseIconn from "@mui/icons-material/Close";

import { useNavigate } from 'react-router-dom';

//Migrations starts here

import Coin from "../../../images/Klink-Coin.svg";
import Arrow from "../../../images/Arrowright.svg";
import multitapIMG from "../../../images/Multitap.svg";
import RechargingSpeedIMG from "../../../images/RechargingSpeed.svg";
import EnergyLimitIMG from "../../../images/EnergyLimit.svg";

import { useDispatch, useSelector } from "react-redux";
import MultitapModal from "../../Modals/MultitapModal";
import TonPreConfirmPopup from "../../Modals/TonPreConfirmPopup";

import { FunctionCommas } from "../../../utils/commasFun";
import Popup from "../../Popup";
import SuccessFailPopUp from "../../SuccessFailPopUp";
import CongratsPopUp from "../../Modals/CongratulationPopupModal";
import TicketLimitExceedModal from "../../Modals/TicketLimitExceedModal";
import Lottie from "react-lottie";
import ConfettiVertical from '../../../ConfettiJson/confetti vertical.json'
import { setBoosterPopVisible, setOpenSuperBoostPopup, setBosterPopUp } from "../../../store/slices/userSlice";
import SuperBoostIcon from "../../../images/SuperBoostIcon.svg"
import SuperBoostPopup from "./SuperBoostPopup";









const BoosterPopup = ({ setOpenPopup1 }) => {
    const navigate = useNavigate();



    const isSmallScreen = useMediaQuery("(max-width:370px)");
    const [fade, setFade] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [ticket, setTicket] = useState(false);
    const accessToken = useSelector((state) => state?.user?.accessToken);

    const [openModal, setOpenModal] = useState(false);
    const [openPreTonConfirmModal, setOpenPreTonConfirmModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [success, setSuccess] = useState(false);
    const [resultPopUp, setResultPopUp] = useState(false);
    const [congratsSuccessPopup, SetCongratsSuccessPopup] = useState(false);
    const [ticketLimitExceed, SetTicketLimitExceed] = useState(false);
    const [modalLoadder, setModalLoadder] = useState(false);
    const currentPoint = useSelector((state) => state?.point?.currentPoint);
    const [initialUserPoint, setInitialUserPoint] = useState(currentPoint);
    const [notEnoughPoint, setNotEnoughPoint] = useState(false);
    const [popupMessage, setPopUpMessage] = useState("");
    const [trafficLight, setTafficLight] = useState(false);
    const [popUpMessage, setPopupMessage] = useState("");
    const [subtext, setSubtext] = useState("");
    const [subtext1, setSubtext1] = useState("");
    const [showConfetti, setShowConfetti] = useState(false);
    const [showLotties, setShowLotties] = useState(false); // Control whether to show the animation
    const boosterPopVisible = useSelector((state) => state.user.boosterPopVisible);

    const defaultOptions = {
        loop: false, // Set to true if you want the animation to repeat
        autoplay: true,
        animationData: ConfettiVertical, // Your Lottie animation data
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };




    const dispatch = useDispatch();
    const { userId } = useSelector((state) => state.user);
    const userData = useSelector(
        (state) => state?.user?.userData?.boosts?.multiTap
    );
    const nextCost = useSelector(
        (state) => state?.user?.userData?.boosts?.multiTap?.nextCost
    );
    const currentLevel = useSelector(
        (state) => state?.user?.userData?.boosts?.multiTap?.currentLevel
    );
    const nextLevel = useSelector(
        (state) => state?.user?.userData?.boosts?.multiTap?.nextLevel
    );
    // const  currentValue = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.currentValue)
    const nextValue = useSelector(
        (state) => state?.user?.userData?.boosts?.multiTap?.nextValue
    );


    //energy limit
    const nextCostenergylimit = useSelector(
        (state) => state?.user?.userData?.boosts?.energyLimit?.nextCost
    );
    const currentLevelenergylimit = useSelector(
        (state) => state?.user?.userData?.boosts?.energyLimit?.currentLevel
    );
    const nextLevelenergylimit = useSelector(
        (state) => state?.user?.userData?.boosts?.energyLimit?.nextLevel
    );
    // const  currentValue = useSelector((state) => state?.user?.userData?.boosts?.multiTap?.currentValue)
    const nextValueenergylimit = useSelector(
        (state) => state?.user?.userData?.boosts?.energyLimit?.nextValue
    );

    //reacharging-speed
    // const userDatarechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed)
    const nextCostRechargingspeed = useSelector(
        (state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextCost
    );
    const currentLevelRechargingspeed = useSelector(
        (state) => state?.user?.userData?.boosts?.rechargingSpeed?.currentLevel
    );
    const nextLevelRechargingspeed = useSelector(
        (state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextLevel
    );
    const nextValueRechargingspeed = useSelector(
        (state) => state?.user?.userData?.boosts?.rechargingSpeed?.nextValue
    );
    // const  currentValueRechargingspeed = useSelector((state) => state?.user?.userData?.boosts?.rechargingSpeed?.currentValue)

    const userTonTickets = useSelector((state) => state?.user?.userTonTickets);
    const numberOfTickets = useSelector(
        (state) => state?.user?.userTonTickets?.numberOfTickets
    );

    const usageCount = useSelector((state) => state?.user?.usageCount);


    // useEffect(() => {
    //     if (success && congratsSuccessPopup) {
    //         setShowLotties(true);
    //         const timer = setTimeout(() => {
    //             setShowLotties(false);
    //         }, 7000); // Confetti lasts for 3000 milliseconds (3 seconds)

    //         return () => clearTimeout(timer); // Cleanup timer on component unmount
    //     }
    // }, [success, congratsSuccessPopup]);


    // useEffect(() => {
    //     if (!congratsSuccessPopup) {
    //         // Reset Lottie animation when the popup closes
    //         setShowLotties(false);
    //     }
    // }, [congratsSuccessPopup]);




    const handleOpenModal = (type) => {
        let content = {};
        switch (type) {
            case "multitap":
                content = {
                    type: "multitap",
                    icon: multitapIMG,
                    title: "Multitap",
                    description1: "Increase the amount of points you earn per tap!",
                    description2: `+${nextValue} per tap for this boost level.`,
                    coinIcon: Coin,
                    nextCost: nextCost,
                    nextLevel: nextLevel,
                };
                break;
            case "energyLimit":
                content = {
                    type: "energyLimit",
                    icon: EnergyLimitIMG,
                    title: "Energy Limit",
                    description1: "Increase the speed of energy bar recharge  ",
                    description2: `+ ${nextValueenergylimit} energy limit for this boost level `,
                    coinIcon: Coin,
                    nextCost: nextCostenergylimit,
                    nextLevel: nextLevelenergylimit,
                };
                break;
            case "rechargingSpeed":
                content = {
                    type: "rechargingSpeed",
                    icon: RechargingSpeedIMG,
                    title: "Recharging Speed",
                    description1: "Increase the speed of energy bar recharge ",
                    description2: `+ ${nextValueRechargingspeed} energy limit for this boost level`,
                    coinIcon: Coin,
                    nextCost: nextCostRechargingspeed,
                    nextLevel: nextLevelRechargingspeed,
                };
                break;
            default:
                break;
        }
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        dispatch(setBoosterPopVisible(true));
        setOpenModal(false);
    };

    const handleCloseTONModal = () => {
        setOpenPreTonConfirmModal(false);
    };
    const handleBoxClick = (ticket) => {
        if (ticket?.ticketCost > currentPoint) {
            setNotEnoughPoint(true);
            return;
        }
        setTicket(ticket);
        setOpenPreTonConfirmModal(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setTafficLight(false);
        }, 5000);
    }, [trafficLight]);

    useEffect(() => {
        setFade(true);
    }, []);



    const handelBuySuperBoost = () => {
        // this is logic of opeing new popup
        setOpenPopup1(false)
        dispatch(setOpenSuperBoostPopup(true))
        dispatch(setBosterPopUp(false))
    }


    const boxFromLeft = keyframes`
      0% {
            transform: translateX(10%);
         
        }
        100% {
            transform: translateX(0);
          
        }
    `;

    const boxFromRight = keyframes`
      0% {
            transform: translateX(-10%);
         
        }
        100% {
            transform: translateX(0);
          
        }
    `;
    const fromTop = keyframes`
      0% {
            transform: translateY(-15%);
            opacity: 0;
        }
       
        50%{
     opacity: 0.5;
    
    
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    `;





    const slideUp = keyframes`
0% {
  transform: translateY(100%);
  opacity: 0;
}
100% {
  transform: translateY(0);
  opacity: 1;
}
`;

    return (
        <Box
            sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                background: "#802020",
                // height: "30.75rem",
                height: boosterPopVisible ? "32.75rem" : "10rem",
                zIndex: 1000000,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                animation: `${slideUp} 200ms ease-out forwards`,
                borderRadius: "25px",
                boxSizing: "border-box",
                padding: "20px",

            }}
        >


            {/* main box starts here  */}


            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    heght: "25.06rem",
                    flexDirection: "column",
                    top: '0',
                    // border:"2px solid black"

                }}
            >




                <Box
                    sx={{
                        marginBottom: "20px",
                        marginLeft: "-10px",
                        animation: `${fromTop} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                    }}
                >
                    {trafficLight && (
                        <Popup
                            message={"Your have reached maximum level"}
                            status={"PENDING"}
                        />
                    )}
                </Box>


                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginTop: '-1.12rem' }}>
                    <Typography
                        sx={{
                            color: "#FFFFFF",
                            fontWeight: 700,
                            fontSize: "1.12rem",
                            marginTop: "0",
                            fontFamily: "Inter",
                            lineHeight: "23.4px",
                        }}
                    >
                        Boosters
                    </Typography>

                    <IconButton
                        onClick={() => setOpenPopup1(false)}
                        sx={{
                            // position: "absolute",
                            // right: "0.75rem",
                            color: "#fff",
                        }}
                    >
                        <CloseIconn width={16} height={16} />
                    </IconButton>



                </Box>


                <div>

                    {openModal && (
                        <MultitapModal
                            setTafficLight={setTafficLight}
                            setPopUpMessage={setPopUpMessage}
                            setOpenModal={setOpenModal}
                            setNotEnoughPoint={setNotEnoughPoint}
                            setModalLoadder={setModalLoadder}
                            loadder={modalLoadder}
                            setSuccess={setSuccess}
                            setResultPopUp={setResultPopUp}
                            onClose={handleCloseModal}
                            content={modalContent}
                            setOpenPopup1={setOpenPopup1}
                        />
                    )}
                    {openPreTonConfirmModal && (
                        <TonPreConfirmPopup
                            // setTafficLight={setTafficLight}
                            setPopUpMessage={setPopUpMessage}
                            setOpenPreTonConfirmModal={setOpenPreTonConfirmModal}
                            // setNotEnoughPoint={setNotEnoughPoint}
                            setModalLoadder={setModalLoadder}
                            loadder={modalLoadder}
                            setSuccess={setSuccess}
                            SetCongratsSuccessPopup={SetCongratsSuccessPopup}
                            onClose={handleCloseTONModal}
                            // content={modalContent}
                            setSubtext={setSubtext}
                            ticket={ticket}
                            SetTicketLimitExceed={SetTicketLimitExceed}
                        />
                    )}
                    <div>


                        {showLotties && (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 9999,
                                    pointerEvents: 'none',
                                }}
                            >
                                <Lottie
                                    options={defaultOptions}
                                    height={window.innerHeight}
                                    width={window.innerWidth}
                                    isStopped={!showLotties} // Stop the animation when showConfetti is false
                                    speed={1.5} // Adjust this for speed, 1.5 means 1.5x speed
                                />
                            </div>
                        )}

                        {resultPopUp && (
                            <SuccessFailPopUp
                                navigate={navigate}
                                customMessage={popupMessage}
                                setResultPopUp={setResultPopUp}
                                success={success}
                            />
                        )}

                        {success && congratsSuccessPopup && (
                            <div style={{ position: 'relative' }}>
                                <CongratsPopUp
                                    customMessage={popupMessage}
                                    subtext={subtext}
                                    setSubtext1={setSubtext1}
                                    setSubtext={setSubtext}
                                    SetCongratsSuccessPopup={SetCongratsSuccessPopup}
                                    setShowConfetti={setShowConfetti}
                                />
                            </div>
                        )}
                    </div>


                    {ticketLimitExceed && (
                        <TicketLimitExceedModal
                            SetTicketLimitExceed={SetTicketLimitExceed}
                            customMessage={popUpMessage}
                            setSubtext={setSubtext}
                            subtext={subtext}

                        />
                    )}


                    {/* popus end  here  */}


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            border: "1px solid #313130",
                            borderRadius: "12px",
                            height: "82px",
                            marginTop: "0px",
                            bgcolor: "#AA4545",
                            padding: "10px !important",
                            animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,


                        }}
                        onClick={() => handleOpenModal("multitap")}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "7px",
                                bgcolor: "#AA4545",
                            }}
                        >
                            <Box>
                                <img
                                    src={multitapIMG}
                                    width={60}
                                    height={60}
                                    style={{ objectFit: "cover" }}
                                    alt="multiTapIMG"
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 800,
                                        // marginTop: "10px",
                                    }}
                                >
                                    Multitap
                                </Typography>
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "12px", // Font size 10px for extra small screens (below 400px)
                                            sm: "12px", // Font size 12px for small screens and above
                                            width: "226px",
                                        },
                                    }}
                                >
                                    Increase the amount you earn per tap
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <img
                                        src={Coin}
                                        alt="Coin"
                                        width={16}
                                        height={16}
                                    // style={{ marginBottom: "10px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#F4FDFF",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            fontFamily: "Inter",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        {FunctionCommas(nextCost)}{" "}
                                        <span style={{ color: "#ffffff" }}>
                                            {" "}
                                            | level {currentLevel}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={Arrow}
                            alt="Arrow"
                            width={32}
                            height={32}
                            style={{ marginTop: "25px" }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            border: "1px solid #313130",
                            // padding: "0px 10px",
                            padding: "10px",
                            borderRadius: "12px",
                            height: "82px",
                            marginTop: "10px",
                            bgcolor: "#AA4545",
                            animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                        }}
                        onClick={() => handleOpenModal("rechargingSpeed")}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "7px",
                            }}
                        >
                            <Box>
                                <img
                                    src={RechargingSpeedIMG}
                                    alt="RechargingSpeedIMG"
                                    width={60}
                                    height={60}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 800,
                                        // marginTop: "10px",
                                    }}
                                >
                                    Recharging Speed
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "12px", // Font size 10px for extra small screens (below 400px)
                                            sm: "12px", // Font size 12px for small screens and above
                                            width: "226px",
                                        },
                                    }}
                                >
                                    Increase the speed of recharge
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <img
                                        src={Coin}
                                        alt="Coin"
                                        width={16}
                                        height={16}
                                    // style={{ marginBottom: "10px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#F4FDFF",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            fontFamily: "Inter",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        {FunctionCommas(nextCostRechargingspeed)}{" "}
                                        <span style={{ color: "#ffffff" }}>
                                            {" "}
                                            | level {currentLevelRechargingspeed}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={Arrow}
                            alt="Arrow"
                            width={32}
                            height={32}
                            style={{ marginTop: "25px" }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            border: "1px solid #313130",
                            // paddingX: '14px',
                            // paddingY: '5px',
                            // padding: "0px 10px",
                            padding: "10px",
                            borderRadius: "12px",
                            height: "82px",
                            marginTop: "10px",
                            bgcolor: "#AA4545",
                            animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                        }}
                        onClick={() => handleOpenModal("energyLimit")}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "7px",
                            }}
                        >
                            <Box>
                                <img
                                    src={EnergyLimitIMG}
                                    alt="EnergyLimitIMG"
                                    width={60}
                                    height={60}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 800,
                                        // marginTop: "10px",
                                    }}
                                >
                                    Energy Limit
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "12px", // Font size 10px for extra small screens (below 400px)
                                            sm: "12px", // Font size 12px for small screens and above
                                            width: "226px",
                                        },
                                    }}
                                >
                                    Increase the limit in energy bar
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <img
                                        src={Coin}
                                        alt="Coin"
                                        width={16}
                                        height={16}
                                    // style={{ marginBottom: "10px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#F4FDFF",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            fontFamily: "Inter",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        {FunctionCommas(nextCostenergylimit)}{" "}
                                        <span style={{ color: "#FFFFFF" }}>
                                            {" "}
                                            | level {currentLevelenergylimit}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={Arrow}
                            alt="Arrow"
                            width={32}
                            height={32}
                            style={{ marginTop: "25px" }}
                        />
                    </Box>

                    {/* this is added new  */}
                    {/* if(usageCount===3)
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                            border: "1px solid #313130",
                            // paddingX: '14px',
                            // paddingY: '5px',
                            // padding: "0px 10px",
                            padding: "10px",
                            borderRadius: "12px",
                            height: "82px",
                            marginTop: "10px",
                            bgcolor: "#AA4545",
                            animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                        }}
                        onClick={() => handelBuySuperBoost()}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "7px",
                            }}
                        >
                            <Box>
                                <img
                                    src={SuperBoostIcon}
                                    alt="EnergyLimitIMG"
                                    width={60}
                                    height={60}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 800,
                                        // marginTop: "10px",
                                    }}
                                >
                                    Super Boost
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "12px", // Font size 10px for extra small screens (below 400px)
                                            sm: "12px", // Font size 12px for small screens and above
                                            width: "226px",
                                        },
                                    }}
                                >
                                    Increase the limit in energy bar
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <img
                                        src={Coin}
                                        alt="Coin"
                                        width={16}
                                        height={16}
                                    // style={{ marginBottom: "10px" }}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#F4FDFF",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            fontFamily: "Inter",
                                            // marginBottom: "10px",
                                        }}
                                    >
                                        {FunctionCommas(nextCostenergylimit)}{" "}
                                        <span style={{ color: "#FFFFFF" }}>
                                            {" "}
                                            | level {currentLevelenergylimit}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={Arrow}
                            alt="Arrow"
                            width={32}
                            height={32}
                            style={{ marginTop: "25px" }}
                        />
                    </Box> */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: usageCount === 3 ? "grey" : "black", // Change text color if disabled
                            border: "1px solid #313130",
                            padding: "10px",
                            borderRadius: "12px",
                            height: "82px",
                            marginTop: "10px",
                            bgcolor: usageCount === 3 ? "#ef9a9a" : "#AA4545", // Change background color if disabled
                            animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`,
                            pointerEvents: usageCount === 3 ? "none" : "auto", // Disable clicking if usageCount is 3
                            opacity: usageCount === 3 ? 0.6 : 1, // Make the box look visually disabled
                        }}
                        onClick={() => {
                            if (usageCount !== 3) {
                                handelBuySuperBoost(); // Only trigger if not disabled
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "7px",
                            }}
                        >
                            <Box>
                                <img
                                    src={SuperBoostIcon}
                                    alt="EnergyLimitIMG"
                                    width={60}
                                    height={60}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 800,
                                    }}
                                >
                                    Super Boost
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#FFFFFF",
                                        fontFamily: "Inter",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "12px",
                                            sm: "12px",
                                            width: "226px",
                                        },
                                    }}
                                >
                                    Increase the limit in energy bar
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        gap: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    <img
                                        src={Coin}
                                        alt="Coin"
                                        width={16}
                                        height={16}
                                    />
                                    <Typography
                                        sx={{
                                            color: "#F4FDFF",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            fontFamily: "Inter",
                                        }}
                                    >
                                        {usageCount}/3
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <img
                            src={Arrow}
                            alt="Arrow"
                            width={32}
                            height={32}
                            style={{ marginTop: "25px" }}
                        />
                    </Box>
                </div>
            </Box>

























        </Box>
    );
};

export default BoosterPopup;