import React, { useState } from 'react';
import { Box, Typography, IconButton, styled, keyframes } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { BuyUserTonTickets } from '../../Apis';
import { setUserData } from '../../store/slices/userSlice';
import ThreeSectionLodder from '../ThreeSectionLodder';
import { useNavigate } from 'react-router-dom';
import { FunctionCommas } from '../../utils/commasFun';
import TonPopupImg  from "../../images/TonPopupImg.svg";
import TonTickets1X  from "../../images/TonprePopup/TonTickets1X.svg";
import TonTickets5X from "../../images/TonprePopup/TonTickets5X.svg";
import TonTickets10X from "../../images/TonprePopup/TonTickets10X.svg";
import TonTickets50X from "../../images/TonprePopup/TonTickets50X.svg";
import TonTickets100X from "../../images/TonprePopup/TonTickets100X.svg";
import TonTickets500X from "../../images/TonprePopup/TonTickets500X.svg";

import Coin from "../../images/Klink-Coin.svg";
import { t } from 'i18next';
const ticketImages = {
    1: TonTickets1X,
    5: TonTickets5X,
    10: TonTickets10X,
    50: TonTickets50X,
    100: TonTickets100X,
    500: TonTickets500X,
  }; 

function TonPreConfirmPopup({ onClose, setSubtext,ticket, loadder,setSuccess,SetCongratsSuccessPopup, SetTicketLimitExceed,setModalLoadder,setOpenPreTonConfirmModal,setPopUpMessage}) {
    const ticketImage = ticketImages[ticket.numberOfTickets] || TonPopupImg;

    const dispatch = useDispatch();
    const { userId } = useSelector((state) => state.user);
    const userData = useSelector((state) => state.user.userData);
    const accessToken = useSelector((state)=>state?.user?.accessToken);
    const currentPoint = useSelector((state) => state?.point?.currentPoint)
    const navigate = useNavigate();;
    const handleBuy = async () => {
        dispatch(BuyUserTonTickets(userId,ticket._id,accessToken,navigate,SetCongratsSuccessPopup,SetTicketLimitExceed,setSuccess,setModalLoadder,setOpenPreTonConfirmModal,setPopUpMessage,setSubtext));
    };


    const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
   const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
    return (

        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left:0,
                right:0,
                background: '#1B1529FA',
                height: '393px',
                zIndex: 1000000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                animation: `${slideUp} 200ms ease-out forwards`,
                padding:"23px",
                borderRadius:"25px"
                
            }}
        >
          { !loadder ? 
          <>       
          <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    color: '#fff',
                }}
            >
                <CloseIcon />
            </IconButton>
            <img src={ticketImage} alt="icon" width={160} height={160} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // gap: '10px',
                    Top:'211px'
                }}
            >
                <img src={Coin} width={26.17} height={26.17} alt="coin_icon" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="p"
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 800,
                            fontSize: '24px',
                            lineHeight: '28.8px',
                            color: '#fff',
                            marginLeft:"10px",
                        }}
                    >
                        {/* {FunctionCommas(content.nextCost)} */}
                        {FunctionCommas(ticket.ticketCost)}
                    </Typography>
                   
                </div>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop:'12px'
                }}
                >
                <Typography
                    component="p"
                    sx={{
                    fontFamily: 'Inter',
                    fontSize: '32px',
                    fontWeight: 800,
                    lineHeight: '38.4px',
                    letterSpacing: '-0.02em',
                    textAlign: 'center',
                    color: '#fff',
                    }}
                >
                    Claim {ticket.numberOfTickets} TON points
                </Typography>
                <Typography
                sx={{
                    marginTop:"12px", 
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '16.94px',  // Adjusted line-height to match your provided value
                    textAlign: 'center',
                    color: '#fff',
                    paddingX: '10px',
                    width:"393px",
                }}
                >
                Points automatically populate in your TON wallet
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '16.94px',  // Adjusted line-height to match your provided value
                        textAlign: 'center',
                        color: '#fff',
                        paddingX: '10px',
                        width:"393px",
                    }}
                >
                    {/* {content.description2} */}
                    for the next prize pool draw
                </Typography>
            </Box>
            
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <button
                     style={{
                       width: '100%',
                       background: '#674EFF',
                       height: '55px',
                       outline: 'None',
                       border: 'None',
                       borderRadius: '12px',
                       marginX: 'auto',
                       textTransform: 'none',
                       marginTop: "25px",
                    //    marginY:'21.5px'
                    marginBottom: "31px"
         
                     }}
                    onClick={handleBuy}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 800,
                            fontSize: '18px',
                            lineHeight: '23.4px',
                            color: '#fff',
                        }}
                    >
                        Claim Tickets
                    </Typography>
                </button>
            </Box>
                    
          </> :<ThreeSectionLodder/>}
        </Box>
    );
}

export default TonPreConfirmPopup;
