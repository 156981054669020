import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Box, Grid, Typography, Button, colors, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { message } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../Apis";
import Coin from "../images/SugerBoy-coin.svg"
import TelegramInviteIMG from "../images/TelegramInviteIMG.svg";
import sugarBoyLogo from "../images/telegram-regular_logo.svg"
import sugarBoyLogo2 from "../images/telegram-premium_logo.svg"
import BronzeCoin from '../images/BronzeSmall.svg'
import SilverCoin from '../images/SilverSmall.svg'
import GoldSmall from '../images/GoldSmall.svg'
import Platinum from '../images/PlatinumSmall.svg'
import Diamand from '../images/DiamondSmall.svg'
import TelegramPrem from '../images/TelegramPre.svg'
import Copy from '../images/copy.svg'
import { keyframes } from '@mui/system';
import UserRegisterStatus from "../components/UserRegisterStatus";
import infocircleIcon from '../images/infocircleIcon.svg'
import InviteMaxLimitPopup from "../components/Modals/InviteMaxLimitPopup";
import star_background from "../images/Star_background.svg"




const Friends = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user.userData);
  const userRefData = useSelector((state) => state.user.userData?.referrals);
  const [openInviteMaxLimitReached, setOpenInviteMaxLimitReached] = useState(false);

  const isRefferalReached = useSelector((state) => state?.user?.isRefferalReached);

  const [isFadedIn, setIsFadedIn] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:395px)');
  useEffect(() => {
    setIsFadedIn(true); // Immediately fade in on mount
  }, []);

  const userReferalList = useSelector(
    (state) => state.user.userData?.referralsInfo
  );

  // useEffect(() => {
  //   dispatch(setData(userId));
  // }, [dispatch, userId]);

  const handleReferral = () => {


    const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}`;
    // console.log("referralLink", referralLink, userId);

    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy to clipboard:", error);
        message.error("Failed to copy to clipboard. Please try again.");
      });
  };

  const FadeContainer = styled(Box)`
    animation: fadeIn 1s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;
  const fromRight = keyframes`
  0% {
        transform: translateX(-5%);
    
    }
    100% {
        transform: translateX(0);
       
    }
`;
  const fromLeft = keyframes`
  0% {
        transform: translateX(5%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  const boxFromLeft = keyframes`
  0% {
        transform: translateX(10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  const boxFromRight = keyframes`
  0% {
        transform: translateX(-10%);
     
    }
    100% {
        transform: translateX(0);
      
    }
`;

  const fromBottom = keyframes`
  0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;
  const handleInviteClick = () => {

    if (isRefferalReached) {

      setOpenInviteMaxLimitReached(true);

      return;
    }

    // Other logic if none of the above conditions are met

    const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}  🚀`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(
      "Join me on sugar Boy's TG Bot and start earning crypto! Use the link to get a 5,000 points welcome bonus  \n\n"
    )}`;
    window.open(telegramShareUrl, "_blank");
  };


  const handleCopyClick = () => {


    const referralLink = `https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userId}`;
    navigator.clipboard.writeText(referralLink).then(
      () => {
        alert("Referral link copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  const memoizedInviteClick = useCallback(() => {
    handleInviteClick();
  }, [handleInviteClick]);

  const memoizedCopyClick = useCallback(() => {
    handleCopyClick();
  }, [handleCopyClick]);
  return (
    <Box>
      {openInviteMaxLimitReached && (
        <InviteMaxLimitPopup setOpenInviteMaxLimitReached={setOpenInviteMaxLimitReached} />
      )}
                <img src={star_background} style={{ position: "absolute", zIndex: -1, top: 0, bottom: 0, right: 0, left: 0, width: "100%", height: "100%" }} alt="Star Background" />
      {/* <UserRegisterStatus /> */}
      <Box
        style={{
          // padding: "24px",
          marginLeft: "22px",
          marginRight: "21px",
          height: "75vh",
          overflow: "hidden",
          overflowY: 'auto',
          msOverflowStyle: 'none !important', /* Internet Explorer 10+ */
          scrollbarWidth: 'none !important', /* Firefox */
          '&::-webkit-scrollbar': {
            display: 'none !important', /* Safari and Chrome */
          },
        }}
      >
        <Box
          textAlign={"center"}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              fontFamily={"Inter"}
              fontWeight={800}
              color={"white"}
              fontSize={"32px"}
              lineHeight={"38.4px"}
              sx={{
                marginTop: "29px",
                animation: `${fromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
              }}

            >
              Invite Friends
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "white",
                fontWeight: 400,
                fontFamily: "Inter",
                lineHeight: "16.94px",
                marginTop: "12px",
                animation: `${fromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
              }}
            >
              You and your friend will receive bonus points
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            bgcolor: "#802020",
            border: "1px solid #ffffff",
            padding: "0px 16.5px",
            borderRadius: "12px",
            height: "80px",
            marginTop: "28px",
            animation: `${boxFromLeft} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <img src={sugarBoyLogo} alt="Tabla" width={52} height={52} marginRight="2px" />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                component="p"
                variant="body1"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: isSmallScreen ? "14px" : "14px",
                  lineHeight: "18.2px",
                  fontWeight: 700,
                }}
              >
                Invite a friend
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  flexDirection: "row", // Ensure the image and text are in one line
                }}
              >
                <img
                  src={Coin}
                  alt="Coin"
                  width={22}
                  height={22}
                  style={{ marginTop: "7px" }} // Adjust margin-top as needed
                />
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "Inter",
                    marginLeft: "5px", // Adjust margin-left for spacing between image and text
                    marginTop: "7px"
                  }}
                >
                  +5,000
                </Typography>
                <Typography sx={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, lineHeight: "15.6px", color: "#FFFFFF !important", marginTop: "7px", marginLeft: "3px" }}>for you and your friend</Typography>
              </Box>
            </Box>
          </Box>

          {/* <img src={Arrow} alt="Arrow"  width={32} height={32} style={{marginTop:"25px"}}/> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            bgcolor: "#802020",
            border: "1px solid #ffffff",
            padding: "0px 10px",
            borderRadius: "12px",
            height: "80px",
            marginTop: "7px",
            animation: `${boxFromRight} 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)`
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <img src={sugarBoyLogo2} alt="Tabla" width={60} height={60} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                // component="p"
                // variant="body1"
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: isSmallScreen ? "14px" : "14px",
                  fontWeight: 700,
                  letterSpacing: "0px",
                  width: "263px"
                }}
              >
                Invite a friend with Telegram Premium
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "2px",
                  marginTop: "5px"
                }}
              >
                <img src={Coin} alt="Coin" width={22} height={22} style={{ marginTop: "5px" }} />
                <Typography
                  sx={{
                    color: "#F4FDFF",
                    fontWeight: 700,
                    fontSize: "12px",
                    fontFamily: "Inter",
                    marginLeft: "5px", // Adjust margin-left for spacing between image and text
                    marginTop: "7px"
                  }}
                >
                  +25,000
                </Typography>
                <Typography sx={{ fontFamily: "Inter", fontSize: "12px", fontWeight: 400, lineHeight: "15.6px", color: "#FFFFFF !important", marginLeft: "3px", marginTop: "7px" }}>for you and your friend</Typography>
              </Box>
            </Box>
          </Box>


          {/* <img src={Arrow} alt="Arrow"  width={32} height={32} style={{marginTop:"25px"}}/> */}
        </Box>

        <Box
          sx={{
            backgroundColor: "rgba(251, 196, 77, 0.1)", // Use rgba for transparent background
            // width: "350px",
            height: "38.67px",
            borderRadius: "12px",
            marginTop: "16px",
            border: "1px solid #FBC44D80",
            display: "flex", // Flexbox to arrange items in a row
            alignItems: "center", // Vertically center the items
            justifyContent: "space-between", // Distribute space between items
            padding: "0 12px", // Add padding to the left and right
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={infocircleIcon} alt="connectTON" style={{ marginRight: "8px" }} />
            <span
              style={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15.6px',
                textAlign: 'left',
                color: "#ffffff",
              }}
            >
              Referral must complete tasks to count
            </span>
          </Box>

        </Box>
        {/* <Box sx={{ marginTop: "37px" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 800,
              fontSize: "24px",
              lineHeight: "28.8px",
              color: "white",
              textAlign: "center",
            }}
          >
            Bonus For Leveling Up
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
              marginTop: "19px",
              marginBottom: "15px",
            }}
          >
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              Level
            </Typography>
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              For Friend
            </Typography>
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              Premium Friend
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              alignItems: "center",
              marginTop: "10px",
              border: "1px solid #ffffff",
              height: "60px",
              borderRadius: "12px",
              background: "#15131D",
              padding: "0 10px",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={BronzeCoin} alt="Coin" width={24} height={24} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={400}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                Bronze
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +5,000
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +10,000
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              alignItems: "center",
              marginTop: "10px",
              border: "1px solid #ffffff",
              height: "60px",
              borderRadius: "12px",
              background: "#15131D",
              padding: "0 10px",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={SilverCoin} alt="Coin" width={24} height={24} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={400}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                Silver
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +10,000
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +25,000
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              alignItems: "center",
              marginTop: "10px",
              border: "1px solid #ffffff",
              height: "60px",
              borderRadius: "12px",
              background: "#15131D",
              padding: "0 10px",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={GoldSmall} alt="Coin" width={24} height={24} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={400}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                Gold
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +25,000
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +50,000
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              alignItems: "center",
              marginTop: "10px",
              border: "1px solid #ffffff",
              height: "60px",
              borderRadius: "12px",
              background: "#15131D",
              padding: "0 10px",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Platinum} alt="Coin" width={24} height={24} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={400}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                Platinum
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +50,000
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +100,000
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              alignItems: "center",
              marginTop: "10px",
              border: "1px solid #ffffff",
              height: "60px",
              borderRadius: "12px",
              background: "#15131D",
              padding: "0 10px",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Diamand} alt="Coin" width={24} height={24} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={400}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                Diamond
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +100,000
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={Coin} alt="Coin" width={16} height={16} />
              <Typography
                color={"#FFFFFF"}
                fontWeight={700}
                fontFamily={"Inter"}
                fontSize={"12px"}
                lineHeight={"14.52px"}
                sx={{ marginLeft: '8px' }}
              >
                +200,000
              </Typography>
            </Box>
          </Box>
        </Box> */}

        <Box sx={{ marginTop: "37px" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 800,
              fontSize: "24px",
              lineHeight: "28.8px",
              color: "white",
              textAlign: "center",
            }}
          >
            Bonus For Leveling Up
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
              marginTop: "19px",
              marginBottom: "15px",
            }}
          >
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              Level
            </Typography>
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              For Friend
            </Typography>
            <Typography
              color={"#FFFFFF"}
              fontWeight={400}
              fontFamily={"Inter"}
              fontSize={"12px"}
              lineHeight={"14.52px"}
              textAlign="center"
            >
              Premium Friend
            </Typography>
          </Box>
          {[{
            img: BronzeCoin, label: "Bronze", friendBonus: "+5,000", premiumBonus: "+10,000"
          }, {
            img: SilverCoin, label: "Silver", friendBonus: "+10,000", premiumBonus: "+25,000"
          }, {
            img: GoldSmall, label: "Gold", friendBonus: "+25,000", premiumBonus: "+50,000"
          }, {
            img: Platinum, label: "Platinum", friendBonus: "+50,000", premiumBonus: "+100,000"
          }, {
            img: Diamand, label: "Diamond", friendBonus: "+100,000", premiumBonus: "+200,000"
          }].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                border: "1px solid #ffffff",
                height: "60px",
                borderRadius: "12px",
                background: "#802020",
                padding: "0 13px",
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', width: "100px" }}>
                <img src={item.img} alt="Coin" width={24} height={24} />
                <Typography
                  color={"#FFFFFF"}
                  fontWeight={400}
                  fontFamily={"Inter"}
                  fontSize={"12px"}
                  lineHeight={"14.52px"}
                  width={'100px'}
                  sx={{ marginLeft: '8px' }}
                >
                  {item.label}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                <img src={Coin} alt="Coin" width={16} height={16} />
                <Typography
                  color={"#FFFFFF"}
                  fontWeight={700}
                  fontFamily={"Inter"}
                  fontSize={"12px"}
                  lineHeight={"14.52px"}

                  sx={{ marginLeft: '7px' }}  // Adjust margin for spacing
                >
                  {item.friendBonus}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                <img src={Coin} alt="Coin" width={16} height={16} />
                <Typography
                  color={"#FFFFFF"}
                  fontWeight={700}
                  fontFamily={"Inter"}
                  fontSize={"12px"}
                  lineHeight={"14.52px"}

                  sx={{ marginLeft: '7px' }}
                >
                  {item.premiumBonus}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>



      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: "15%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
          boxSizing: "border-box",
          zIndex: 1000, // Ensure buttons are above other content
          animation: `${fromBottom} 1s cubic-bezier(0.25, 0.8, 0.25, 1)`
        }}
      >
        <Button
          sx={{
            bgcolor: "#AA4545",
            width: "70%",
            height: "55px",
            color: "#FFFFFF",
            borderRadius: "12px",
            border: '1px solid', // Solid border
            "&::before": {
              content: '""',
              position: 'absolute',
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background: 'linear-gradient(to bottom, #ffffff, #FC9F0D)',
              borderRadius: 'inherit',
              zIndex: -1,
            },
            "&::after": {
              content: '""',
              position: 'absolute',
              top: 1,
              left: 1,
              right: 1,
              bottom: 2,
              background: "#AA4545",
              borderRadius: 'inherit',
              zIndex: -1,
            },
            // borderBottom: "none",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "23.4px",
            textTransform: "none",
            "&:hover": {
              bgcolor: "#AA4545", // Adjust hover color if needed
            },
          }}
          onClick={memoizedInviteClick}
        >
          Invite a friend
        </Button>
        <Button
          sx={{
            bgcolor: "#AA4545",
            width: "55px",
            height: "55px",
            color: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "12px",
            border: '1px solid', // Solid border
            "&::before": {
              content: '""',
              position: 'absolute',
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background: 'linear-gradient(to bottom, #ffffff, #FC9F0D)',
              borderRadius: 'inherit',
              zIndex: -1,
            },
            "&::after": {
              content: '""',
              position: 'absolute',
              top: 1,
              left: 1,
              right: 1,
              bottom: 2,
              background: "#AA4545",
              borderRadius: 'inherit',
              zIndex: -1,
            },
            // borderBottom: "none",
            "&:hover": {
              bgcolor: "#AA4545", // Adjust hover color if needed
            }
          }}
          onClick={memoizedCopyClick}
        >
          <img src={Copy} />
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(Friends);

