function getRandomString(length) {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('').slice(0, length);
  }


  export async function generateUserKey() {
    let epochTimeInMS = Math.floor(Date.now());
    const maxReqTime = 4234;
    const prefix = '0x' + getRandomString(3);
    const suffix = getRandomString(5);
    const preConstant = getRandomString(5);
    const postConstant = getRandomString(5);
    const secretKey = getRandomString(12);
  
    // console.log("this is prefix", prefix);
    // console.log("this is suffix", suffix);
    // console.log("this is preConstant", preConstant);
    // console.log("this is postConstant", postConstant);
    // console.log("this is secretKey", secretKey);
  
    epochTimeInMS += maxReqTime;
    // console.log('epochTimeInMS --> ', epochTimeInMS);
  
    const keyString = `${prefix}${preConstant}${epochTimeInMS}${postConstant}${suffix}${secretKey}`;
    // console.log('keystring --> ', keyString);
  
    const encoder = new TextEncoder();
    const bytes = encoder.encode(keyString);
    const userKey = btoa(String.fromCharCode(...bytes));
  
    // console.log("this is userKey==", userKey);
    return { userKey };
  }

