import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, styled, keyframes } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { BuyUserMultitap, BuyUserEnergyLimit, BuyUserRechargeSpeed } from '../../Apis';
import { setUserData } from '../../store/slices/userSlice';
import ThreeSectionLodder from '../ThreeSectionLodder';
import { useNavigate } from 'react-router-dom';
import { FunctionCommas } from '../../utils/commasFun';
import { setNotEnoughPoint } from '../../store/slices/notEnoughPointSlice';
import {setBoosterPopVisible}  from "../../store/slices/userSlice";

function MultitapModal({ onClose, content,setTafficLight, nextCost, nextLevel,loadder,setSuccess,setResultPopUp,setModalLoadder,setOpenModal,setPopUpMessage,setOpenPopup1 }) {
    const dispatch = useDispatch();
    dispatch(setBoosterPopVisible(false));
    const { userId } = useSelector((state) => state.user);
    const userData = useSelector((state) => state.user.userData);
    const accessToken = useSelector((state)=>state?.user?.accessToken);
    const currentPoint = useSelector((state) => state?.point?.currentPoint)
    const navigate = useNavigate();

  

    const handleBuy = async () => {
        dispatch(setBoosterPopVisible(true));
        if(content?.nextCost > currentPoint ){
            dispatch(setNotEnoughPoint(true));
            setOpenModal(false);
            setOpenPopup1(false);
              return;
        }

        if (content.type === 'multitap') {

           dispatch(BuyUserMultitap(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight));        
        } else if (content.type === 'energyLimit') {
           dispatch(BuyUserEnergyLimit(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight));
        } else if (content.type === 'rechargingSpeed') {
             dispatch(BuyUserRechargeSpeed(userId,accessToken,navigate,setResultPopUp,setSuccess,setModalLoadder,setOpenModal,setPopUpMessage,setTafficLight));
        }

    };


    const FadeContainer = styled(Box)`
    animation: fadeIn 0.5s ease-in-out;
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
   `;
   const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;
const slideDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;
    return (

        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left:0,
                right:0,
                background: '#802020',
                height: '393px',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '25px',
                animation: `${slideUp} 200ms ease-out forwards`,
                padding:"23px",
                borderRadius:"25px"
                
            }}
        >
          { !loadder ? 
          <>       
          <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                    color: '#fff',
                
                }}
            >
                <CloseIcon />
            </IconButton>
            <img src={content.icon} alt="icon" width={160} height={160} style={{marginTop:"12px"}} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    top:'220px'  
                }}
            >
                <Typography
                    component="p"
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '32px',
                        lineHeight: '38.4px',
                        height: "38px",
                        color: '#fff',
                    }}
                >
                    {content.title}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '14px',
                        lineHeight: '16.94px',
                        color: '#fff',
                        textAlign: 'center',
                        marginTop:"12px"
                    }}
                >
                    {content.description1}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: '#fff',
                        textAlign: 'center',
                        marginTop:"12px"
                    }}
                >
                    {content.description2}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                }}
            >
                <img src={content.coinIcon} alt="coin_icon" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="p"
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 700,
                            fontSize: '18px',
                            lineHeight: '21.78px',
                            color: '#fff',
                        }}
                    >
                        {FunctionCommas(content.nextCost)}
                    </Typography>
                    <Typography
                        component="p"
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14.52px',
                            color: '#fff',
                            marginLeft:"4px"
                        }}
                    >
                         /  level {content.nextLevel} 
                    </Typography>
                </div>
            </Box>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <button
                    style={{
                        width: '100%',
                        background: '#AA4545',
                        outline: 'None',
                        border: 'None',
                        padding: '16px',
                        borderRadius: '12px',
                        marginBottom:'31px',
                        border: '1px solid transparent', // Transparent border to allow background-clip
                        backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                        backgroundOrigin: 'border-box', // Background starts from the border
                        backgroundClip: 'padding-box, border-box', 
                    }}
                    onClick={handleBuy}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontWeight: 800,
                            fontSize: '18px',
                            lineHeight: '23.4px',
                            color: '#fff',
                        }}
                    >
                        Get it!
                    </Typography>
                </button>
            </Box>
                    
          </> :<ThreeSectionLodder/>}
        </Box>
    );
}

export default MultitapModal;
