import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import DailyRewardImg from "../../images/MiningRewardMain.svg"
import { FunctionCommas } from "../../utils/commasFun";
import { useDispatch, useSelector } from "react-redux";
import KlinkPurple from "../../images/KlinkCoinPurple.svg"
import { getUserMiningInfo, claimMiningRewards } from "../../Apis";

const MiningRewardPopup = ({ setpenMinignRewadPopup  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.userId);
    const accessToken = useSelector((state)=>state?.user?.accessToken);
    const hourlyRewards = useSelector((state) => state?.user?.userminininfo?.hourlyRewards)

    const handleClaimRewards = async () => {
        try {
            const rewards = await dispatch(claimMiningRewards(userId, accessToken));
            if (rewards) {
                setpenMinignRewadPopup(false); // Close popup after successful claim
                dispatch(getUserMiningInfo(userId, accessToken)); // Fetch updated mining info
                navigate('/airdrop');
            }
        } catch (error) {
            console.error("Failed to claim rewards:", error);
        }
    }; 
    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    margin: "0 auto",
                    left: 0,
                    right: 0,
                    marginTop: "100px",
                    background: "#1B1529",
                    height: "438.17px", // For vertical height scaling
                    zIndex: 1000000,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: `${slideUp} 200ms ease-out forwards`,
                    borderRadius: "22.26px",
                    boxSizing: "border-box",
                    padding: "20px",
                    width: "90%", // Adjust this to a percentage for better responsiveness
                    // maxWidth: "320px", 
                    boxShadow: "0px 0px 53.03px rgba(149, 131, 255, 0.2)",

                }}
            >

                {/* close button box */}
                <Box>
                    <IconButton
                        onClick={() => setpenMinignRewadPopup(false)}
                        sx={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            color: "#fff",
                        }}
                    >
                        <CloseIcon width={16} height={16} />
                    </IconButton>
                </Box>

                {/* image box */}


                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '200px',
                        width: "151px",
                        marginTop:" 1.563rem"
                    }}
                >
                    <img
                        src={DailyRewardImg}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'  // Ensures the image scales proportionally
                        }}
                        alt="Tap Point"
                    />
                </Box>


                {/* last box with text and button */}
                <Box
                    sx={{
                        width: "100%",
                        height: "14.56rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1.56rem",


                    }}
                >
                    {/* Box1 */}
                    <Box
                        sx={{
                            width: "100%",
                            height: "5.87rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                        }}
                    >

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 800,
                                fontSize: "28.5px",
                                lineHeight: "34.2px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "10px",
                            }}
                        >
                            Mining Rewards
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "8.9px",
                                width: "100%"
                            }}
                        >
                            Your mining reward is ready. Don’t forget to log in
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                width: "100%"
                            }}
                        >
                            every 8 hours to make a profit!
                        </Typography>
                    </Box>

                    {/* box2 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "1.56rem"
                        }}
                    >
                        {/* two buttons box */}

                        <Box
                            sx={{
                                display: "flex",
                                width: "113px",
                                height: "30px",
                                dispaly: "flex",
                                justifyContent: "center",
                                alignItems: "items"
                            }}
                        >

                            {/* add image here  */}
                            <Box
    sx={{
        display: 'flex',
        alignItems: 'center', // Vertically center the items
        gap: '10px', // Add a gap of 10px between the items
    }}
>
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '26.72px',
            width: '26.72px',
        }}
    >
        <img
            src={KlinkPurple}
            style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain', // Ensures the image scales proportionally
            }}
            alt="Tap Point"
        />
    </Box>

    <Typography
        sx={{
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '28.5px',
            lineHeight: '34.2px',
            color: '#FFFFFF',
            textAlign: 'center',
        }}
    >
        {FunctionCommas(hourlyRewards)}
    </Typography>
</Box>


                        </Box>



                        <button
                            style={{
                                width: "19.4rem",
                                background: "#674EFF",
                                height: "3rem",
                                outline: "none",
                                border: "none",
                                borderRadius: "12px",
                                marginTop: "10px",
                                cursor: "pointer",
                                marginBottom: "21px"
                            }}
                            onClick={handleClaimRewards} 
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    textAlign: "center",
                                }}
                            >
                                Claim
                            </Typography>
                        </button>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default MiningRewardPopup;