import React from 'react';
import success from "../images/GreenCheckIcon.svg";
import reject from "../images/ReadCheckIcon.svg";
import pending from "../images/YellowCheckIcon.svg"
import { Box, keyframes, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {setBoosterPopVisible} from "../store/slices/userSlice";

const Popup = ({message,status}) => {

  const dispatch = useDispatch();
  dispatch(setBoosterPopVisible(true));
    const slideDown = keyframes`
    0% {
      transform: translateX(-100%);
   
    }
    100% {
      transform: translateX(0);

    }
  `;
  if(!status) return;
    
  return (
    <div style={{
        position:"relative",
        top:'16px',
        backgroundColor:`${status=='SUCCESS' ? "#51DA4C33": `${status=='REJECT'? "#FC5D7A33" :"#FBC44D33"}` }`,
        width:"335px",
        minHeight:"34px",
        borderRadius:"8px",
        padding:"5px",
        boxSizing:"border-box",
        animation:`${slideDown} 2s ease-out forwards`,
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        marginRight: "14px"

      
    }}>
         
       <Box
       sx={{
        display:"flex",
        alignItems:"center",
        gap:"7px",
        marginTop:"1px",
        // overflowX:"scroll"

       }}
       >
      { status == "REJECT" &&  <img src={reject} width={20} height={20} alt='rejects' /> }
      { status =="SUCCESS" &&  <img src={success} width={20} height={20} alt='sccuess' /> }
      { status =="PENDING" &&  <img src={pending} width={20} height={20} alt='pending' /> }
       
        <Typography
        
        sx={{
            fontSize:"14px",
            fontWeight:400,
            fontFamily:"Inter",
            color:"#FFFFFF"
        }}
        >
          {message}
        </Typography>
       </Box>
    </div>
  )
}

export default Popup