import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import tap from '../../images/TapIcon.svg';
import Task from '../../images/Task.svg';
import ref from '../../images/Ref.svg';
import Airdrop from '../../images/Airdrop.svg';
import Stat from '../../images/Stats.svg';


const BottomNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getButtonStyles = (path) => ({
    color: 'white',
    width: '60px',
    height: '77px',
    display: 'flex',
    alignItems: 'center',
    background: "",
    flexDirection: 'column-reverse',
    fontSize: '12px',
    lineHeight: '20px',
    borderRadius: '12px',
    background: location.pathname === path ? 'transparent !important' : 'none !important',
    borderColor: location.pathname === path ? '#FC9F0D !important' : 'none !important',
    padding: '10px',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: 'Inter',
    border: location.pathname === path ? '2px solid #FC9F0D !important' : 'none',
    gap: '5px',
    justifyContent: 'center',
    transition: 'border-color 0.3s, color 0.3s', // Add transition for color
    boxShadow: 'none !important',
  
    // Hover styles
    '&:hover': {
      color: 'white', // Set text color to white on hover
      background: location.pathname === path ? 'transparent !important' : 'none !important',
      borderColor: location.pathname === path ? '#FC9F0D !important' : 'none !important',
    },
  
    // Active styles
    '&:active': {
      backgroundColor: 'none !important',
      border: '1px solid #FC9F0D',
      color: 'black',
    },
  });
  

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 100%, transparent 100%)',
        color: 'white',
        borderRadius: '40px 40px 0px 0px', // Matching rounded corners
        border: '2px solid', // Solid border
        '&::before': {
          content: '""',
          position: 'absolute',
          top: -2,
          left: -2,
          right: -2,
          bottom: 0,
          borderRadius: 'inherit',
          padding: '2px',
          background: 'linear-gradient(to bottom left, #ffffff 10%, #802020 75%)',
          WebkitMask: 
            'linear-gradient(#fff 0 0) content-box, ' +
            'linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
          pointerEvents: 'none',
        },
        borderBottom: "none",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        padding: '10px 0',
      }}
    >
      <Button
        variant="contained"
        onClick={() => navigate('/friends')}
        sx={getButtonStyles('/friends')}
      >
        <span className="capitalize-first">Ref</span>
        <img src={ref} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/earn')}
        sx={getButtonStyles('/earn')}
      >
        <span className="capitalize-first">Task</span>
        <img src={Task} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={getButtonStyles('/')}
      >
        <span className="capitalize-first">Tap</span>
        <img src={tap} width={40} height={40} />
      </Button>
      {/* <Button
        variant="contained"
        onClick={() => navigate('/boost')}
        sx={getButtonStyles('/boost')}
      >
        <span className="capitalize-first">Claim</span>
        <img src={Claim} width={40} height={40} />
      </Button> */}
      <Button
        variant="contained"
        onClick={() => navigate('/airdrop')}
        sx={getButtonStyles('/airdrop')}
      >
        <span className="capitalize-first">Airdrop</span>
        <img src={Airdrop} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/stats')}
        sx={getButtonStyles('/stats')}
      >
        <span className="capitalize-first">Stats</span>
        <img src={Stat} width={40} height={40} />
      </Button>
    </Box>
  );
};

export default BottomNavBar;






