import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notEnoughPoint: false,
};

const notEnoughPointSlice = createSlice({
    name: 'notEnoughPoint',
    initialState,
    reducers: {
        setNotEnoughPoint: (state, action) => {
            state.notEnoughPoint = action.payload; // Update state
        },
    },
});

export const { setNotEnoughPoint } = notEnoughPointSlice.actions;
export default notEnoughPointSlice.reducer;