import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import DailyRewardImg from "../../images/DailyrewardConnectWelletMain.svg"
import klinkPurpleCoin from '../../images/KlinkCoinPurple.svg'
import  TotalLifePoints from "../../images/TotalLifePoints.svg"
import Daily from "../../images/Daily.svg"
const DailyrewardwalletConnectPopup = ({ setOpenDailyrewardwalletConnectPopup }) => {
    const navigate = useNavigate();
    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <>
            <Box
                sx={{
                    position: "fixed",
                    margin: "0 auto",
                    left: 0,
                    right: 0,
                    bottom:0,
                    marginTop: "100px",
                    background: "#802020",
                    height: "438.17px", // For vertical height scaling
                    zIndex: 10000001,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: `${slideUp} 200ms ease-out forwards`,
                    borderRadius: "22.26px",
                    boxSizing: "border-box",
                    padding: "20px",
                    width: "100%", // Adjust this to a percentage for better responsiveness
                    // maxWidth: "320px", 
                    boxShadow: "0px 0px 53.03px rgba(149, 131, 255, 0.2)",

                }}
            >

                {/* close button box */}
                <Box>
                    <IconButton
                        onClick={() => setOpenDailyrewardwalletConnectPopup(false)}
                        sx={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            color: "#fff",
                        }}
                    >
                        <CloseIcon width={16} height={16} />
                    </IconButton>
                </Box>

                {/* image box */}


                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '200px',
                        width: "151px"
                    }}
                >
                    <img
                        src={Daily}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',  // Ensures the image scales proportionally
                            marginTop: "25px"
                        }}
                        alt="Tap Point"
                    />
                </Box>


                {/* last box with text and button */}
                <Box
                    sx={{
                        width: "100%",
                        height: "14.56rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1.25rem",


                    }}
                >
                    {/* Box1 */}
                    <Box
                        sx={{
                            width: "100%",
                            height: "5.87rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                        }}
                    >

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 800,
                                fontSize: "28.5px",
                                lineHeight: "34.2px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "10px",
                            }}
                        >
                            Daily Reward
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "8.9px",
                                width: "100%"
                            }}
                        >
                            Your daily reward is ready. Connect your
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                width: "100%"
                            }}
                        >
                            TON wallet to start making profit!
                        </Typography>
                    </Box>

                    {/* box2 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "1.25rem"
                        }}
                    >
                        {/* two buttons box */}

                        <Box
                            sx={{
                                display: "flex",
                                width: "113px",
                                height: "30px",
                                dispaly: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >

                            {/* add image here  */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: '26.72px',
                                    width: "26.72px",
                                    marginRight:"10px",
                                }}
                            >
                                <img
                                    src={TotalLifePoints}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain'  // Ensures the image scales proportionally
                                    }}
                                    alt="Tap Point"
                                />
                            </Box>


                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 700,
                                    fontSize: "28.5px",
                                    lineHeight: "34.2px",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                }}
                            >
                                50000
                            </Typography>

                        </Box>



                        <button
                            style={{
                                width: "19.4rem",
                                background: "#AA4545",
                                height: "3rem",
                                outline: "none",
                                border: "none",
                                borderRadius: "12px",
                                marginTop: "10px",
                                cursor: "pointer",
                                marginBottom: "21px",
                                border: '1px solid transparent', // Transparent border to allow background-clip
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                                backgroundOrigin: 'border-box', // Background starts from the border
                                backgroundClip: 'padding-box, border-box', 
                            }}
                            onClick={() => navigate('/register')}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    textAlign: "center",
                                    
                                }}
                            >
                                Get it!
                            </Typography>
                        </button>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default DailyrewardwalletConnectPopup;