import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { Box, Button, Typography, IconButton, keyframes } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KlinkToken from '../../images/Klink-Coin.svg';
import accountConnected from '../../images/Onboard2.svg';

const UserLastActivePopup = () => {
    const userData = useSelector((state) => state.user.userData);
    const [accountNotConnectedModal, setAccountNotConnectedModal] = useState(false);

    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    useEffect(() => {
        if (userData?.lastLoggedIn) {
            const lastActiveDate = parseISO(userData.lastLoggedIn);

            const now = new Date();
            const millisecondsSinceLastActive = differenceInMilliseconds(now, lastActiveDate);


            // Convert milliseconds to hours and seconds
            const hoursSinceLastActive = millisecondsSinceLastActive / (1000 * 60 * 60);
            const secondsSinceLastActive = millisecondsSinceLastActive / 1000;



            // Show popup if the user has not been active for more than 24 hours (1 day) and is not verified
            if (hoursSinceLastActive >= 24 && !userData?.isUserVerified) {
                setTimeout(() => {
                    setAccountNotConnectedModal(true);
                }, 3000); // 3000 milliseconds = 3 seconds
            }
        }
    }, [userData]);

    const handleClick = () => {
        window.location.href = '/register';
    };

    return (
        <>
            {accountNotConnectedModal && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: '#802020FA',
                        height: '393px',
                        zIndex: 9999,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '15px',
                        animation: `${slideUp} 200ms ease-out forwards`,
                        borderRadius: '25px',
                        boxSizing: 'border-box',
                        padding: '20px',
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '12px',
                            right: '12px',
                            color: '#fff',
                        }}
                        onClick={() => setAccountNotConnectedModal(false)}
                    >
                        <CloseIcon width={16} height={16} />
                    </IconButton>
                    <img src={accountConnected} alt="icon" width={160} height={160} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '101px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '6px',
                                marginTop: '5px',
                            }}
                        >
                            <img src={KlinkToken} alt="coin.svg" width={26} height={26} />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    component="p"
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '24px',
                                        fontWeight: 800,
                                        lineHeight: '28.8px',
                                        letterSpacing: '-0.01em',
                                        textAlign: 'center',
                                        color: '#fff',
                                    }}
                                >
                                    50,000
                                </Typography>
                            </div>
                        </Box>
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: 800,
                                fontSize: '32px',
                                lineHeight: '38.4px',
                                color: '#fff',
                                marginTop: '15px',
                            }}
                        >
                            Connect TON Wallet
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.94px',
                                textAlign: 'center',
                                color: '#fff',
                                width: '393px',
                                marginTop: '12px',
                            }}
                        >
                            Sign up and connect your TON wallet to start
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.94px',
                                textAlign: 'center',
                                color: '#fff',
                                width: '393px',
                            }}
                        >
                            redeeming rewards directly to your account.
                        </Typography>
                    </Box>
                    <Button
                        style={{
                            width: '350px',
                            height: '55px',
                            background: '#AA4545',
                            outline: 'None',
                            border: 'None',
                            borderRadius: '12px',
                            marginX: 'auto',
                            textTransform: 'none',
                            // marginbottom:'37px',
                            marginTop: "20px",
                            marginBottom: "15px"

                        }}
                        onClick={handleClick}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Inter',
                                fontWeight: 873,
                                fontSize: '18px',
                                lineHeight: '23.4px',
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            Sign up to TON
                        </Typography>
                    </Button>
                </Box>
            )}
        </>
    );
};

export default UserLastActivePopup;
