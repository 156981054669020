import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, styled, keyframes, CardContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from 'react-router-dom';
import { FunctionCommas } from "../../utils/commasFun";
import DailyRewardImg from "../../images/DailyrewardPopupMain.svg"
import { claimDailyReward, RewardsData } from '../../Apis';


const DailyRewardPop = ({ setOpenDailyRewardPop }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userStreak = useSelector((state) => state.user.userData?.streak);
    const accessToken = useSelector((state) => state.user.accessToken);
    const userId = useSelector((state) => state.user.userId);
    const [isDisabled, setIsDisabled] = useState(false);

    // const [rewardsData, setRewardsData] = useState([]);
    const dailyreward = useSelector((state) => state?.user?.dailyreward)



    // useEffect(() => {
    //     if (accessToken && userId) {
    //         dispatch(RewardsData(accessToken, userId))
    //             .then((data) => {
    //                 if (data) {
    //                     setRewardsData(data.data.rewardsData); // Save fetched rewards data in state
    //                     // Assuming userRewardData is now derived from Redux, no need to set local state
    //                 } else {
    //                     console.error('No data received from RewardsData'); // Handle empty response
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching rewards data:', error); // Error handling
    //             });
    //     }
    // }, [dispatch, accessToken, userId]);





    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <>
            <Box
                sx={{
                    // position: "fixed",
                    // // margin: "0 auto",
                    // // left: 0,
                    // // right: 0,
                    // bottom:0,
                    // // marginTop: "100px",
                    // background: "#1B1529",
                    // height: "438.17px", // For vertical height scaling
                    // zIndex: 1000000,
                    // // display: "flex",
                    // // flexDirection: "column",
                    // // justifyContent: "center",
                    // // alignItems: "center",
                    // animation: `${slideUp} 200ms ease-out forwards`,
                    // borderRadius: "22.26px",
                    // boxSizing: "border-box",
                    // padding: "20px",
                    // width: "100%", // Adjust this to a percentage for better responsiveness
                    // // maxWidth: "320px", 
                    // boxShadow: "0px 0px 53.03px rgba(149, 131, 255, 0.2)",

                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    background: "#802020",
                    height: "465px",
                    zIndex: 1000000,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: `${slideUp} 200ms ease-out forwards`,
                    borderRadius: "25px",
                    boxSizing: "border-box",
                    padding: "20px",
                    width: "100%"


                }}
            >

                {/* close button box */}
                <Box>
                    <IconButton
                        onClick={() => setOpenDailyRewardPop(false)}
                        sx={{
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            color: "#fff",
                        }}
                    >
                        <CloseIcon width={16} height={16} />
                    </IconButton>
                </Box>

                {/* image box */}


                {/* <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '200px',
                        width: "151px"
                    }}
                >
                    <img
                        src={DailyRewardImg}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'  // Ensures the image scales proportionally
                        }}
                        alt="Tap Point"
                    />

                     <CardContent
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    marginTop: '0px !important',
                                    padding: '0px !important',
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: '52px',
                                        textAlign: 'center',
                                        fontFamily: 'Inter',
                                        fontSize: '10px',
                                        fontWeight: 400,
                                        lineHeight: '13px',
                                        color: '#FFFFFF',
                                        marginTop: '10.5px',
                                    }}
                                >
                                    Day {rewardsData[userStreak]?.day}
                                </Typography>
                                {isDisabled ? (
                                    <img
                                        style={{ color: '#FFFFFF', fontSize: 20 }}
                                        // src={claimedCheckIcon} // Ensure claimedCheckIcon is defined
                                        alt="Claimed"
                                    />
                                ) : (
                                    <img
                                        width={20}
                                        height={20}
                                        src={rewardsData[userStreak]?.image} // Assuming card.image is defined in the rewards data
                                        alt={`Day ${rewardsData[userStreak]?.day}`}
                                    />
                                )}
                                <Typography
                                    sx={{
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontWeight: 700,
                                        lineHeight: '15.6px',
                                        letterSpacing: '-0.01em',
                                        textAlign: 'center',
                                        color: '#000000',
                                        background: isDisabled
                                            ? '#9583FF80'
                                            : (['Day 8', 'Day 15', 'Day 22', 'Day 28'].includes(`Day ${rewardsData[userStreak]?.day}`) ? '#00A6FF' : '#FBC44D'),
                                        borderRadius: '4px',
                                        padding: '1px 3px',
                                    }}
                                >
                                    {FunctionCommas(rewardsData[userStreak]?.KlinkAirdropPoints)}
                                </Typography>
                            </CardContent>

                </Box> */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: '200px',
                        width: "151px",
                        position: 'relative', // Make the parent a relative container
                    }}
                >
                    {/* <img
                        src={DailyRewardImg}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'  // Ensures the image scales proportionally
                        }}
                        alt="Tap Point"
                    /> */}

                    <CardContent
                        sx={{
                            position: 'absolute', // Make CardContent absolute
                            top: '47%', // Center vertically
                            left: '50%', // Center horizontally
                            transform: 'translate(-50%, -50%)', // Adjust for its own dimensions
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '3px',
                            marginTop: '0px !important',
                            padding: '0px !important',
                            height:"100px",
                            width:"100px",
                            borderRadius:"10px",
                            bgcolor:"#AA4545",
                           border:"1px solid white"
                        }}
                    >
                        <Typography
                            sx={{
                                width: '52px',
                                textAlign: 'center',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '14px',
                                color: '#FFFFFF',
                                marginTop: '2.5px',
                            }}
                        >
                            Day {dailyreward[userStreak]?.day}
                        </Typography>
                        <img
                            width={20}
                            height={20}
                            src={dailyreward[userStreak]?.image} // Assuming card.image is defined in the rewards data
                            alt={`Day ${dailyreward[userStreak]?.day}`}
                        />
                        <Typography
                            sx={{
                                width: "33px",
                                height: "12px",
                                fontFamily: 'Inter',
                                fontSize: '9.46px',
                                fontWeight: 700,
                                // lineHeight: '15.6px',
                                letterSpacing: '-0.01em',
                                textAlign: 'center',
                                color: '#000000',
                                background: isDisabled
                                    ? '#9583FF80'
                                    : (['Day 8', 'Day 15', 'Day 22', 'Day 28'].includes(`Day ${dailyreward[userStreak]?.day}`) ? '#00A6FF' : '#FBC44D'),
                                borderRadius: '4px',
                                padding: '1px 3px',
                            }}
                        >
                            {FunctionCommas(dailyreward[userStreak]?.AirdropPoints)}
                        </Typography>
                    </CardContent>
                </Box>



                {/* last box with text and button */}
                <Box
                    sx={{
                        width: "100%",
                        height: "14.56rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1.56rem",


                    }}
                >
                    {/* Box1 */}
                    <Box
                        sx={{
                            width: "100%",
                            height: "5.87rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "100%",
                        }}
                    >

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 800,
                                fontSize: "28.5px",
                                lineHeight: "34.2px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "10px",
                            }}
                        >
                            Daily Rewards
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                marginBottom: "8.9px",
                                width: "100%"
                            }}
                        >
                            Your daily reward is ready. Don’t forget to collect
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "12.47px",
                                lineHeight: "15.09px",
                                color: "#FFFFFF",
                                textAlign: "center",
                                width: "100%"
                            }}
                        >
                            daily to Airdrop Points and increase farming Multiplier.
                        </Typography>
                    </Box>

                    {/* box2 */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            gap: "1.56rem"
                        }}
                    >
                        {/* two buttons box */}

                        <Box
                            sx={{
                                display: "flex",
                                width: "113px",
                                height: "30px",
                                dispaly: "flex",
                                justifyContent: "center",
                                alignItems: "items"
                            }}
                        >

                            {/* add image here  */}

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: 'row', // Arrange children in a row
                                    height: '26.72px', // Adjust height if necessary
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: '26.72px',
                                        width: "26.72px",
                                        marginRight: "8.78px"
                                    }}
                                >
                                    <img
                                        src={dailyreward[userStreak]?.image}
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'contain'  // Ensures the image scales proportionally
                                        }}
                                        alt="Tap Point"
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 700,
                                        fontSize: "28.5px",
                                        lineHeight: "34.2px",
                                        color: "#FFFFFF",
                                        textAlign: "center",
                                    }}
                                >
                                    {dailyreward[userStreak]?.AirdropPoints?.toLocaleString()}

                                </Typography>
                            </Box>


                        </Box>



                        <button
                            style={{
                                width: "19.4rem",
                                background: "#AA4545",
                                height: "3rem",
                                outline: "none",
                                border: "none",
                                borderRadius: "12px",
                                marginTop: "10px",
                                cursor: "pointer",
                                marginBottom: "21px"
                            }}
                            onClick={() => setOpenDailyRewardPop(false)}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 873,
                                    fontSize: "18px",
                                    lineHeight: "23.4px",
                                    color: "#fff",
                                    textAlign: "center",
                                }}
                                onClick={() => navigate('/airdrop', { state: { showBoost: true } })}
                            >
                                Collect
                            </Typography>
                        </button>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default DailyRewardPop;
