import React, { useState } from "react";
import { Box, Typography, IconButton, styled, keyframes } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import notEnoughPointCoin from "../../images/notEnoughPointCoin.svg";
import CloseIcon from '../../images/CloseCrossIcon.svg'
import { setNotEnoughPoint } from '../../store/slices/notEnoughPointSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setBoosterPopVisible } from "../../store/slices/userSlice";
import SmileIcon from "../../images/SmileIcon.svg"
const NotEnoughPointPopup = ({ }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notEnoughPoint = useSelector((state) => state.notEnoughPoint.notEnoughPoint);

    dispatch(setBoosterPopVisible(true));
    const slideUp = keyframes`
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  `;

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    backgroundColor: "#1b152993",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    zIndex: 1000,
                    animation: `${slideUp} 200ms ease-out forwards`,
                }}
             >
                <Box
                    sx={{
                        position: "relative", // Add this for positioning the cross button
                        width: "350px",
                        height: "160px",
                        background: "linear-gradient(to bottom right, #BF5E5E, #802020)",
                        boxShadow:"inset 0px 4px 4px 0px rgba(255,255,255,0.25)",
                        borderRadius: "16px",
                        display: "flex",
                        marginTop: "5vh"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            width: "32px",
                            height: "32px",
                            color: "#FFFFFF",
                        }}
                        onClick={() => dispatch(setNotEnoughPoint(false))}
                    >
                        <img src={CloseIcon} width={32} height={32} alt="cls image" />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            height: "100%",
                            flexDirection: "column",
                            paddingLeft: "15px",
                            paddingTop: "25px",
                            gap: "10px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 800,
                                fontSize: "30px",
                                lineHeight: "36px",
                                color: "#fff",
                            }}
                        >
                            Not Enough Points
                        </Typography>
                        <button
                            style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: "14px",
                                background: "#AA4545",
                                borderRadius: "8px",
                                outline: "none",
                               // border: "1px solid ",
                                border: '1px solid transparent', // Transparent border to allow background-clip
                                backgroundImage: 'linear-gradient(#AA4545, #AA4545), linear-gradient(90deg, #FFFFFF 0%, #BB5C17 100%)', // Two backgrounds
                                backgroundOrigin: 'border-box', // Background starts from the border
                                backgroundClip: 'padding-box, border-box', 
                                color: "#FFFFFF",
                                width: "125px",
                                height: "34px",
                               
                              
                            }}
                            onClick={() => dispatch(setNotEnoughPoint(false))}
                        >
                            Keep Tapping
                        </button>
                    </Box>
                    <img
                        src={SmileIcon}
                        alt="not_enough_point"
                        style={{ width: "auto", height: "100%" }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default NotEnoughPointPopup;