import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: "100px",
   border:"4px solid #CB2726",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#CB2726',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    background: 'linear-gradient(90deg, #802020 0%, #FC9F0D 125.46%)',
    transition: 'width 0.3s ease-in-out',
  },
}));



const MyProgress = ({ value, max }) => {
  // Calculate the percentage of the progress
  const percentage = (value / max) * 100;
  

  return (
    <Box display="flex" alignItems="center"  marginX="auto" width="90vw">
      <Box width="100%"   sx={{
        border:"1px solid white",
        borderRadius:12,
        boxShadow: '1px 1px 20px  10px #9582ff0d,   1px -1px 20px  10px #9582ff0d,-1px 1px 20px  10px #9582ff0d, -1px -1px 20px  10px #9582ff0d' ,
        boxSizing:"border-box"
      }}>
        <BorderLinearProgress variant="determinate" value={percentage ? percentage : 4}  />
      </Box>

      
    
    </Box>
  );
};


export default MyProgress;